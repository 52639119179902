@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/mixins';

.search-filter-section {
  width: 100%;

  .group {
    position: relative;
  }

  .filter-content {
    display: none;
    border-radius: 10px;

    &.opened {
      display: block;

    }

    form.not-admin {
      height: 100%;
      display: flex;
      justify-content: start;
      flex-direction: column;

      .row:last-child {
        margin-top: auto;
      }
    }
  }

  .filter-container {
    background-color: $sundhedplus-lightest-grey;
  }

  .filter-content {
    padding: 66px 22px 22px 22px;
    position: fixed;
    background-color: $white;
    z-index: 29;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    max-height: 80vh;
    overflow: auto;
    z-index: 100;

    @include media-breakpoint-up(xs) {
      width: 400px;
      padding: 25px;
      height: fit-content;
      left: calc(50% - 200px);
      overflow: auto;
      box-shadow: 0px 5px 9.9px 0.1px rgba(102, 102, 102, 0.39);
    }

    @include media-breakpoint-up(xs) {
      width: 100%;
      left: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 25px;
      position: absolute;
      box-shadow: 0px 5px 9.9px 0.1px rgba(102, 102, 102, 0.39);
      width: 320px;
      top: 40px;
      left: auto;
      right: 0;
      height: auto;
    }

    .filter-title {
      margin-bottom: 1rem;
      line-height: 1;
    }

    &>form {
      @include media-breakpoint-down(md) {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    &>.row:last-child {
      @include media-breakpoint-down(md) {
        margin-top: auto;
      }
    }

    .form-row {
      margin: {
        left: -10px;
        right: -10px;
      }

      &>.col,
      &>[class*='col-'] {
        padding: {
          left: 10px;
          right: 10px;
        }
      }
    }

    .custom-control {
      padding-left: 20px;
      margin-bottom: 5px;

      &.custom-checkbox {
        .custom-control-input:checked+.custom-control-label::before {
          border-color: #be323c;
          background-color: #be323c;
        }

        .custom-control-input:checked+.custom-control-label::after {
          left: -15px;
          top: 2px;
        }

        .custom-control-label {
          font-size: 14px;
          vertical-align: inherit;
          padding-left: 20px;
          color: $darker-grey;
          position: relative;

          &:before {
            border-radius: 0;
          }

          &:before,
          &:after {
            left: -15px;
            top: 2px;
          }
        }
      }

      .custom-radio {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        border: none;

        &:hover {
          input {
            background-color: $light-grey;
          }
        }

        .custom-control-input {
          appearance: none;
          width: 22px;
          height: 22px;
          background-color: $lightest-grey;
          margin-right: 10px;
          border-radius: 50%;
          outline: none;
          transition: background-color 0.3s ease;

          &:checked {
            background-color: $green-main;
            border-color: transparent;
          }
        }

        .custom-control-laebl {
          font-size: 14px;
          color: $darkest-grey;
          margin-left: 10px;
        }
      }

    }

    input {
      &:focus {
        box-shadow: none;
      }
    }

    .clinicsarchAddress {
      color: $darker-grey;
      font-size: 12px;
    }

    .clinic-search {
      position: relative;

      & .clinic-search-input {
        border-radius: 17px;
        border-color: $filter-border;
        font-size: 14px;
      }

      .lnir-search-alt {
        position: absolute;
        right: 12px;
        top: 25%;
      }

      .no-suggestions,
      .suggestions {
        box-shadow: 0 0 8.9px 0.1px rgba(102, 102, 102, 0.33);

        .list-group-item {
          display: flex;
          padding: 20px 20px 20px 0 !important;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);

          &:hover {
            background-color: #faf8f6;
          }

          .sugestion_refrenceid {
            width: 70px;
            min-width: 70px;
            text-align: center;
          }

          .clinic_search_address {
            color: $dark-grey;
            font-size: 12px;
          }
        }

        &.list-group {
          position: absolute;
          width: 100%;
          z-index: 200;
          max-height: 250px;
          overflow-y: scroll;
          border: 1px solid #f1e8e2;
        }

        .list-group-item {
          padding: 0.5rem 1rem;
          font-size: 13px;
          cursor: pointer;
          border: none;

          &:hover {
            background-color: $lightest-grey;
            border-bottom-color: #ededed;
          }
        }
      }
    }

    .date-input {

      // display: flex;
      // flex-direction: row;
      // flex-wrap: nowrap;
      .col-12 {
        padding: 0;
      }

      input[type='date'] {
        position: relative;
        padding: 0.35rem 0.25rem 0.25rem 0.5rem;
        color: $darker-grey;
        font-size: 13px;

        &::placeholder {
          color: $filter-border;
        }

        // &::-webkit-datetime-edit,
        &::-webkit-clear-button,
        &::-webkit-inner-spin-button {
          display: none;
        }

        &::-webkit-calendar-picker-indicator {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
          color: transparent;
          background: transparent;
        }

        &:after {
          font-family: 'denti-icon';
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: unicode($di-calendar);
          color: $filter-border;
          padding-right: 4px;
        }

        &.dash-line {
          &:before {
            position: absolute;
            content: '-';
            left: -14px;
          }
        }
      }
    }

    .menu-toggler {
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 200;
      min-width: 50px;

      @include media-breakpoint-up(md) {
        display: none;
      }

      @media only screen and (max-width: 600px) {
        min-width: auto;
        top: 10px;
        right: 10px;
      }
    }
  }

  .filter-title {
    margin-bottom: 1rem;
    line-height: 24px;
    font-size: 16px;
    font-family: $font-family-medium;
  }

  .CustomSelectComponent .custom_select .custom_select__placeholder {
    font-size: 16px;
  }

  .CustomSelectComponent .custom_select {
    .custom_select__multi-value .custom_select__multi-value__label {
      font-size: 14px;
    }

    .custom_select__option {
      font-size: 14px;
    }

    .custom_select__option--is-selected {
      color: #fff;
      background-color: $sundhedplus-brawn;
    }

    .custom_select__indicators .custom_select__dropdown-indicator {
      padding: 0;

      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }

    &.custom_select--is-disabled {
      .custom_select__indicators {
        display: none;
      }
    }
  }
}

.search-filter-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  border: 1px solid #E2DBD9;
  height: 38px;
  padding: 0 3px;
  align-content: center;

  input#keyword {
    color: #222120;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 21px;

    &:focus-visible {
      outline: none;
      border: none;
    }

    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }

    &:focus::-moz-placeholder {
      opacity: 0;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }
  }

  button {
    z-index: 1;
    color: #888888;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    background-color: transparent;
    border: 0;
  }

  .btn-search {
    span {
      color: #996B68;
      margin: 0 3px;
      width: 18px;
      font-size: 18px;
    }
  }

  .btn-clear {
    transition: opacity 0.3s;
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible
    }
  }

  .btn-filter {
    background: #FAF8F6;
    width: 56px;
    border-radius: 30px;
    height: 30px;

    &:hover {
      background-color: #F6F0EE;
      animation-timing-function: ease-out;
      animation-duration: 300ms;
    }
  }
}

.active-filters-section {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .badge-filter,
  .clear-filters {
    margin-bottom: 10px;
    background-color: #FAF8F6;
    color: #996B68;

    border-radius: 50px;
    font-size: 11px;
    font-weight: 400;
    vertical-align: middle;
    height: 26px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background-color: #F6F0EE;
      animation-timing-function: ease-out;
      animation-duration: 300ms;
    }
  }

  .badge-filter {
    margin-left: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px 14px 1px 9px;

    &.has-tooltip {
      .tooltip {
        box-shadow: 0px 4px 15px 4px rgba(175, 175, 175, 0.2509803922);
        margin-top: 10px;
        background-color: rgba(175, 175, 175, 0.75);
        color: #fff;
        font-size: 11px;
        margin-left: 30px;

        &:before {
          border: none;
        }
      }
    }

    .close-action {
      font-size: 8px;
      padding: 0px 5px;
    }
  }

  .clear-filters {
    background-color: #fff;
    border: 1px solid #F6F0EE;
    margin-right: 5px;
    padding: 1px 14px;
  }
}