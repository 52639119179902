@import 'src/resources/scss/utilities/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');

.modalSize {
  width: 31.25rem;
  height: 26.21rem;
}

.dunning-page {
  .header {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  background-color: #faf8f6;

  .patient-list {
    .loading-message {
      height: auto !important;
    }
  }
}

.search-filter-dunning {
  @media screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    .search-filter-section {
      display: flex;

      .search-filter-group {
        width: 400px;
      }
    }

    .search-results-counter {
      color: $dark-grey;
      font-size: 12px;
      min-width: 200px;
      text-align: right;
    }
  }
}