@import "src/resources/scss/utilities/variables";

.inputComponent {
  width: 100%;
  //display: flex;
  //justify-content: space-between;
  margin-bottom: 20px;

  label {
    text-align: left;
    line-height: 2.5;
    font-weight: normal;
    font-size: 16px;
    font-family: "Maven Pro Medium";
    color: $darker-grey;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
  }

  input {
    border: 1px solid $light-grey;
    width: 100%;
    display: block;
    outline: none;
    border-radius: 5px;
    height: 60px;
    padding: 10px 15px;
    font-size: 24px;
    font-weight: 300;

    &::placeholder {
      color: $light-grey;
    }

    &:read-only {
      color: $grey;
    }

    &:focus-visible {
      outline: none;
    }

    &.invalidInput {
      border: 1px solid $status-red;
    }
  }

  //styles for disabled input
  &.disabled {
    pointer-events: none;
    input {
      color: $grey;
      background-color: $lightest-grey;
      border: 1px solid $light-grey;
    }
  }
}

//styles for validation
.errorText, .inlineInputError {
  color: $text-status-red;
  padding: 4px;
  font-size: 12px;
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .inputComponent {
    input {
      border: 1px solid $sundhedplus-light-grey;
      &.invalidInput {
        border: 1px solid $status-red;
      }
      &::placeholder {
        color: $sundhedplus-light-grey;
      }
    }
  
    //styles for disabled input
    &.disabled {
      input {
        background-color: $sundhedplus-lightest-grey;
        border: 1px solid $sundhedplus-light-grey;
      }
    }
  }
}