@import 'src/resources/scss/utilities/variables';

.LeadInformationComponent {
  margin-top: 20px !important;

  .media {
    min-height: 68px;

    .logo {
      padding-top: 8px;
    }
  }

  .header-text {
    display: flex;
    align-items: center;

    .card-loked-data {
      background-color: $lightest-grey;
      border-radius: 10px;
      margin-left: 10px;
      height: 26px;
      display: flex;
      align-content: center;
      justify-content: center;

      .iconzone {
        padding: 2px 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        .time {
          padding-left: 5px;
        }
      }

      i {
        padding-right: 13px;
      }

      .di-denti {
        color: $status-green;
      }

      .di-sundhedplus {
        color: $status-red;
      }

    }
  }

  .leadInformation-form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px $light-grey;
    border-radius: unset;
    font-size: 14px;
    color: $darkest-grey;
    padding-left: 0;

    &:focus {
      border-bottom: solid 2px $green-main;
      box-shadow: none;
    }
  }

  .header-actions {
    display: flex;

    .refreshButton {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $lightest-grey;
      border: solid 1px $lightest-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
    }

    button {
      margin-right: 8px;
      border: none;

      .icon {
        margin-right: 10px;
      }

      .center_Text {}
    }

    .menu-items {
      width: 175px;
    }
  }

  .media-body-title {
    font-size: 12px;
    padding-bottom: 3px;
    display: flex;

    @media screen and (min-width: 768px) and (max-width: 980px) {
      font-size: 16px;
    }
  }

  .media-body-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .fullWidth {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    input {
      padding: 0px;
    }

    .name1 {
      width: 45%;
    }

    .name2 {
      width: 45%;
    }

    .name3 {
      width: 100%;
    }

    .name5 {
      width: 30%;
    }

    .name6 {
      width: 60%;
    }

  }

  .nameAndAddress {
    display: block;

  }

  .dateField {
    border: none;
    border-bottom: 1px solid $light-grey;
    width: 100%;
    outline: none;
  }

  .card-title {
    font-size: 36px !important;
  }

  .error {
    color: $error-color;
    text-transform: none;
    font-size: 12px;
    margin-left: 20px;
  }

  .zone1,
  .zone2 {
    min-width: 370px;
    max-width: 370px;

    @media only screen and (max-width: 1305px) {
      min-width: 50%;
      max-width: 50%;
    }
  }

  .zone1 {
    .row {
      height: 100%;
    }
  }

}