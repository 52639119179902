@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/mixins';

.onboarding {
  width: 100%;
  height: auto;
  background-color: $sundhedplus-lighter-grey;
  display: flex;
  overflow-y: initial !important;
  overflow-x: hidden;

  @media screen and (min-width: 770px) and (max-width: 1599px) and (max-height: 960px) {
    &:not(.denti-modal) {
      padding: 50px 0;
    }
  }
  &.payslips-modal {
    padding: 0 !important;
  }

  .sundhed-wrapper {
    @media screen and (max-width: 768px) {
      width: 100vw;
      height: 100dvh;
      min-height: auto;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }

  .slide-group {
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
    }
  }

  a {
    color: $sundhedplus-red-main;
    font-size: inherit;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #212529;
  }

  .long-btn {
    padding: 10px !important;
  }

  .wrapper {
    margin: auto;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex: 1;
    }

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .rc-slider-handle {
    opacity: 1;
  }

  .step {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 27.5px 4.5px rgba(102, 102, 102, 0.17);
    box-sizing: border-box;

    @include media-breakpoint-down(sm) {
      box-shadow: none;
      height: 100%;
    }
  }

  .step-content {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      min-height: 710px;
      width: 500px;
    }

    .row:last-of-type {
      &.justify-content-center {
        margin-top: auto !important;
      }
    }

    @include media-breakpoint-down(sm) {
      .row:last-of-type {
        .col-12 {
          padding: 0;
        }
      }
    }
  }

  .card-body {
    padding: 40px 35px;

    @include media-breakpoint-down(sm) {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 20px 30px;
    }
  }

  .step-title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 1.2;
      margin-top: 0 !important;
    }
  }

  .step-subtitle {
    font-size: 18px;
    line-height: 1.5;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    &.bold {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 600;
      color: #212529;
    }
  }

  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }
  .font-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .note-container {
    border-width: 0.5px;
    border-style: solid;
    border-color: #e88888;
    .head {
      background: linear-gradient(0deg, #fedede, #fedede);
      padding: 3px 10px 0 10px;
      line-height: 25px;
      text-transform: uppercase;
    }
    .body {
      padding: 10px;
      background-color: white;
    }
  }

  .select-wrapper {
    margin: 40px 0 25px 0;

    .DropDown_Component {
      background-color: #f8f8f8;
    }

    .custom_select {
      width: 100%;
      background-color: #f8f8f8;

      .custom_select__control {
        font-size: 24px;
        border-color: $light-grey;
        box-shadow: none;
        background-color: #f8f8f8;

        .custom_select__value-container {
          padding-left: 14px;
          font-size: 24px;
        }
      }

      .custom_select__indicator-separator {
        display: none;
      }

      .custom_select__indicators .custom_select__dropdown-indicator {
        padding: 10px;

        svg {
          width: 40px !important;
          height: 40px !important;

          path {
            stroke: #fff;
            stroke-width: 1px;
          }
        }
      }

      .custom_select__menu {
        text-align: left;
      }

      .custom_select__placeholder {
        font-size: 24px;
      }

      .custom_select__clear-indicator {
        display: none;
      }

      .custom_select__multi-value {
        background-color: $green-main;
        color: $white;
        border-radius: 4px;
        margin-right: 5px;

        .custom_select__multi-value__remove {
          width: 30px;
          display: flex;
          justify-content: center;

          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }

        .custom_select__multi-value__label {
          font-size: 16px;
          color: $white;
          padding-left: 10px;
        }
      }

      .custom_select__option {
        font-size: 24px;
      }

      .custom_select__option--is-focused,
      .custom_select__option:hover {
        background-color: $light-grey;
        border: none;
        cursor: pointer;
        color: #000;
      }

      &.invalidInput .custom_select__control {
        border: 1px solid $status-red;
        border-radius: 4px;
        box-shadow: none;
      }
    }
  }

  .loading-spinner {
    .step-subtitle {
      padding: 0px 40px 60px;
      font-weight: 600;
      color: #212529;
    }

    text-align: center;
    margin-bottom: 40px;
  }

  .card-footer {
    border-top: none;
    padding: 30px 20px;
  }

  li {
    font-size: 16px;
    padding-bottom: 10px;
  }

  .btn-rounded {
    width: 65px;
    height: 65px;
    margin: 10px;
    background-color: #aa2d36;
    border-radius: 100px !important;
    border: #aa2d36;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    padding: 12px 0 10px 0 !important;
    //margin: 6px;
    font-size: 20px;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-down(sm) {
      width: 60px;
      height: 60px;
    }

    &:focus,
    &:hover {
      color: #fff;
      transform: scale(1.2);
      outline: none;
    }
  }

  .see-more {
    margin-top: 10px;
    width: 100%;
    height: auto;
    font-size: 14px;
  }

  .actions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &.step-buttons {
      margin-top: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &.display-columns {
      flex-direction: column;
    }

    &.align-left {
      justify-content: flex-start;
    }

    .btn {
      min-width: 142px;
      min-height: 41px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 26px 26px 26px 0;
      box-shadow: none !important;

      @include media-breakpoint-down(sm) {
        max-width: 50%;
        min-width: 130px;

        &.full-width {
          max-width: 100%;
        }
      }
    }
    &.note {
      margin-top: 20px;
      margin-bottom: 10px;
      .btn {
        min-height: auto !important;
        padding: 10px 30px;
        min-height: auto;
        border-radius: 30px !important;
        border: 1px solid #be323c !important;
      }
    }

    .btn-success {
      border: 2px solid $sundhedplus-red-main !important;
    }

    .btn-cancel {
      color: $sundhedplus-red-main !important;
      background-color: #fff !important;
      border: 2px solid $sundhedplus-red-main !important;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        background-color: #f8f8f8 !important;
      }
    }

    .goBackButton {
      .ButtonLoader_component {
        color: #be323c;
        margin: 0 auto;
      }
    }

    .full-width {
      width: 100%;
      margin-top: 15px;
    }

    .btn-pill-grey {
      border: 1px solid $light-grey;
      background: $lighter-grey;

      &:hover {
        background: $light-grey;
      }
    }
  }

  // for first step make the submit button full-width on mobile
  .step-1 {
    @include media-breakpoint-down(sm) {
      .actions-buttons {
        .btn {
          max-width: 100%;
        }
      }
    }
  }

  .onboardingPolicyPrivacy {
    font-size: 14px;
    line-height: 1.8;
    padding: 0 10px;

    .color {
      color: $sundhedplus-red-main;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  .existingMemberTitle {
    font-size: 20px !important;
    line-height: 1.8;
  }

  .existingMemberSubtitle {
    font-size: 16px !important;
    line-height: 1.8;
  }

  .contactForQuestions {
    font-size: 16px;
    line-height: 1.8;

    .color {
      color: $sundhedplus-red-main;
    }
  }

  .form-details {
    width: 100%;

    margin-bottom: 40px;

    .label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .error {
        color: $error-color;
        text-transform: none;
        font-size: 12px;
        // margin-left: 20px;
      }

      .textTransformNone {
        text-transform: none !important;
      }
    }

    .input_danger {
      border: 1px solid $error-color !important;

      &:focus {
        border: 1px solid $error-color !important;
      }
    }

    .form-group {
      margin: 20px 0;

      .input-details {
        text-transform: uppercase;
        &:not(:last-child) {
          margin-bottom: 35px;
          @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
          }
        }

        .label {
          margin-bottom: 10px;
        }

        input {
          outline: none;
          border: none $logo-grey;
          border-radius: 5px;
          margin: 0;
          font-size: 24px;
          background-color: $lightest-grey;
          padding: 10px 20px;
          width: 100%;
          color: $darker-grey;
          font-weight: 300;
        }

        input:focus {
          outline: none;
          border: none $logo-grey;
          font-size: 24px;
          margin: 0;
          padding: 10px 20px;
          font-weight: 300;
        }
      }
    }
  }

  .confirm-step {
    .form-details {
      .form-group {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
          width: 90%;
        }

        .input-details {
          text-transform: uppercase;
          input {
            width: 55px;
            height: 75px;
            @include media-breakpoint-down(sm) {
              width: 90%;
              input {
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
  .resend-code {
    margin-top: 10px;
    font-size: 16px;
    color: $darker-grey;
    text-transform: none;

    .label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .error {
        color: $error-color;
        text-transform: none;
        font-size: 12px;
        // margin-left: 20px;
      }

      .textTransformNone {
        text-transform: none !important;
      }
    }

    .resend-code-link {
      border: none;
      background: none;
      color: $sundhedplus-red-main;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .countdownActive {
      border: none;
      background: none;
      color: $sundhedplus-grayish-orange;
    }
  }

  .expensesChoiceBtn {
    width: 40% !important;
    float: left;
    margin: 0 5% 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 26px 26px 26px 0 !important;
    border: none;

    &:hover {
      background: $light-grey;
    }
  }

  #addItemBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $darker-grey;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 10px 20px !important;

    i {
      font-size: 20px;
    }
  }

  #patientCpr {
    border: none $logo-grey;
    outline: none;
    margin: 0;
    font-size: 37px;
    letter-spacing: 14px;
    background-color: $lightest-grey;
    padding: 10px 18px 10px 20px;
    width: 100%;
    color: $darker-grey;
    font-weight: 300;

    &:focus {
      outline: none;
      border: none $logo-grey;
      margin: 0;
      padding: 10px 20px;
      width: 100%;
      font-weight: 300;
    }

    @media (max-width: 767px) {
      input {
        font-size: 48px !important;
        line-height: inherit !important;
        padding: 10px 20px !important;
      }
    }
  }

  // this is the buttons to choose kids age
  #carAndKidsList {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .item {
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;

      .itemIcon {
        border-radius: 50px;
        background-color: #aa2d36;
        font-size: 24px;
        color: #fff;
        width: 50px;
        height: 50px;
        text-align: center;
        padding: 10px 9px;
        float: left;
        margin: 0px 10px 10px 0;
      }

      .itemName {
        font-size: 20px;
        font-weight: bold;
      }

      .itemValue {
        font-size: 14px;
      }

      .removeItemBtn {
        position: absolute;
        right: 0px;
        top: 12px;
        font-size: 12px;
        background: none;
        border: 1px solid $neutral-main;
        border-radius: 8px;
        color: $neutral-main;
      }

      .removeItemBtn:hover {
        background: $lightest-grey;
      }

      .removeItemBtn i {
        margin: 4px 5px 0 0;
      }
    }
  }

  // Membership Conditions

  .terms-wrapper {
    background-color: #faf8f6;
    padding-bottom: 15px;
  }

  .terms-conditions-summary {
    .read-more {
      cursor: pointer;
      display: inline-block;
      border-bottom: 1px solid black;
      padding-bottom: -8px;
      line-height: 15px;
      margin: 20px 0 30px 0;
    }
  }

  // Techincal error
  .error-box {
    border-top: 4px solid #fff3cd;
    font-size: 16px;
    border-bottom: 4px solid #fff3cd;
    line-height: 1.8;
    margin-bottom: 30px;
    padding: 20px 0px 1px 0px;

    a {
      font-size: 16px;
    }
  }

  // credit denied error
  .warning-error-box {
    margin-top: 40px;
    border-width: 0.5px;
    border-style: solid;
    border-color: #e88888;
    .head {
      background: #fedede;
      display: flex;
      text-align: center;
      justify-content: left;
      align-items: center;
      line-height: 22px;
      text-transform: uppercase;
      height: 35px;
      padding: 0 20px;
      font-family: 'Overpass', sans-serif;
      font-weight: 600;
      font-size: 14px;
    }
    .icon {
      margin-right: 10px;
      // turn the icon into 180deg
      transform: rotate(180deg);
      margin-bottom: 6px;
      .infoIcon {
        margin-bottom: 1px;
        width: 16.5px;
        height: 16.5px;
        fill: #b84949;
      }
    }
    .body {
      padding: 20px;
      background-color: white;

      .message {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }

  // forny box credit expired
  .warning-note {
    border-width: 0.5px;
    border-style: solid;
    border-color: #ffd570;
    .head {
      display: flex;
      text-align: center;
      justify-content: left;
      align-items: center;
      line-height: 22px;
      text-transform: uppercase;
      height: 35px;
      padding: 0 20px;
      background: #fff9e6;
      font-family: 'Overpass', sans-serif;
      font-weight: 600;

      font-size: 14px;

      .icon {
        margin-right: 10px;
        // turn the icon into 180deg
        transform: rotate(180deg);
        margin-bottom: 6px;
        .infoIcon {
          margin-bottom: 1px;
          width: 18px;
          height: 18px;
          fill: #ffd570;
        }
      }
    }
    .body {
      padding: 20px;
      background-color: white;
    }
  }

  .credit-denied-actions {
    margin-top: 30px !important;
  }

  //approved credit
  .subsection {
    background: $lightest-grey;
    text-align: left;
    padding: 20px;

    .title {
      text-align: left;
      padding: 0px 0 10px 0px;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 600;
      color: #212529;
    }
  }

  // Edit budget
  .info-card {
    padding: 20px;
    border: 2px solid #b7d5bc;
    margin-bottom: 20px;
    background: #fafafa;
    font-size: 14px;
    line-height: 25px;

    p {
      margin-bottom: 0;
    }
  }

  .shared-expenses-badge {
    font-size: 12px;
    display: inline;
    background: #f6f0ee;
    padding: 2px 12px;
    border-radius: 20px;
    font-weight: 300;
    //margin-left: 5px;
  }

  .budget-accordion {
    background-color: #f8f8f8;
    border-bottom: 1px solid #eaeaea;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    min-height: 100px;
    text-align: left;
    outline: none;
    transition: 0.4s;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
      background-color: #f2f2f2;
      outline: none;
    }

    &.active {
      background-color: #f2f2f2;
    }

    .arrow {
      margin-left: auto;
      order: 2;

      i {
        color: $sundhedplus-red-main;
      }
    }

    .arrow::before {
      display: inline-block;
      font: normal normal normal 1em/1 'LineIconsPro Regular';
      text-transform: none;
      /* Better Font Rendering */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $sundhedplus-red-main;
      content: '\eb27';
      transition: 0.5s ease-in-out;
    }

    &.active {
      .arrow::before {
        transform: rotateX(180deg);
      }
    }

    .group-title {
      font-weight: 600;
    }

    .sum {
      .amount {
        padding: 2px 10px;
        text-align: center;
        background-color: #ebebeb;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
        margin-top: 10px;
        width: fit-content;
        display: inline-flex;
      }

      .status {
        font-size: 12px;

        &.invisible {
          display: none;
        }
      }
    }
  }

  .line {
    margin-bottom: 5px;
  }

  .panel {
    background-color: #fcfcfc;
    font-size: 12px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding: 0 18px;
    margin-bottom: 20px;

    input {
      text-align: right;
      height: 48px;
      line-height: 48px;
      transition: all 0.2s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid $logo-grey;
        box-shadow: none;
      }
    }
  }

  .panel-content {
    padding: 20px;
  }

  #budget {
    .panel {
      .amount {
        width: 100%;
        .custom-checkbox {
          margin-top: 7px;
          padding-left: 0;
          justify-content: flex-end;
          display: flex;
          cursor: pointer;
          &::before {
            content: '';
            margin-right: 10px;
          }
          label {
            display: inline-flex;
          }
          input {
            display: none;
          }
          .checkmark-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #adb5bd;
            border-radius: 3px;
            margin-left: 10px;
          }
          input:checked + .checkmark-icon {
            border-color: $sundhedplus-red-main;
            background-color: $sundhedplus-red-main;
            position: relative;
            display: inline-flex;

            .checkmark-tick {
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              height: 10px;
              width: 5px;
              margin-left: 40%;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              margin-top: 3px;
            }
          }
        }

        > label {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 3px;

          .shared-expenses-badge {
            margin-left: 10px;
            margin-bottom: 5px;
            display: inline-block;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .edited {
          margin-top: 5px;
        }
      }
    }
  }

  // spinner with bullet points
  .bulletSpinner {
    /* Spinner CSS */

    display: inline-block;
    width: 16px;
    height: 16px;
    border: 3px solid #f3f3f3;
    border-top-color: #be323c;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    margin-right: 10px;

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .checkmark {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: relative;
  }

  .checkmark:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 10px;
    left: 4px;
    top: 2px;
    border: solid green;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .loadingMessage:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #000;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
    }
  }

  footer {
    text-align: center;
    width: 75%;
    margin: 20px auto 0 auto;
  }
}

.flow-wrapper.onboarding {
  line-height: 24px;
}

.upload-modal,
.application-modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  .subheadline {
    display: block;
    font-size: 22px;
    line-height: 28px;
  }

  &.approved-documentation .subtitle {
    font-size: 18px;
    line-height: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .onboarding {
    height: auto;
    padding-bottom: 0;
    border-radius: 0.3rem;
  }
}

.notifications-tl {
  .notification {
    padding: 12px 40px 12px 15px !important;
  }
}

//canvas for confetti
canvas {
  position: absolute;
  height: 100%;
  top: 0;
  //left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
}

.step.smartlink {
  .error {
    color: #be323c;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .search-clinic {
    position: relative;

    i {
      position: absolute;
      right: 13px;
      top: 15px;
      font-size: 30px;
      color: #afafaf;
    }

    .filter-loader {
      position: absolute;
      right: 27px;
      top: 20px;
      font-size: 30px;
    }

    .suggestions {
      .list-group-item {
        cursor: pointer;
      }
    }
  }

  .clinic-search-input {
    border-radius: 4px;
    border-color: #eaeaea;
    font-size: 24px;
    height: 62px;
    color: #222222;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 60px;
    background-color: #f8f8f8;

    &:focus {
      box-shadow: none;
      border: 1px solid #ced4da;
    }
  }
}

.missing-budget {
  .panel {
    max-height: fit-content;
    overflow: visible;
  }

  .amount {
    margin-bottom: 15px !important;
  }

  .error {
    color: $error-color;
    margin-right: auto;
  }
}

.restart-application-modal {
  flex-direction: column;
  text-align: center;

  .restart-application-icon {
    padding: 20px;

    i {
      color: $sundhedplus-red-main;
      font-size: 60px;
    }
    .info-circle {
      width: auto;
      height: 80px;
    }
  }

  .subtitle {
    line-height: 30px;
    font-size: 18px;
  }
  .ButtonLoader_component {
    color: #be323c;
    margin: 0 auto;
  }
}
