.entity-details-section {
  position: absolute;
  overflow: auto;
  background-color: $lightest-grey;
  transition: right 0.2s ease-in;
  right: -100%;
  height: 100%;
  padding: 0;
  // display: flex;
  // flex-direction: column;
  min-height: 0;
  z-index: 19;

  @include media-breakpoint-between(md, xl) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100%;
  }

  @include media-breakpoint-up(xl) {
    height: auto;
    position: relative;
    right: 0;
  }

  &.active {
    right: 0;
  }

  .header,
  .main-content,
  .requires-action-container {
    padding: 0 15px;

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 30px;
    }

    @include media-breakpoint-up(wd) {
      padding: 0 45px;
    }
  }

  .custom-card-section {
    & .header {
      height: 130px;
      min-height: 130px;
      display: flex;
      flex-direction: column;
    }

    .main-content {
      >.row {
        @include media-breakpoint-up(md) {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .header {
    justify-content: center;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    @include media-breakpoint-up(xl) {
      margin-bottom: 1rem;
      justify-content: flex-end;
    }

    .name {
      text-align: center;
      margin-bottom: 5px;
      width: 85%;
      flex: 1;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      @include media-breakpoint-up(xl) {
        text-align: left;

        margin: {
          right: 10px;
          bottom: 0;
        }
      }
    }

    .is-verified {
      vertical-align: bottom;

      margin: {
        left: 0;
        right: 30px;
      }

      @include media-breakpoint-down(lg) {
        vertical-align: middle;
      }

      @include media-breakpoint-up(md) {
        margin: {
          left: 10px;
          right: 0;
        }
      }
    }

    .title {
      @include font-style(18px, 700, 16px);
      font-family: 'Maven Pro Bold';
      color: $darkest-grey;
      margin-bottom: 5px;
      word-wrap: break-word;

      @include media-breakpoint-up(md) {
        display: inline-block;
        @include font-style(24px, 700, 20px);
      }

      @include media-breakpoint-up(xl) {
        display: block;
      }

      @include media-breakpoint-up(wd) {
        @include font-style(36px, 700, 30px);
      }

      .refNumber {
        @include font-style(16px, 400, 14px);
        color: $dark-grey;

        @include media-breakpoint-up(md) {
          @include font-style(18px, 400, 20px);
        }

        @include media-breakpoint-up(wd) {
          @include font-style(26px, 400, 26px);
        }
      }

      .late-payment {
        cursor: default;
        display: inline-block;

        .has-tooltip {
          @include font-style(18px, 700, 16px);

          @include media-breakpoint-up(md) {
            @include font-style(24px, 700, 20px);
          }

          @include media-breakpoint-up(wd) {
            @include font-style(36px, 700, 30px);
          }
        }
      }
    }

    .time-created {
      @include font-style(14px, 400, 20px);
      color: $dark-grey;
      display: inline-block;

      @include media-breakpoint-up(md) {
        display: inline-block;
        @include font-style(14px, 400, 20px);
      }
    }

    .company-selected {
      @include font-style(14px, 400, 20px);
      color: $dark-grey;
      display: block;
      border-left: 0;
      margin-left: 0;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        display: inline-block;
        @include font-style(14px, 400, 20px);
        margin-left: 10px;
        border-left: 1px solid $dark-grey;
        padding-left: 10px;
      }
    }

    .header-information {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: flex-start;
        padding: 5px 0;
      }
    }

    .menu-toggler {
      outline: none;
      position: absolute;
      left: 1rem;
      right: auto;
      top: 1rem;
      z-index: 20;

      @include media-breakpoint-up(md) {
        top: 1.5rem;
        left: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        top: 1rem;
        right: 2rem;
      }

      @include media-breakpoint-up(xl) {
        right: 4rem;
      }

      &.close-action {
        right: 1rem;
        left: auto;

        @include media-breakpoint-up(md) {
          right: 1.5rem;
        }
      }
    }

    &.company {
      @include media-breakpoint-up(xl) {
        margin-bottom: 1rem;
      }

      .time-created {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        .create-date {
          margin-right: 5px;
        }

        .last-login-date {
          padding-right: 5px;

          a {
            padding-left: 5px;
            color: $status-green;
          }

          &:before {
            border-right: 1px solid $dark-grey;
            margin-right: 5px;

            @include media-breakpoint-up(md) {
              content: '';
            }
          }
        }
      }
    }
  }

  .header-information {
    &.agreement {
      display: flex;
      justify-content: space-between;
      margin-top: 30px !important;

      .credit-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -10px;

        .label {
          color: $grey;
          font-size: 12px;
        }

        .remaining-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: $font-family-medium;
          margin-left: 10px;
          background-color: $white;
          border-radius: 10px;
          padding: 7px 10px;
          font-size: 13px;

          i {
            color: $green-main;
            margin-right: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .dropdown {
    width: 230px;

    .dropdown-menu {
      padding: 0;
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 0px 8px 0px rgba(85, 85, 85, 0.15);
    }

    .btn-outline {
      padding: {
        right: 0;
        left: 0;
      }

      // @include focus {
      //   background-color: $lighter-grey;
      // }

      span {
        position: relative;
        margin-left: -5px;
      }

      .di {
        transition: all 0.1s linear;
        position: absolute;
        right: 5px;
        transform: rotate(0);
      }

      &.open {
        .di {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-item {
      @include font-style(14px, 400, 10px);
      padding: 1rem 1.3rem;
      cursor: pointer;
      // background-color: $lighter-grey;
      // border: 1px solid $light-grey;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:first-child {
        border-top-left-radius: $dropdown-border-radius;
        border-top-right-radius: $dropdown-border-radius;
      }

      &:last-child {
        border-top: 1px solid $light-grey;
        border-bottom-left-radius: $dropdown-border-radius;
        border-bottom-right-radius: $dropdown-border-radius;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $grey-hover;
        color: #212529;
      }

      &+.dropdown-item {
        margin-top: -1px;
      }

      // @include active {
      //   background-color: $green-main;
      // }

      &.active {
        //border: none;
        background-color: transparent;
        cursor: not-allowed;
        color: #212529;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 200;
        }
      }

      .dot {
        height: 12px;
        width: 12px;
        background-color: $dunning-grey;
        border-radius: 50%;
        display: inline-block;
        margin-right: 15px;

        &.grey {
          background-color: $dunning-grey;
        }

        &.green {
          background-color: $dunning-green;
        }

        &.blue {
          background-color: $dunning-blue;
        }

        &.yellow {
          background-color: $dunning-yellow;
        }

        &.red {
          background-color: $dunning-red;
        }
      }
    }
  }

  .admin-search {
    position: relative;
    margin-bottom: 0;

    & .admin-search-input {
      border-radius: 17px;
      border-color: $filter-border;
      font-size: 14px;
    }

    .lnir-search-alt {
      position: absolute;
      right: 12px;
      top: 10px;
    }

    .no-suggestions,
    .suggestions {
      box-shadow: 0 0 8.9px 0.1px rgba(102, 102, 102, 0.33);

      &.list-group {
        position: absolute;
        width: 100%;
        z-index: 200;
        max-height: 250px;
        overflow-y: scroll;
      }

      .list-group-item {
        padding: 0.5rem 1rem;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          background-color: $lightest-grey;
        }
      }
    }
  }

  .custom-card-container {

    // margin-bottom: 50px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 1.5rem;
    }
  }

  .main-content {
    min-height: 0;
    padding-top: 20px;

    @media only screen and (max-width: 1200px) {
      padding-top: 0;
    }
  }

  .dunning-status-card {
    .descriptionWraper {
      display: flex;
      margin-bottom: 25px;

      .iconArea {
        margin-left: 2px;
        font-size: 22px;
        color: $green-main;

        i {
          font-weight: bold;
        }
      }

      .titleArea {
        font-size: 22px;
        margin-left: 17px;
        font-weight: normal;
      }
    }

    .card-body {
      position: relative;
      margin-top: 6px;
      background-image: linear-gradient(white, rgba(255, 255, 255, 0.1));
    }

    &.green {
      box-shadow: inset 0 7px 0 0 $dunning-green;
    }

    &.yellow {
      box-shadow: inset 0 7px 0 0 $dunning-yellow;
    }

    &.red {
      box-shadow: inset 0 7px 0 0 $dunning-red;
    }

    .dunning-title {
      font-size: 14px;
      margin-bottom: 10px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 10px;
      }
    }

    // DUNNING CUSTOM SELECT
    .custom_select {
      width: 100%;

      .custom_select__control {
        border-color: $light-grey;
        box-shadow: none;
        border-radius: 4px;
        font-size: 14px;

        .custom_select__value-container {
          padding-left: 14px;
        }
      }

      .custom_select__indicator-separator {
        display: none;
      }

      .custom_select__indicators .custom_select__dropdown-indicator {
        padding: 5px;
        color: hsl(0, 0%, 80%);

        svg {
          width: 30px !important;
          height: 30px !important;

          path {
            stroke: #fff;
            stroke-width: 1px;
          }
        }
      }

      .custom_select__menu {
        text-align: left;
      }

      .custom_select__clear-indicator {
        display: none;
      }

      .custom_select__option {
        font-size: 14px;
      }

      .custom_select__option--is-focused,
      .custom_select__option--is-selected,
      .custom_select__option:hover {
        background-color: $grey-hover;
        color: initial;
        border: none;
        cursor: pointer;
      }

      &.invalidInput .custom_select__control {
        border: 1px solid $status-red;
        border-radius: 8px;
        box-shadow: none;
      }

      @media only screen and (max-width: 400px) {
        margin-bottom: 10px;
      }
    }

    .dropdown-menu {
      border: none;
      border-radius: 8px;
    }

    .btn-round {
      max-width: 200px;

      &.btn-block {
        text-transform: uppercase;
      }

      &.btn-grey {
        border: 2px solid $dunning-grey;
        color: $dunning-grey;
        font-family: $font-family-bold;
      }

      &.btn-yellow {
        border: 2px solid $dunning-yellow;
        color: $dunning-yellow;
        font-family: $font-family-bold;
      }

      &.btn-blue {
        border: 2px solid $dunning-blue;
        color: $dunning-blue;
        background-color: transparent;
        font-family: $font-family-bold;
      }

      &.btn-green {
        border: 2px solid $dunning-green;
        color: $dunning-green;
        font-family: $font-family-bold;
      }

      &.btn-red {
        border: 2px solid $dunning-red;
        color: $dunning-red;
        font-family: $font-family-bold;
      }
    }
  }

  .payment-status {
    height: 100%;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &.green {
      box-shadow: inset 0 7px 0 0 $dunning-green;
    }

    &.yellow {
      box-shadow: inset 0 7px 0 0 $dunning-yellow;
    }

    &.red {
      box-shadow: inset 0 7px 0 0 $dunning-red;
    }

    & .header-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      margin: 0;
      padding: 0;

      .icon-area {
        display: flex;

        .icon {
          font-size: 22px;
          margin-top: 1px;
          color: #996b68 !important;
          font-weight: bold;
        }

        .title {
          font-size: 18px;
          margin-left: 10px;
          line-height: 27px;
          font-family: 'Maven Pro medium';
        }
      }

      & .circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        & a {
          background-color: $neutral-main;
          border-radius: 50%;
          padding: 5px;
          width: 20px;
          height: 20px;
          text-transform: uppercase;
          font-family: 'Maven Pro Bold';
          color: $white;
          text-decoration: none;
          font-size: 14px;
          text-align: center;
          vertical-align: middle;
          padding-top: 1px;
        }
      }

      .KebabMenu_Component .menu-container .menu-items {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 175px !important;

        .KebabMenu-item {
          padding: 10px 25px !important;

          .icon {
            margin-right: 10px !important;
          }
        }
      }
    }

    & .status-information {
      margin-top: 7px;

      &>.line {
        display: flex;
        background-color: #faf8f6cc;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 1.7;
        height: 35px;
        border-radius: 4px;
        padding: 7px 15px;
        margin-bottom: 10px;

        .title-line {
          color: #969696;
        }

        &>.status-green {
          color: $card-green;
        }

        &>.status-yellow {
          color: $card-yellow;
        }

        &>.status-red {
          color: $text-status-red;
        }
      }
    }

    .dunning-dropdown {
      width: 140px;

      .dropdown {
        width: 100%;
      }

      .dropdown-menu {
        min-width: 140px;
        max-width: 140px;
      }
    }

    & .dunning-status {
      &>.btn {
        border-width: 0;
      }

      &.status-debtCollection {
        &>.btn {
          background-color: $status-debtCollection;
          color: $white;
        }
      }

      &.status-dialogue {
        &>.btn {
          background-color: $status-dialogue;
          color: $white;
        }
      }

      &.status-noContact {
        &>.btn {
          background-color: $status-noContact;
          color: $white;
        }
      }

      &.status-notContacted {
        &>.btn {
          background-color: $status-notContacted;
          color: $white;
        }
      }

      &.status-agreement {
        &>.btn {
          background-color: $status-agreement;
          color: $white;
        }
      }

      &.dropdown {
        & .btn-outline span {
          margin-left: -20px;
        }
      }
    }
  }

  .lead-options {
    .nav-tabs {
      border-bottom: 0;
      margin-right: 21px;

      .nav-item {
        margin-left: 2px;
      }

      .nav-link {
        font-size: 14px;
        border: 0;
        background-color: $tab-inactive;
        color: $darker-grey;
        border-radius: 0;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.active {
          background-color: $white;
        }
      }
    }
  }

  .rotate-info-message {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .media {
    &.empty {
      color: lighten($darker-grey, 20%);

      .d-logo {
        color: $white;
      }
    }
  }

  .credit-details {
    .media {
      align-items: center;
    }

    .d-logo {
      background-color: $green-main;
      color: $white;
      width: 40px;
      height: 40px;
      line-height: 43px;
    }

    .media-heading {
      margin-bottom: 5px;
      @include font-style(16px, 500, 100%);
    }

    .text-expire {
      color: $darker-grey;
      @include font-style(12px, 400, 100%);

      span {
        position: relative;
        border-right: 1px solid $darker-grey;
        padding-right: 8px;
        margin-right: 8px;

        &:last-child {
          padding-right: 0;
          border-right: 0;
          margin-right: 0;
        }
      }
    }

    &.grey {
      .d-logo {
        background-color: $logo-color;
        color: $white;
      }

      .media-heading {
        color: $grey;
      }
    }
  }

  .referred-denti {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: inline-block;

    .text,
    .d-logo {
      display: inline-block;
    }

    .text {
      @include font-style(16px, 400, 18px);
      color: $green-main;
    }

    .tooltip {
      cursor: default;
      width: 180px;
    }

    a {
      @include plain-all {
        text-decoration: none;
        color: $green-main;
      }
    }
  }

  .requires-action-container {
    padding: 0;
    margin-bottom: 1rem;

    @include media-breakpoint-up(xl) {
      margin-top: 10px;
    }
  }

  .requires-action-alert {
    border-color: $badge-color;
    background-color: rgba($badge-color, 0.1);
    color: $black;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    .alert-icon {
      padding-right: 1.5rem;

      .di-bell {
        font-size: 20px;
        color: $badge-color;
      }
    }

    .alert-title {
      margin-bottom: 5px;
      @include font-style(13px, 600, 1.1);

      @include media-breakpoint-up(md) {
        @include font-style(15px, 600, 1.1);
      }
    }

    .alert-text {
      @include font-style(12px, 400, 1.1);

      @include media-breakpoint-up(md) {
        @include font-style(14px, 400, 1.1);
      }
    }
  }
}

.custom-card {
  background-color: $white;
  border-radius: 8px;
  border: 0px;
  border-bottom: 5px solid $lighter-grey;
  color: $darker-grey;

  .card-body {
    padding: 20px;
  }

  .header-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      align-items: baseline;
    }

    .card-title {
      @include font-style(18px, normal, 100%);
      font-family: 'Maven Pro Bold';
      margin-bottom: 0;

      @media only screen and (max-width: 516px) {
        margin-bottom: 10px;
      }
    }

    .card-subtitle {
      @include font-style(14px, 400, 100%);
      margin-top: 0.75rem;
    }

    .reepay-subscription {
      @include font-style(14px, 400, 100%);
      color: $darker-grey;

      a {
        color: $darker-grey;
        text-decoration: none;

        i {
          vertical-align: text-bottom;
        }

        span {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .quill-editor {
    margin: 20px 0;

    .ql-editor {
      min-height: 120px;
      background-color: $white;
      overflow-y: auto;

      border: {
        width: 1px;
        style: solid;
        color: $light-grey;
        radius: 0;
      }

      @include focus {
        border-bottom-color: $green-main;
      }
    }
  }

  // &.personal-information {
  //   color: $darker-grey;

  //   .header-information {
  //     flex-direction: column;
  //     align-items: flex-start;

  //     @include media-breakpoint-up(sm) {
  //       flex-direction: row;
  //       align-items: center;
  //     }
  //   }

  //   .btn {
  //     margin-top: 15px;

  //     @include media-breakpoint-up(sm) {
  //       margin-top: 0;
  //     }
  //   }

  //   &.company-information {
  //     .btn {
  //       margin-top: 0;
  //     }

  //     .card-title {
  //       padding: 0.75rem 0;
  //     }
  //   }
  // }

  .lead-personal-data {
    @media only screen and (max-width: 600px) {
      >.col-12:last-child {
        padding: 0;
      }
    }

    .empty {
      .note-data {
        color: $grey;
      }
    }

    .media {
      &.home {
        .logo {
          margin-top: -4px;
        }
      }

      &.phone,
      &.mail {
        .d-logo {
          line-height: 34px;
        }
      }

      &.no-note,
      &.note {
        .d-logo {
          line-height: 35px;
        }
      }

      &.cpr {
        .d-logo {
          .di-cpr {
            font-size: 12px;
          }
        }
      }
    }

    .media-body {
      @include font-style(14px, 400, 24px);
      color: $darker-grey;

      @media screen and (min-width: 768px) and (max-width: 980px) {
        font-size: 16px;
      }
    }

    .note-data {
      @include font-style(14px, 400, 18px);
      color: $darker-grey;
    }

    .note {
      .d-logo {
        line-height: 35px;
      }
    }
  }

  .rotate-info-message {
    padding-top: 10px;

    .text {
      @include font-style(12px, 400, 12px);
      margin-left: 10px;
      color: $grey;
    }
  }

  &.tab-row {
    background-color: transparent;

    // margin-top: 1.5rem;
    border: 0px;
    box-shadow: none;
  }
}

.no-leads-details,
.no-lead-selected-details {
  height: 100%;
  display: none;

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .denti-logo {
    background: url('#{$images-path}/denti-logo-2.png') no-repeat center center;
    width: 260px;
    height: 55px;
  }

  .text-wrapper {
    width: 50%;
    padding: 5%;
    box-sizing: border-box;
    text-align: center;
    color: $darker-grey;
    font-size: 18px;
  }
}

.no-lead-selected-details {
  .text-wrapper {
    width: 70%;
    color: $darker-grey;
    font-size: 18px;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .entity-details-section {
    background-color: $sundhedplus-lightest-grey;

    .header {
      .title {
        @include font-style(18px, 700, 16px);
        color: $sundhedplus-darkest-grey;
      }

      &.company {
        .time-created {
          .last-login-date {
            a {
              color: $sundhedplus-red-main;
            }
          }
        }
      }
    }

    .dropdown {
      .dropdown-item {
        &:first-child {
          border-top-left-radius: $dropdown-border-radius;
          border-top-right-radius: $dropdown-border-radius;
        }

        &:last-child {
          border-top: 1px solid $sundhedplus-light-grey;
          border-bottom-left-radius: $dropdown-border-radius;
          border-bottom-right-radius: $dropdown-border-radius;
        }

        .dot {
          &.green {
            background-color: $sundhedplus-dunning-green;
          }
        }
      }
    }

    .admin-search {

      .no-suggestions,
      .suggestions {
        .list-group-item {
          &:hover {
            background-color: $sundhedplus-lightest-grey;
          }
        }
      }
    }

    .lead-information {
      border-bottom: 5px solid $sundhedplus-lighter-grey;
    }

    .quill-editor {
      .ql-editor {
        border: {
          color: $sundhedplus-light-grey;
        }

        @include focus {
          border-bottom: 2px solid $sundhedplus-status-green;
        }
      }
    }

    .dunning-status-card {
      .descriptionWraper {
        .iconArea {
          color: $sundhedplus-brawn;
        }
      }

      &.green {
        box-shadow: inset 0 7px 0 0 $sundhedplus-dunning-green;
      }

      // DUNNING CUSTOM SELECT
      .custom_select {
        width: 100%;

        .custom_select__control {
          border-color: $sundhedplus-light-grey;
        }
      }

      .btn-round {
        &.btn-block {
          text-transform: uppercase;
        }

        &.btn-grey {
          border: 2px solid $dunning-grey;
          color: $dunning-grey;
        }

        &.btn-yellow {
          border: 2px solid $dunning-yellow;
          color: $dunning-yellow;
        }

        &.btn-blue {
          border: 2px solid $dunning-blue;
          color: $dunning-blue;
          background-color: transparent;
        }

        &.btn-green {
          border: 2px solid $sundhedplus-dunning-green;
          color: $sundhedplus-dunning-green;
        }

        &.btn-red {
          border: 2px solid $dunning-red;
          color: $dunning-red;
        }
      }
    }

    .note-payment {
      & .payment-status {
        &.green {
          box-shadow: inset 0 7px 0 0 $sundhedplus-dunning-green;
        }

        & .status-information {
          &>.line {
            &>.status-green {
              color: $sundhedplus-card-green;
            }
          }
        }

        & .dunning-status {
          &.status-agreement {
            &>.btn {
              background-color: $sundhedplus-status-agreement;
            }
          }
        }
      }
    }

    .lead-options {
      .nav-tabs {
        .nav-link {
          background-color: $sundhedplus-lighter-grayish-orange;

          &.active {
            background-color: $white;
          }
        }
      }
    }

    .credit-details {
      .d-logo {
        background-color: #d0b6b4;
      }
    }

    .referred-denti {
      .d-logo {
        background-color: $sundhedplus-lighter-grey;
      }

      .text {
        color: $sundhedplus-red-main;
      }

      a {
        @include plain-all {
          color: $sundhedplus-red-main;
        }
      }
    }

    .requires-action-alert {
      border-color: $sundhedplus-badge-color;
      background-color: rgba($sundhedplus-badge-color, 0.1);

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }

    .header-information {
      &.agreement {
        .credit-status {
          .remaining-amount {
            i {
              color: $sundhedplus-red-main;
            }
          }
        }
      }
    }
  }

  .no-leads-details,
  .no-lead-selected-details {
    .sundhed-plus-logo {
      width: auto;
      height: 55px;
    }
  }
}