@import 'src/resources/scss/utilities/variables';

.MultipleMatches {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 700px;
  padding: 20px;

  .title {
    color: $darkest-grey;
    font-size: 20px;

  }

  .note {
    color: $light-silver;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  .button-area{
    a{
      background-color: $vivid-orange;
      box-shadow: 0px 3px 3.7px 0.3px rgba(122, 122, 122, 0.39);
      border-radius: 17px;
      border-color: transparent;
      width: 100%;
    }
  }
}
