@import 'src/resources/scss/utilities/variables';

.Step3 {
  .tos {
    height: 460px;
    border-radius: 4px;
    background-color: $lightest-grey;
    color: $darker-grey;
    font-size: 14px;
    font-weight: 300;
    overflow-x: auto;
    margin-top: 45px;
    margin-bottom: 30px;
    @media only screen and (max-width: 500px) {
     height: 350px;
     margin-bottom: 25px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .Step3 {
    .tos {
      background-color: $sundhedplus-lightest-grey;
    }
  }
}
