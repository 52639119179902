@import "src/resources/scss/utilities/variables";
.associated-clinics-component {
  .header_wrapper {
    width: 100%;
    padding: 0 2%;
    color: $white;
    background-color: $darkest-grey;
    border-color: $black;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-size: 14px;
    .lead_wrapper {
      display: flex;
      height: 42px;
      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .contentWrapper {
    width: 100%;
    padding: 0 30px; // 4%
    @media only screen and (max-width: 600px) {
      padding: 0 10px;
    }
  }
  .table-separator {
    border-bottom: 5px solid #339ca6;
    margin: 0;
  }
  .date-field {
    width: 20%;
  }
  .star-field {
    width: 5%;
    .lnir {
      color: $orange-main;
    }
  }
  .lead_row {
    display: flex;
    border-bottom: 1px solid $lighter-grey;
    padding: 16px 0;
    align-items: center;
    font-size: 14px;
  }
  .company-name {
    width: calc(75% - 152px);
    @media only screen and (max-width: 600px) {
      width: calc(75% - 50px);
    }

    .clinic-link {
      a {
        color: $darker-grey;
        text-decoration: underline;
      }
    }
  }
  .removeButton {
    width: 152px;
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 152px;
      .lnir {
        margin-right: 10px;
        color: $text-status-red;
      }
      @media only screen and (max-width: 600px) {
        width: 75px;
        .btn {
          min-width: 110px;
        }
      }
    }
  }
  .button-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 105px;
    .btn {
      .lnir {
        margin-right: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .table-size {
      width: 500px;
    }
  }
}
.associated-clinics-remove-confirmation {
  .delte-modal-title {
    font-weight: bold;
    font-size: 24px;
    font-family: "Maven Pro Bold";
    line-height: 30px;
    padding-bottom: 20px;
    color: $black;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .content {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 40px;
    text-align: center;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .associated-clinics-component {
    .removeButton {
      .btn-removeClinic {
        background-color: $white;
        border-color: transparent;
        color: $darker-grey;
      }
    }
    .table-separator {
      border-bottom: 5px solid $sundhedplus-brawn;
    }
    .star-field {
      .lnir {
        color: $sundhedplus-green-brawn;
      }
    }
    .lead_row {
      border-bottom: 1px solid $sundhedplus-lighter-grey;
    }
    .removeButton {
      .btn-removeClinic {
        background-color: $sundhedplus-lightest-grey;
        &:hover {
          background-color: $sundhedplus-light-grayish-orange;
        }
      }
    }
  }
}
