@import 'src/resources/scss/utilities/variables';

.delete_company_fail_Component{
  width:100%;
  text-align: center;

  .delete_company_fail_Component-header{
    margin-bottom: 40px;

    i {
      font-size: 60px;
      margin-bottom: 25px;
      color:$logo-red;
    }

    .title {
      font-size: 36px;
      font-weight: bold;
      color: $darkest-grey;
    }
  }
  .delete_company_fail_Component-content{
    font-size: 18px;
    width: 100%;
    padding-bottom: 26px;

    .firstText{
      margin: 0 auto;
      max-width: 450px;
      padding-bottom: 26px;
    }
    .secondText {
      margin: 0 auto;
      max-width: 450px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .delete_company_fail_Component {
    .delete_company_fail_Component-header {
      i {
        color: $sundhedplus-logo-red;
      }
  
      .title {
        color: $sundhedplus-darkest-grey;
      }
    }
  }
}
