@import '../../fonts/Lineicons-WebFonts/WebFonts/Pro-Light/scss/lineicons';
@import '../../fonts/Lineicons-WebFonts/WebFonts/Pro-Light/scss/icons';
@import '../../fonts/Lineicons-WebFonts/WebFonts/Pro-Regular/scss/lineicons';
@import '../../fonts/Lineicons-WebFonts/WebFonts/Pro-Regular/scss/icons';

@font-face {
  font-family: 'denti-icon';
  src: url('../../fonts/denti-icon/denti-icon.eot');
  src: url('../../fonts/denti-icon/denti-icon.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/denti-icon/denti-icon.ttf') format('truetype'),
  url('../../fonts/denti-icon/denti-icon.woff') format('woff'),
  url('../../fonts/denti-icon/denti-icon.woff2') format('woff2'),
  url('../../fonts/denti-icon/denti-icon.svg?#denti-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Maven Pro Regular';
  font-weight: 400;
  src: url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.eot'); // IE9 Compat Modes
  src: url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.woff') format('woff'), // Pretty Modern Browsers
  url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('#{$font-path}/MavenProRegular/mavenpro-regular-webfont.svg') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Maven Pro Medium';
  font-weight: 500;
  src: url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.eot'); // IE9 Compat Modes
  src: url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.woff') format('woff'), // Pretty Modern Browsers
  url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('#{$font-path}/MavenProMedium/mavenpro-medium-webfont.svg') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Maven Pro Bold';
  src: url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.eot'); // IE9 Compat Modes
  src: url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.woff') format('woff'), // Pretty Modern Browsers
  url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('#{$font-path}/MavenProBold/mavenpro-bold-webfont.svg') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Maven Pro Black';
  font-weight: 900;
  src: url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.eot'); // IE9 Compat Modes
  src: url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.woff') format('woff'), // Pretty Modern Browsers
  url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('#{$font-path}/MavenProBlack/mavenpro-black-webfont.svg') format('svg'); // Legacy iOS
}
