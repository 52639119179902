@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/breakpoints';

.renrew-modal-container {
  border-radius: 4px;
  max-width: 620px !important;
  max-height: 711px !important;
  overflow: auto;
  .modal-content {
    border-radius: 4px;

    padding: 40px 40px 50px 40px;
    @media only screen and (max-width: 515px) {
      padding: 40px 20px;
    }

    .modal-body {
      padding: 0;
    }

    .renew-modal-content {
      .slide-group {
        // make transition smooth
        transition: transform 0.5s ease;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .loading-spinner {
        .card-body {
          justify-content: center;
        }
      }

      .step-header {
        text-align: center;
        color: $black;
        margin-bottom: 40px;
        .logo {
          margin-bottom: 20px;
          color: $sundhedplus-red-main;

          i {
            font-size: 55px;
          }

          @include media-breakpoint-up(md) {
            font-size: 55px;
          }
        }
        .checkmark {
          margin-bottom: 20px;
          color: $sundhedplus-red-main;

          i {
            font-size: 60px;
          }

          @include media-breakpoint-up(md) {
            font-size: 55px;
          }
        }

        .title-text-header {
          h2 {
            font-size: 32px;
            margin-bottom: 30px;
            line-height: 40px;
            font-family: 'Maven Pro medium';
            font-weight: 700;
            color: #444444;
            @include media-breakpoint-up(md) {
              font-size: 32px;
            }
          }
        }

        .step-subtitle {
          font-size: 16px;
          text-align: left;
          color: #444444;
          h3 {
            font-family: 'Maven Pro medium';
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 10px;
          }
        }
      }

      .link-sent {
        margin-bottom: 20px;
      }
      .step-options {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;

        .title-text {
          margin: 0 !important;
          h3 {
            font-family: 'Maven Pro medium';
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 10px;
          }
        }
        .checkbox-options {
          width: 100%;
          display: flex;
          background-color: #faf8f6;
          min-height: 48px;
          border-radius: 4px;
          margin-bottom: 10px;
          &.information {
            min-height: 88px !important;

            gap: 30px;

            .container {
              display: flex;
              align-items: center;
              justify-content: left;
              padding: 20px;
              width: 100%;

              .text {
                display: flex;

                font-size: 16px;
                color: #444444;
                font-weight: 400;
                line-height: 24px;

                font-family: 'Maven Pro regular';
              }

              .icon {
                svg {
                  width: 40px;
                  height: 40px;
                  fill: #996b68 !important;

                  path {
                    fill: #996b68 !important;
                  }
                }

                &.arrow {
                  display: flex;
                  width: 50px;
                  path {
                    width: 17.09px;
                    height: 25.73px;
                  }

                  &.turn-down {
                    transform: rotate(180deg);
                    margin-top: 12px;
                  }
                }

                &.arrow-right {
                  path {
                    width: 17.09px;
                    height: 25.73px;
                  }

                  &.turn-up {
                    transform: rotate(180deg);
                    margin-top: 12px;
                  }
                }

                &.clock {
                  width: 37.75px;
                  height: 38.13px;
                }
                &.warning {
                  path: {
                    width: 37.81px;
                    height: 29.25px;
                  }
                }
              }
              .text {
                font-size: 16px;
                color: #444444;
                font-weight: 400;
                line-height: 24px;
                margin-left: 31px;
              }
            }
          }
          .input-group {
            display: flex;
            align-items: center;
            padding: 0 35px;

            @media only screen and (max-width: 515px) {
              padding: 0 10px;
            }
          }

          .SimpleCheckbox_component-content {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;
            font-size: 16px;
            color: #444444;
            font-weight: 400;
            line-height: 24px;
            @media only screen and (max-width: 515px) {
              margin-left: 10px;
            }
            @media only screen and (max-width: 415px) {
              margin-left: 1px;
            }
          }
        }
      }
      .button-actions {
        display: flex;

        justify-content: center;
        gap: 50px;
        margin-top: 50px;
        @media only screen and (max-width: 415px) {
          gap: 10px;
          justify-content: space-around;
        }

        .btn {
          min-width: 140px !important;
          height: 50px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 17.72px;
          font-family: 'Overpass';
          padding: 10px 30px;
        }
        .btn-cancel {
          font-weight: 500;
          line-height: 17.72px;
          background-color: #ffffff;
          border: 1px solid #be323c;
          color: #be323c;
        }
      }
    }
  }
}
