@import 'src/resources/scss/utilities/variables';

#loan-calculator {
  font-family: $font-family-regular;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  min-height: 100vh;
  min-width: fit-content;

  .main-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 70px;
    max-width: 800px;
    width: 100%;

    .left-column {
      margin: 0 10px;
      width: 100%;
    }

    .right-column {
      align-items: center;
      justify-content: center;
      margin: 0 10px;
    }
    .horizontalCard {
      margin: -13px 0;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 980px) {
    .main-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 35px;
    }
  }

  .denti-logo {
    width: 190px;
  }
  .d-logo {
    background-color: $logo-green;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    font-size: 26px;

    @media screen and (min-width: 768px) {
      font-size: 33px;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 205px;
      transform: translateY(-50%);
    }

    .di-denti {
      position: relative;
      right: 1px;
      bottom: 1px;
    }
  }

  .card {
    max-width: 480px;
    width: 100%;

    background-color: #f8f8f8;

    padding: 40px 0;
    border: none;
    border-radius: 0.25rem;

    .button-layout {
      font-size: 16px;
      padding: 1.25rem 2.5rem;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 40px 40px 40px 0;
    }

    @media screen and (max-width: 550px) {
      padding: 40px 20px;
    }
    @media screen and (max-width: 312px) {
      padding: 0;

      .applyButton {
        padding: 20px;
      }
    }
    @media screen and (max-width: 260px) {
      .applyButton {
        padding-top: 45px;
      }
    }

    .card-header {
      background: none;
      border: none;
      display: flex;
      justify-content: center;
    }

    .card-body {
      max-height: 720px;

      padding: 0;
    }

    .card-footer {
      border: none;
      background: none;
      display: flex;
      justify-content: center;
    }
  }

  h1 {
    font-family: $font-family-regular;
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .amount-slider {
    margin: 20px 0 50px 0;

    .rc-slider {
      .rc-slider-step {
        display: none;
      }

      .rc-slider-track {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .rc-slider-handle {
        position: relative;
        animation: pulse 1.7s infinite;
        opacity: 1;
      }

      .rc-slider-mark {
        position: relative;
        top: 5px;
        margin-left: 15px;
        margin-right: 20px;
        width: auto;
      }
    }
  }

  .duration-buttons {
    display: flex;
    margin: 15px 0;

    label {
      cursor: pointer;
      font-size: 18px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #b3b3b3;
      background: $white;
      display: flex;
      text-align: center;
      width: 23%;
      margin: 1%;
      align-items: center;
      justify-content: center;

      &.selected {
        background: $green-main;
        color: $white;
      }
    }

    input {
      display: none;
    }
  }

  .horizontally-duration {
    margin: 15px 0;
    label {
      cursor: pointer;
      font-size: 17px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #b3b3b3;
      background: $white;
      text-align: center;
      width: 23%;
      margin: 1%;

      &.selected {
        background: $green-main;
        color: $white;
      }
    }
    input {
      display: none;
    }
  }

  .loan-details {
    margin: 40px 0;
  }
  .horizontally {
    margin: 15px 0;
  }

  .line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    line-height: 30px;
    font-size: 14px;
  }

  .focus-line {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    font-size: 17px;
    font-weight: bold;
  }

  .note {
    text-align: center;
    font-size: 14px;
  }
}

.loan-calculator {
  #root {
    overflow: auto;
  }
}

// ##### SUNDHEDPLUS ####
#Sundhedplus {
  #loan-calculator {
    font-family: 'Overpass', sans-serif;

    h1 {
      font-family: 'PlayfairDisplay', serif;
    }

    .duration-buttons {
      label {
        &.selected {
          background: $sundhedplus-red-main;
          color: $white;
        }
      }
    }
    .horizontally-duration {
      label {
        &.selected {
          background: $sundhedplus-red-main;
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  #loan-calculator {
    h1 {
      margin-top: 20px;
      margin-bottom: 25px;
    }

    .card {
      width: 100%;
      border: none;
      box-shadow: none;
    }

    .duration-buttons {
      label {
        font-size: 16px;
        padding: 7px;
      }
    }
    .horizontally-duration {
      label {
        font-size: 16px;
        padding: 7px;
      }
    }
  }

  #Denti {
    #loan-calculator {
      .card {
        height: auto;
        overflow: auto;

        .card-header {
          background: none;
          border: none;
          display: flex;
          justify-content: center;
        }

        .card-body {
          padding-top: 0;
        }
      }
    }
  }

  #Sundhedplus {
    #loan-calculator {
      .wrapper {
        width: 100%;
        .logo {
          margin-top: 10px;
          margin-bottom: 0px;
        }
      }
      .main-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 5px;
        .logo {
          margin-top: 10px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  #loan-calculator {
    .main-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 25px;

      .card {
        border: none;
        box-shadow: none;
        margin: 0 auto;
      }

      .horizontally-duration {
        label {
          font-size: 82%;
          padding: 7px;
          height: 100%;
        }
      }

      .right-column {
        font-size: 14px !important;
        margin: 0 10px;
      }
    }

    .card {
      width: 100%;

      border: none;
      box-shadow: none;
      margin: 0 auto;
    }

    .duration-buttons {
      label {
        font-size: 16px;
        padding: 7px;
      }
    }
  }

  #Denti {
    #loan-calculator {
      .card {
        height: auto;
        overflow: auto;

        .card-header {
          background: none;
          border: none;
          display: flex;
          justify-content: center;
        }

        .card-body {
          padding-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 525px) and (max-width: 768px) {
  #loan-calculator {
    .main-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 25px;

      .card {
        border: none;
        box-shadow: none;
        margin: 0 auto;
      }

      .loan-duration {
        h3 {
          font-size: 16px !important;
          line-height: normal;
        }
      }

      .horizontally-duration {
        label {
          font-size: 82%;
          padding: 7px;
          height: auto;
        }
      }

      .right-column {
        font-size: 14px !important;
      }
    }
    .card {
      width: 100%;

      border: none;
      box-shadow: none;
      margin: 0 auto;
    }

    .duration-buttons {
      label {
        font-size: 16px;
        padding: 7px;
      }
    }
  }

  #Denti {
    #loan-calculator {
      .card {
        height: auto;
        overflow: auto;

        .card-header {
          background: none;
          border: none;
          display: flex;
          justify-content: center;
        }

        .card-body {
          padding-top: 0;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  #loan-calculator {
    background: $sundhedplus-red-secudary;
    .card {
      background: $sundhedplus-red-secudary;
    }
  }
}
