@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/mixins';
@import 'src/resources/scss/utilities/classes';
@import 'src/resources/scss/utilities/breakpoints';
@import 'src/resources/scss/utilities/fonts';

.ChainItemContent {
  .forChain {
    @media only screen and (min-width: 2200px) {
      margin: 0 auto !important;
      margin-top: 1.5rem !important;

      .lead-options {
        padding: 0 0 0 3px !important;

        .nav-tabs-section {
          margin-bottom: 50px;
        }
      }
    }

    .ChainItemContent {
      margin: 0px !important;
    }
  }
}