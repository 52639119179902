@import 'src/resources/scss/utilities/variables';

.MigrateCreditFlow {
  overflow: visible;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .d-logo {
    margin-bottom: 17px;
  }

  .actions-section .btn {
    min-width: 142px;
    font-size: 13px;
    text-transform: uppercase;

    &.btn-light {
      background-color: transparent;
      border: 2px solid $green-main;
      color: $green-main;
      font-weight: 500;
    }
  }

  .container {
    width: 480px;
    padding: 0;

    @media screen and (max-width: 700px) {
      min-height: 800px;
      height: 100%;
    }

    .title {
      text-align: center;
    }

    @media only screen and (max-width: 700px) {
      padding-top: 64px;
    }

    .imgTitle {
      width: 180px;
      margin: 0 auto;
      margin-bottom: 20px;
      display: inline-block;

      .headerImg {
        width: 100%;
      }

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    .migration-steps {
      position: relative;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 0 28px 5px rgba(102, 102, 102, 0.17);
      padding: 30px 38px 13px 38px;
      min-height: 760px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &_content {
        padding-top: 40px;

        @media only screen and (max-width: 700px) {
          padding-top: 20px;
        }

        .section {
          .title {
            color: $darker-grey;
            font-size: 32px;
            font-weight: bold;
            font-family: 'Playfair Display', sans-serif;
          }

          .content {
            line-height: 28px;
          }

          article {
            margin-top: 30px;

            h5 {
              font-weight: bold;
              font-family: 'Overpass', sans-serif;
              font-size: 16px;
            }

            .read-more {
              cursor: pointer;
              text-decoration: underline solid $black 2px;
            }

            .Checkbox_component {
              margin-bottom: 40px;
            }
          }

          .note {
            margin-top: 30px;
            margin-bottom: 45px;
            font-size: 18px;
            font-weight: 300;
          }

          .content {
            .terms-wrapper {
              height: 370px;
              border-radius: 4px;
              background-color: $lightest-grey;
              color: $darker-grey;
              font-size: 14px;
              font-weight: 300;
              overflow-x: auto;
              margin-top: 45px;
              margin-bottom: 30px;

              @media only screen and (max-width: 500px) {
                height: 350px;
                margin-bottom: 25px;
              }
            }

            .inputContainer {
              text-align: left;
              margin-bottom: 40px;

              label {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 300;
                color: $darker-grey;
                display: block;
              }

              input {
                border-radius: 4px;
                background-color: $lightest-grey;
                height: 60px;
                padding: 20px;
                border: none;
                font-size: 24px;
                font-weight: 300;
                width: 100%;

                &:read-only {
                  color: $grey;
                }

                &:focus-visible {
                  outline: none;
                }
              }
            }
          }
        }

        .Step3 {
          text-align: center;

          #rp_container {
            height: 570px;
            width: 90%;
            margin: 10px auto 20px auto;
            border-radius: 4px;
            box-shadow: 0 0 15px 2px rgba(102, 102, 102, 0.17);
          }
        }

        .Step4 {
          margin-bottom: 20px;
          h5 {
            text-align: center;
            padding: 0 60px;
            font-weight: 600;
          }

          .subsection {
            background: $lightest-grey;
            text-align: left;
            padding: 20px;

            .title {
              font-size: 26px;
              text-align: left;
              padding: 0px 0 10px 0px;
            }
          }
        }
      }

      .actions-section {
        padding-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        button:first-child {
          margin-right: 25px;
        }
      }

      .timestamp {
        font-size: 11px;
        line-height: 24px;
        color: $grey;
        text-align: center;
        margin-top: 30px;
      }

      &_step-bullets {
        margin: 0 auto;

        .step-bullet {
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: $lightest-grey;
          margin: 0 8px 0 0;

          &.checked {
            background-color: $light-grey;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      .controls {
        margin-top: auto;
        text-align: center;
      }

      .actions-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;

        &.display-columns {
          flex-direction: column;
        }

        &.align-left {
          justify-content: flex-start;
        }

        .btn {
          min-width: 150px;
          max-width: 306px;
          height: 41px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 26px 26px 26px 0;
          box-shadow: none !important;
        }

        .btn-success {
          border: 2px solid $sundhedplus-red-main !important;
        }

        .btn-cancel {
          color: $sundhedplus-red-main !important;
          margin-right: 20px;
          background-color: #fff !important;
          margin-right: 20px;
          border: 2px solid $sundhedplus-red-main !important;

          &:hover {
            background-color: #f8f8f8 !important;
          }
        }

        .full-width {
          width: 100%;
          margin-top: 15px;
        }

        .btn-pill-grey {
          border: 1px solid $light-grey;
          background: $lighter-grey;

          &:hover {
            background: $light-grey;
          }
        }
      }
    }
  }

  .longButtonzone {
    width: 100%;
    margin: 10px 0px;

    .btn {
      width: 70%;
    }
  }

  @media only screen and (max-width: 700px) {
    .container {
      padding-top: 0px;

      .imgTitle {
        margin-bottom: 0px;
        margin-top: 10px;
        width: 50%;
      }

      width: 100%;

      .migration-steps {
        box-shadow: none;
        padding: 20px;
        min-height: calc(100vh - 60px);

        .actions-section {
          padding: 10px 0;

          .btn {
            min-width: 40%;
            font-size: 14px !important;
          }

          &.single {
            .btn {
              min-width: 80%;
            }
          }
        }
      }

      .migration-steps_content {
        .section {
          .note {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 321px) {
    .container {
      .migration-steps_content {
        .section {
          .note {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .content {
            .inputContainer {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .single {
    .btn {
      margin-right: 0px !important;
    }
  }

  .something-went-wrong .visit-action-buttons a {
    width: 100%;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .MigrateCreditFlow {
    background-color: $sundhedplus-red-secudary;
    font-family: 'Overpass', sans-serif;

    .btn {
      &.btn-light {
        border: 2px solid $sundhedplus-red-main;
        color: $sundhedplus-red-main;
        background-color: $white;
      }
    }
    .something-went-wrong {
      font-family: 'Overpass', sans-serif;
      .title {
        font-family: 'PlayfairDisplay';
      }
    }

    .container {
      background-color: $white;

      .d-logo {
        background: none;
        width: auto;
        height: auto;
        transform: translate(-50%, -90px);

        @media only screen and (max-width: 700px) {
          width: auto;
          height: auto;
          font-size: initial;
        }
      }

      .migration-steps {
        min-height: 700px;

        &_content {
          padding-top: 0px;

          .section {
            .content {
              .inputContainer {
                input {
                  background-color: $sundhedplus-lightest-grey;
                }
              }
            }
          }
        }

        &_step-bullets {
          .step-bullet {
            background-color: $sundhedplus-lightest-grey;

            &.checked {
              background-color: $sundhedplus-light-grey;
            }
          }
        }
      }
    }

    .finalState {
      margin: auto;

      .iconZone {
        padding-top: 30px;

        .lnil {
          font-size: 170px;

          &.red {
            color: $status-debtCollection;
          }

          &.green {
            color: $sundhedplus-red-main;
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .MigrateCreditFlow {
      background-color: #fff;
      .d-logo {
        padding-top: 10px;
        margin-bottom: 0px;
      }
      .container {
        .migration-steps_content .section {
          .title {
            font-size: 22px;
          }
        }

        .migration-steps .actions-buttons {
          margin-bottom: 20px;
          .btn {
            min-width: 130px;
          }
        }
      }
    }
  }
}
