@import 'src/resources/scss/utilities/variables';

.Step1 {
  .content {
    .inputContainer {
      .validations {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 300;
        color: $dark-grey;
        .col-sm-6 {
          padding-left: 7px;
        }
        .valid {
          color: $strong-green;
        }
        .invalid{
          color: $red-main;
          &.valid{
            color: $strong-green;
          }
        }
        .row {
          margin-bottom: 10px;
          @media only screen and (max-width: 500px) {
            margin-bottom: 0px;
            .col-sm-6{
              padding-left: 7px;
            }
          }
          i {
            margin: 0 8px;
          }
        }

        &_note {
          color: $very-dark-orange;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 10px;
        }
      }
    }
    .passwordContainer{
      position: relative;
      margin-bottom: 20px !important;
      .showPassword-Container{
        position: absolute;
        right: 17px;
        top: 43px;
        font-size: 24px;
        cursor: pointer;
        .hideIcon {

          .oblick-line {
            border-top: 2px solid black;
            position: absolute;
            width: 29px;
            transform: rotate(-32deg);
            top: 14px;
            right: -3px;
            height: 3px;
            background-color: white;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .passwordContainer{
      margin-bottom: 0px!important;
    }
  }
}