@import 'src/resources/scss/utilities/variables';

.add-user-modal-component {

  .header {
    text-align: center;
    margin-bottom: 45px;
    color: $darkest-grey;

    .lnir {
      font-size: 60px;
    }

    .title {
      font-size: 36px;
      font-family: $font-family-bold;
    }
  }

  .content {
    margin: 0 20px 10px 20px;

    .form {
      .label {
        font-size: 16px;
        color: $darkest-grey;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .ErrorZone{
        color: $text-status-red;
        font-size: 12px;
      }

      input {
        height: 62px;
        width: 100%;
        border: solid 1px $light-grey;
        border-radius: 4px;
        margin-bottom: 30px;
        font-size: 24px;
        color: $darkest-grey;
        padding: 20px;
        &.inputError{
          border-color: $text-status-red;
        }
        &:focus {
          outline: 0px;
        }
      }
      .DropDown_Component{
        margin-bottom: 30px;
      }
    }
    .informationZone{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: -10px;
      .content{
        max-width: 490px;
        text-align: center;
        font-size: 14px;
        margin: 0 30px 20px 30px;
      }
    }
    .button_area{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 25px;
      min-width: 150px;
      i{
        margin-right: 5px;
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .add-user-modal-component {
    .header {
      color: $sundhedplus-darkest-grey;
    }
    .content {
      .form {
        .label {
          color: $sundhedplus-darkest-grey;
        }
        input {
          border: solid 1px $sundhedplus-light-grey;
          color: $sundhedplus-darkest-grey;
        }
      }
    }
  }
}