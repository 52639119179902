@import 'src/resources/scss/utilities/variables';

.Step4 {
  .title{
    padding: 0px 0 30px 0px;
  }
  .note {
    font-size: 32px!important;
    font-weight: bold!important;
  }
  .content{
    text-align:left;
    @media only screen and (max-width: 500px) {
     height: auto;
    }
  }
}
