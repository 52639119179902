.register-invoice-modal {
  overflow: hidden !important;
  max-width: 600px;
  max-height: 759px;
  &.modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
      max-height: 759px;
    }
  }
  .modal-content,
  .modal-body {
    position: initial;
  }

  .logo {
    display: flex;
    background-color: $white;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
  }
}
#register-invoice-flow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100% !important;

  overflow: hidden !important;

  .slide-group {
    // make transition smooth
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loading-spinner {
    .card-body {
      justify-content: center;
    }
  }

  .step-content {
    color: $select-title-color;

    width: 530px !important;
    min-height: 659px;

    .step-header {
      text-align: center;
      color: $black;
      margin-bottom: 35px;

      .title-text {
        font-size: 24px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          font-size: 32px;
        }
        line-height: 1;
        font-family: 'Maven Pro Bold';
        margin-top: 20px;
      }

      .di-add-user {
        font-size: 40px;

        @include media-breakpoint-up(md) {
          font-size: 60px;
        }
      }
      .step-subtitle {
        font-size: 16px;
        margin: 10px 0 40px 0;
        color: $dark-grey;
      }
    }

    .step-body {
      .input-container {
        background-color: $sundhedplus-lightest-grey;
        border-radius: 4px;
        text-align: center;
        padding: 30px 40px;

        > .form-group {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          // align-items: center;

          &:last-child {
            margin-bottom: 0;
            align-items: center;
          }

          label {
            font-size: 16px;
            color: $darker-grey;
            margin-bottom: 10px;
          }
          .patientLetters {
            margin-top: 20px;
          }

          .form-control {
            font-size: 31px;
            padding: 10px 0px 10px 22px;
            border-radius: 4px;
            border-color: $light-grey;
            display: block;
            letter-spacing: 21px;

            &:focus {
              box-shadow: none;
            }

            &#patientLetters {
              width: 110px;
            }
            &#patientCpr {
              width: 450px;
            }
          }
        }

        .container-wrapper {
          // width: 100%;
          // display: flex;
          // flex-direction: column;

          .patient-info-name {
            margin-bottom: 30px;
            h3 {
              font-weight: 500;
              color: #444444;
              font-size: 26px;
              line-height: 39px;
              font-family: 'Maven Pro Medium';
            }
            .patient-info-label {
              font-size: 14px;
              color: #969696;
              margin-bottom: 10px;
            }
          }

          .credit-info-container {
            padding: 40px 50px 20px 50px;
            background-color: #ffffff;

            @media screen and (max-width: 768px) {
              flex-direction: column;
              padding: 20px 20px 20px 20px;
              width: 100%;
            }

            .patient-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              margin-bottom: 20px;

              .patient-info-label {
                font-size: 14px;
                color: #969696;
                margin-right: 5px;
              }
              .patient-info-value {
                color: $darker-grey;
                font-weight: bold;

                &.expiration {
                  font-weight: 400 !important;
                }

                &.active {
                  color: #72ae8c;
                  font-weight: 500 !important;
                  font-size: 22px !important;
                  line-height: 28px;
                }

                &.inactive {
                  color: #be323c;
                  font-weight: 500 !important;
                  font-size: 22px !important;
                  line-height: 28px;
                }
              }
            }
          }
          .btn-show-profile {
            margin: 0;
            padding-top: 10px;
            margin-bottom: 0;
            .btn {
              color: $sundhedplus-red-main !important;
              background-color: #ffffff !important;
              border: 2px solid $sundhedplus-red-main !important;

              &:hover {
                background-color: $sundhedplus-red-main !important;
                color: #ffffff !important;
              }
            }
          }
          .note-container {
            margin-top: 30px;
            width: 100%;
            border: 1px solid #ffd570;
            background-color: #fff3cd;
            text-align: left;
            padding: 15px;
            padding-top: 10px;

            .note-label {
              font-size: 16px;
              font-family: 'Maven Pro Medium';
              color: #444444;
              margin-bottom: 10px;
            }
            .note-value {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .obs-link {
              text-align: left;
              padding: 0 !important;
              margin: 0 !important;
              margin-top: 10px;
              color: #be323c;
            }
          }
        }
      }

      .error-message {
        color: $error-color;
        font-size: 16px;
        line-height: 20px;
      }
      .text-center {
        text-align: center;
        margin: 40px 0;
      }
    }

    .button-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      margin-top: 50px;

      .btn {
        font-size: 14px;
      }

      .btn-add-patient {
        min-width: 140px;
      }
      .icon {
        margin-right: 15px;
        font-size: 18px;
      }
    }

    .text-message {
      margin-top: 20px;
    }

    &.step-final {
      .step-header {
        margin-bottom: 20px;

        .title-text {
          margin-top: 30px;
        }
      }

      .image-status {
        height: 222px;
        width: auto;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
          height: 312px;
        }
      }

      .text-information {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 50px;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .button-actions {
        .btn {
          min-width: 105px;
        }
      }
    }

    .clinic-search {
      position: relative;
      margin-bottom: 0;
      .list-group-item {
        display: flex;
        padding: 10px !important;
        border-top-color: $lightest-grey;
        border-bottom-color: $lightest-grey;

        .sugestion_refrenceid {
          width: 70px;
          min-width: 70px;
          text-align: center;
          margin-right: 10px;
        }
      }
      .clinic_search_address {
        color: $dark-grey;
        font-size: 12px;
      }
      & .clinic-search-input {
        border-radius: 4px;
        border-color: $light-grey;
        font-size: 24px;
        color: $darkest-grey;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 60px;
        &:focus {
          border-color: $green-main;
        }
      }

      .lnir-search-alt {
        position: absolute;
        right: 4%;
        top: 52%;
        font-size: 30px;
        color: $grey;
      }

      .no-suggestions,
      .suggestions {
        &.list-group {
          position: absolute;
          width: 100%;
          z-index: 200;
          max-height: 250px;
          overflow-y: scroll;
          top: 94px;
        }

        .list-group-item {
          padding: 20px;
          font-size: 16px;
          color: $black;
          cursor: pointer;

          &:hover {
            background-color: $lightest-grey;
          }
        }
      }
    }

    &.step-sendapp-link {
      .step-header {
        margin-bottom: 25px;
      }

      .text-information {
        margin-top: 0;
        margin-bottom: 45px;
        font-size: 16px;
      }
      .DropDown_Component .Top_area .placeholder {
        color: $light-grey;
      }

      .input-send-container {
        margin-bottom: 30px;

        .form-group {
          position: relative;
          text-align: left;

          &:last-child {
            margin-bottom: 0;
          }

          .di {
            position: absolute;
            left: 20px;
            top: 12px;
            color: $placeholder-icon-color;
          }

          .form-control {
            border-radius: 4px;
            border-color: $light-grey;
            padding-left: 20px;
            height: 62px;
            font-size: 24px;
            color: $darkest-grey;
            margin-bottom: 30px;

            &::placeholder {
              color: $light-grey;
            }

            &:focus {
              box-shadow: none;
              border-color: $green-main;
            }
          }
        }

        label {
          color: $darkest-grey;
          font-size: 16px;
        }
      }
      .button-actions {
        margin-top: 40px;
        .btn {
          min-width: 186px;
          position: relative;
          .icon {
            margin-right: 27px;
            font-size: 18px;
            top: 0px;
            position: absolute;
            left: 44px;
            top: 9px;
          }
          span {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .register-invoice-modal {
    .step-content {
      color: $select-title-color;

      .step-body {
        .select-options {
          .select-item {
            &:hover {
              background-color: $sundhedplus-lightest-grey;
            }

            .di {
              color: $sundhedplus-red-main;
            }
          }
        }

        .input-container {
          background-color: $sundhedplus-lightest-grey;

          .clinic-search {
            position: relative;
            width: 100%;

            i {
              position: absolute;
              color: $grey;
            }

            .no-suggestions,
            .suggestions {
              .list-group-item {
                &:hover {
                  background-color: $sundhedplus-lightest-grey;
                }
              }
            }
          }

          #clinicSearch {
            &.form-control {
              border-color: $sundhedplus-light-grey;
              color: $sundhedplus-darkest-grey;
            }
          }

          .form-group {
            .form-control {
              border-color: $sundhedplus-light-grey;
            }
          }
        }
      }

      .text-information {
        .btn-app-link {
          display: flex;
          margin: auto;
          color: $sundhedplus-red-main;
        }
      }

      .clinic-search {
        .list-group-item {
          border-top-color: $sundhedplus-lightest-grey;
          border-bottom-color: $sundhedplus-lightest-grey;
        }

        & .clinic-search-input {
          border-color: $sundhedplus-light-grey;

          &:focus {
            border-color: $grey;
          }
        }

        .no-suggestions,
        .suggestions {
          .list-group-item {
            &:hover {
              background-color: $sundhedplus-lightest-grey;
            }
          }
        }
      }

      &.step-sendapp-link {
        .DropDown_Component .Top_area .placeholder {
          color: $sundhedplus-light-grey;
        }

        .input-send-container {
          .form-group {
            .form-control {
              border-color: $sundhedplus-light-grey;
              color: $darkest-grey;

              &::placeholder {
                color: $sundhedplus-light-grey;
              }

              &:focus {
                box-shadow: none;
                border-color: $sundhedplus-light-grey;
              }
            }
          }
        }

        .button-actions {
          .btn {
            border-radius: 20px 20px 20px 20px;
          }
        }
      }
    }
  }
}
