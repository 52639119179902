@import "src/resources/scss/utilities/variables";

.KebabMenu_Component {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $lightest-grey;
  border: solid 1px $lightest-grey;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: $lighter-grey;
  }

  .menu-container {
    .menu-dots {
      position: absolute;
      left: 18px;
      top: 12px;

      i {
        transform: rotate(90deg);
        position: absolute;
        left: -6px;
        top: -1px;
      }
    }

    .open {
      display: block;
    }

    .close {
      display: none;
    }

    .menu-items {
      position: absolute;
      width: 160px;
      border-radius: 4px;
      box-shadow: 0px 0px 6px 0px rgba(120, 120, 120, 0.25);
      border: 0px;
      background-color: $white;
      padding-top: 10px;
      padding-bottom: 5px;
      font-size: 12px;
      color: $darkest-grey;
      z-index: 1;

      a {
        color: $darkest-grey;

        &:hover {
          text-decoration: none;
        }
      }

      .KebabMenu-item:not(.application):hover {
        color: $green-main;
      }

      i {
        font-size: 14px;
      }
    }

    .menu-items.on-bottom {
      top: 42px;
      right: -2px;

      &:before {
        position: absolute;
        top: -10px;
        right: 10px;
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        z-index: 3;
      }

      &:after {
        /* This is the shadow triangle */
        position: absolute;
        top: -12px;
        right: 8px;
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $lightest-grey;
        z-index: 2;
      }
    }

    .menu-items.on-top {
      bottom: calc(100% + 5px);
      top: auto;
      right: -2px;

      &:after {
        position: absolute;
        bottom: -10px;
        right: 10px;
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid white;
        z-index: 3;
      }

      &:before {
        position: absolute;
        bottom: -13px;
        right: 8px;
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid $lightest-grey;
        z-index: 2;
      }
    }




    .KebabMenu-item {
      padding: 9px 15px;
      display: flex;
      align-items: center;


      .icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 25px;
      }

      &.application {
        align-items: flex-start;
        line-height: 14px;

        .icon {
          svg {
            width: 14px !important;
            height: 14px !important;
          }
        }
      }


      .text {
        flex: 1;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: -4px;

    .menu-container {
      .menu-dots {
        position: absolute;
        left: 12px;
        top: 8px;

        i {
          transform: rotate(90deg);
          position: absolute;

          top: -1px;
        }
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .KebabMenu_Component {
    background-color: $sundhedplus-lightest-grey;
    border: solid 1px $sundhedplus-lightest-grey;
    cursor: pointer;

    &:hover {
      background-color: $sundhedplus-lighter-grey;
    }

    .menu-container {
      .menu-items {
        color: $sundhedplus-darkest-grey;

        a {
          color: $sundhedplus-darkest-grey;

          &:hover {
            text-decoration: none;
          }
        }

        .KebabMenu-item:not(.application):hover {
          color: $sundhedplus-brawn;

          svg path {
            fill: $sundhedplus-brawn;
          }
        }

        .KebabMenu-item.application:hover {
          color: initial;
          background-color: $lightest-grey;
        }
      }
    }
  }
}