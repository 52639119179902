@import 'src/resources/scss/utilities/variables';

.entities-list-section .entities-list-group .list-group-item .info-location {
    flex-direction: column;
    &.application {
        font-size: 12px;
        line-height: 20px;
        color: #888;
    }
}
#Denti {
    .applications-list-group, .application-data-item {
        .d-logo, .application-status {
            color: #fff;
            &.deprecated  {
                background-color: $logo-grey;
            }
            &.failed {
                background-color: $logo-red;
            }
            &.rejected {
                background-color: $logo-red;
            }
            &.pending-manual {
                background-color:  $logo-yellow;
            }
            &.pending, &.in-progress  {
                background-color: $status-dialogue;
            }
            &.done {
                background-color: $logo-green;
            }
        }
    }
    .application-data-item {
        .d-logo {
            color: $green-main;
        }
    }
}


#Sundhedplus {
    .applications-list-group, .application-data-item {
        .d-logo, .application-status {
            color: #fff;
            &.deprecated {
                background-color: $sundhedplus-logo-grey;
            }
            &.failed {
                background-color: $sundhedplus-logo-red;
            }
            &.rejected {
                background-color: $sundhedplus-logo-red;
            }
            &.pending-manual {
                background-color:  $sundhedplus-logo-yellow;
            }
            &.pending, &.in-progress {
                background-color: $sundhedplus-logo-blue;
            }
            &.done {
                background-color: $sundhedplus-logo-green;
            }
        }
    }
    .application-data-item {
        .d-logo {
            color: $sundhedplus-brawn;
        }
    }
}
