@import 'src/resources/scss/utilities/variables';

.NoPatientCredit_component{
  // height: 110px;
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  .icon_area {
    width: 100%;
    padding-top: 110px;
    padding-bottom: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 54px;
      &.green{
        color: $green-main;
      }
      &.red{
        color: $text-status-red;
      }
      &.gray{
        color: $grey;
      }
    }
  }
  .content_area {
      max-width: 480px;
    .top_area {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      padding-bottom: 20px;
      color: $grey;

    }
    .bottom_area {
      color: $grey;
      font-size: 14px;
      text-align: center;

    }
    .button_area{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn{
        i{
          margin-right:8px;
        }
      }
    }
  }
}
