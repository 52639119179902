@import 'src/resources/scss/utilities/variables';

.unsubscribeBookingRequestPage {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  font-family: 'Maven Pro', sans-serif;

  .unsubscribeBookingRequest-wrapper {
    margin: auto;

    .sundhed-plus-logo {
      margin: 0 auto 20px auto;
      width: 180px;

      .sundhed-logo {
        width: 100%;
        height: auto;
      }
    }
    .denti-logo {
      display: flex;
      position: relative;
      top: 38px;
      justify-content: center;

      .d-logo {
        background-color: $logo-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        width: 70px;
        height: 70px;
        font-size: 26px;
        .di-denti {
          position: relative;
          margin: auto;

          font-size: 29px;
          color: $white;
        }
      }
    }
    .unsubscribeBooking-content {
      display: flex;
      width: 479px;
      box-shadow: 0 0 27px 4px rgba(102, 102, 102, 0.17);
      min-height: 811px;
      padding: 10px 25px;
      margin: 0 auto;

      .unsubscribeBooking-body {
        .color {
          color: $green-main;
        }
        .unsubscribeBooking-header {
          display: flex;
          font-size: 32px;
          font-weight: 700;
          color: $darker-grey;
          text-align: center;
          margin: 53px 55px 40px 55px;
        }
        .unsubscribeBooking-text {
          font-size: 18px;
          font-weight: 300;
          color: $very-dark-black;
          margin-bottom: 20px;
          line-height: 24px;
        }
        .unsubscribeBooking-button {
          align-items: center;
          display: flex;
          justify-content: center;
          margin-top: 110px;
          .btn-size {
            min-height: 40px;
            min-width: 140px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .unsubscribeBookingRequestPage {
    .unsubscribeBooking-content {
      font-family: 'Overpass', sans-serif;
      .unsubscribeBooking-body {
        .color {
          color: $sundhedplus-red-main;
        }
        .unsubscribeBooking-header {
          margin: 29px 55px 40px 55px;
          font-family: 'Playfair Display';
        }
      }
    }
  }
}
