@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/breakpoints';

.activating-credit-modal {
  min-height: 400px;
  height: 100%;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form_icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;

    .form_icon_ico {
      width: 60px;
      height: 60px;
      path {
        fill: $sundhedplus-status-accepted;
      }
    }
  }

  .form_title {
    color: $darkest-grey;
    margin-top: 25px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.83;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  .form_subtitle {
    margin-top: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .form_control {
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }

    .form_control_group {
      margin-bottom: 20px;

      .note-card {
        display: flex;
        gap: 20px;
        width: 100% !important;
        justify-content: flex-end;

        .support-info {
          font-size: 12px;
          color: #8e8e8e;
          align-self: flex-end;
          .support-user {
            margin-left: 10px;
          }
        }
      }
    }

    .form_text {
      display: flex;
      justify-content: space-between;

      div:last-of-type:not(:first-of-type) {
        font-size: 12px;
        line-height: 24px;
      }
    }

    .form_input {
      width: 100%;
      height: 62px;
      margin-top: 5px;
      padding: 0 15px;
      border-radius: 4px;
      border: solid 1px $light-grey;
      background-color: $white;
      font-size: 30px;
      text-align: left;
      color: $darkest-grey;

      &:invalid {
        border: solid 1px $error-color;
      }

      &:invalid:focus {
        border-color: $error-color;
      }

      &:focus {
        border-color: $light-grey;
      }
    }

    textarea.form_input {
      height: 83px;
      font-size: 14px;
      line-height: 24px;
      padding: 13px 27px 14px 16px;
      &::placeholder {
        color: #afafaf;
      }
    }

    .input_danger {
      border-color: $error-color !important;

      &:focus {
        border-color: $error-color;
      }
    }

    input,
    textarea {
      &:focus {
        outline: none;
      }

      @include media-breakpoint-only(sm) {
        font-size: 36px;
      }

      @include media-breakpoint-only(md) {
        font-size: 36px;
      }
    }
  }

  label:not(.form_text) {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: $darkest-grey;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .buttonArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 38px 0 10px 0;
    @media only screen and (max-width: 390px) {
      display: block;
    }

    button {
      padding-right: 40px;
      padding-left: 40px;
      margin: 0 10px;
      min-width: 170px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 390px) {
        margin: 10px auto;
      }
    }
  }

  .eror_notification {
    text-align: center;
    padding: 0 24px;
    font-size: 14px;
  }

  .conclusions-section {
    background-color: #faf8f6cc;
    width: 80%;
    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: space-around;
    padding: 10px 20px;
    margin: 0 auto;

    .side-by-side-infobox {
      display: flex;
      width: 100%;
      align-items: center;
      text-align: center;

      span {
        font-size: 14px;
        margin: 0 auto;
      }
      .calculated-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        line-height: 28px;
        margin: 0 20px;
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-flow: column;
      padding: 15px;
    }
  }

  .calculation-error {
    margin-top: 10px;
  }
}

.center_modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 825px), only screen and (max-height: 900px) {
  .center_modal {
    align-items: flex-start;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .new_Invoice_Component {
    .form_title {
      color: $sundhedplus-darkest-grey;
    }

    .form_control {
      .form_input {
        border: solid 1px $sundhedplus-light-grey;
        color: $sundhedplus-darkest-grey;

        &:focus {
          border-color: $sundhedplus-light-grey;
        }
      }
    }

    label {
      color: $sundhedplus-darkest-grey;
    }
  }
}
