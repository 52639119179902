@import 'src/resources/scss/utilities/variables';


.ButtonLoader_component{
    color: #ffffff;
    font-size: 20px;
    margin: 6px auto;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    @-webkit-keyframes load4 {
      0%,
      100% {
        box-shadow: 0 -15px 0 1px, 10px -10px 0 0px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 0;
      }
      12.5% {
        box-shadow: 0 -15px 0 0, 10px -10px 0 1px, 15px 0 0 0, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 -5px;
      }
      25% {
        box-shadow: 0 -15px 0 -2.5px, 10px -10px 0 0, 15px 0 0 1px, 10px 10px 0 0, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 -5px;
      }
      37.5% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0px 0 0, 10px 10px 0 1px, 0 15px 0 0px, -10px 10px 0 -5px, -15px 0px 0 -5px, -10px -10px 0 -5px;
      }
      50% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 0px, 0 15px 0 1px, -10px 10px 0 0, -15px 0px 0 -5px, -10px -10px 0 -5px;
      }
      62.5% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 0, -10px 10px 0 1px, -15px 0 0 0, -10px -10px 0 -5px;
      }
      75% {
        box-shadow: 0px -15px 0 -5px, 10px -10px 0 -5px, 15px 0px 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 0, -15px 0px 0 1px, -10px -10px 0 0;
      }
      87.5% {
        box-shadow: 0px -15px 0 0, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 0, -15px 0px 0 0, -10px -10px 0 1px;
      }
    }
    @keyframes load4 {
      0%,
      100% {
        box-shadow: 0 -15px 0 1px, 10px -10px 0 0px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 0;
      }
      12.5% {
        box-shadow: 0 -15px 0 0, 10px -10px 0 1px, 15px 0 0 0, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 -5px;
      }
      25% {
        box-shadow: 0 -15px 0 -2.5px, 10px -10px 0 0, 15px 0 0 1px, 10px 10px 0 0, 0 15px 0 -5px, -10px 10px 0 -5px, -15px 0 0 -5px, -10px -10px 0 -5px;
      }
      37.5% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0px 0 0, 10px 10px 0 1px, 0 15px 0 0px, -10px 10px 0 -5px, -15px 0px 0 -5px, -10px -10px 0 -5px;
      }
      50% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 0px, 0 15px 0 1px, -10px 10px 0 0, -15px 0px 0 -5px, -10px -10px 0 -5px;
      }
      62.5% {
        box-shadow: 0 -15px 0 -5px, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 0, -10px 10px 0 1px, -15px 0 0 0, -10px -10px 0 -5px;
      }
      75% {
        box-shadow: 0px -15px 0 -5px, 10px -10px 0 -5px, 15px 0px 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 0, -15px 0px 0 1px, -10px -10px 0 0;
      }
      87.5% {
        box-shadow: 0px -15px 0 0, 10px -10px 0 -5px, 15px 0 0 -5px, 10px 10px 0 -5px, 0 15px 0 -5px, -10px 10px 0 0, -15px 0px 0 0, -10px -10px 0 1px;
      }
    }
}
