@import 'src/resources/scss/utilities/variables';

.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }
  
  .react-switch {
    max-width: 100% !important;
    display: block;
    cursor: pointer;
    width: 160px;
    height: 34px;
    background: #F9F9F9;
    position: relative;
    transition: background-color 0.2s;
    border: 1px solid #ECECEC;
    border-radius: 30px;
  }
  
  .react-switch .react-switch-button {
    position: absolute;
    top: 0px;
    border-radius: 30px;
    width: 80px;
    height: 33px;
    transition: 0.2s;
    background: $green-main;
    color: $white;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    left: calc(100%);
    transform: translateX(-100%);
  }
  
  .react-switch-checkbox:checked + .react-switch-button {
    left: calc(50%);
  }
  
  .react-switch-checkbox:active + .react-switch-button {
    width: 80px;
  }
  
  .react-switch-labels {
    display: relative;
    z-index: 0;
    height: 100%;
    color: $grey;
  }
  
  .react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    font-size: 14px;
    &.on, &.off {
      color: $white;
    }
  }
  .react-switch-labels span:first-child {
    left: 0;
  }
  .react-switch-labels span:last-child {
    right: 0;
  }

  // ####### SUNDHEDPLUS #########
#Sundhedplus {
  .react-switch .react-switch-button {
    background: $sundhedplus-logo-green;
  }
}
  