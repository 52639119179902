@import 'src/resources/scss/utilities/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');

.LiveAgent_page {
  height: 100%;
  width: 100%;
  display: block;
  font-family: 'Roboto';
  overflow-y: auto;
  overflow-x: hidden;

  .btn-orange {
    background-color: #f6921e;
    box-shadow: 0px 3px 3.7px 0.3px rgba(122, 122, 122, 0.39);
    font-size: 13px;
  }

  .btn-green {
    background-color: $sundhedplus-card-green;
    box-shadow: 0px 3px 3.7px 0.3px rgba(122, 122, 122, 0.39);
    font-size: 13px;
  }

  .btn.disabled {
    background-color: $grey;
    box-shadow: none;
  }

  .btn-gray {
    background-color: #afafaf;
    // box-shadow: 0px 3px 3.7px 0.3px rgba(122, 122, 122, 0.39);
    font-size: 13px;
  }

  .Loader_component {
    margin-top: 50px;
  }

  .topLine_area {
    height: 7px;
    width: 100%;

    &.green {
      background-color: #90e496;
    }

    &.orange {
      background-color: #ffc107;
    }

    &.red {
      background-color: #dc3545;
    }
  }

  .LiveAgent_content {
    width: 100%;
    color: $darkest-grey;
    padding: 20px;

    .input_area {
      .textButton {
        height: 41px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #fafafa;
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;

        .noteText {
          margin-right: 10px;
          color: #afafaf;
          font-size: 12px;
        }

        .btn {
          height: 25px;
          padding: 5px 20px;
          margin-right: 10px;
          color: white;
          font-weight: bold;

        }
      }

      .ql-editor {
        width: 100%;
        background-color: #fffcec;
        color: #222222;
        padding: 10px 15px;
        border: 1px solid #eaeaea;
        max-height: 150px;
        line-height: 1.80;
      }
    }

    .titel_area {
      position: relative;
      display: flex;
      justify-content: unset;
      align-items: center;
      padding: 10px 0;
      font-size: 20px;

      .name_icon {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: inline-block;
        text-align: center;
        position: relative;
        margin-left: 8px;

        .di-denti {
          position: absolute;
          top: 6px;
          left: 9px;
          font-size: 14px;
        }

        .di-sundhedplus {
          position: absolute;
          top: 6px;
          left: 4px;
        }

        &.blue {
          background-color: #339ca6;
          color: white;
        }

        &.red {
          background-color: white;
          color: #b84949;
          align-self: baseline;
        }
      }
    }

    .buttons_area {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      .btn {
        display: block;
        // margin: 15px 22px;
        font-size: 14px;
        font-weight: 900;
        color: $white;
        padding: 10px 10px;
      }
    }

    .emailFlow_area {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 25px 0;
      font-size: 13px;
    }

    .patientdata_area {
      .dataRow:nth-of-type(odd) {
        background-color: #f8f8f8;
      }
    }

    .links-to-copy {
      margin-top: 20px;

      .link-input-group {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        label {
          font-size: 13px;
          line-height: 15px;
          font-family: 'Roboto';
          color: #222;
          margin-right: 20px;
        }

        input {
          height: 25px;
          background-color: #fff;
          border: 1px solid #DDDDDD;
          font-size: 13px !important;
          line-height: 15px !important;
          font-family: 'Roboto';
          font-weight: 300;
          color: #222;
          flex-grow: 1;
          padding: 5px;
          text-overflow: ellipsis;
          &:focus-visible, &:focus {
            border: 1px solid #DDDDDD;
            outline: none;
          }
        }
      }
    }

    .clinics_area {
      .clinic_area_name {
        margin: 30px 0 12px 0;
        font-size: 13px;
        font-weight: bold;
      }

      .dataRow:nth-of-type(even) {
        background-color: #f8f8f8;
      }
    }

    .dataRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      padding: 4px 0;

      .leftItem {
        padding-left: 10px;
        padding-right: 10px;
      }

      .rightItem {
        padding-right: 10px;

        &.brand {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 10px;

          &.brand-d {
            background-color: $green-main;
          }

          &.brand-s {
            background-color: $text-status-red;
          }
        }
      }

      .red {
        color: #dc3545;
      }

      .green {
        color: #4caf50;
      }

      .yelow {
        color: #f6921e;
      }

      .update-card-link {
        display: inline;
        color: #222;

        i {
          margin-left: 5px;
        }

        .tooltip-wrapper {
          position: relative;
          display: inline;
          cursor: pointer;

          span {
            text-decoration: underline;
          }

          .tooltip {
            opacity: 0;
            left: -10px;
            width: auto;
            text-decoration: none;
          }
        }

        &.copied {
          .tooltip {
            opacity: 1;
            visibility: visible;
            transform: translateX(0px) translateY(0px) translateZ(0);
          }
        }
      }
    }
  }

  .react-switch {
    border: none;
    width: auto;
    background: none;
    height: auto;
  }
}

.live-agent-container {
  .nav-tabs {
    .nav-link {
      background-color: #dedede;
      border-radius: 0;
      color: $darker-grey;
      margin-bottom: -2px;
      cursor: pointer;

      &.active {
        border: none;
        background-color: $white;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid $white;
      }
    }
  }

  .tab-content {
    .DropDown_Component {
      margin-bottom: 20px;

      .Top_area {
        height: 40px;
        color: $card-yellow;
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
        padding-left: 0;

        .triangle {
          top: 5px;
          right: 9px;
          width: 30px;
        }
      }

      .drop_area {
        max-height: 300px;

        .row {
          height: 40px;
          line-height: 40px;
          text-align: center;
          padding: 0;
        }

        .second_column .first.boldText {
          height: 40px;
          color: $card-yellow;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .tab-pane.active {
      .col-sm-12 {
        min-height: 350px;
      }
    }
  }
}

@media only screen and (max-width: 299px) {
  .LiveAgent_page .LiveAgent_content .buttons_area.patient-tab {
    display: inline-block;
    width: 100%;
    clear: both;

    .btn {
      width: 100%;
      float: none;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .LiveAgent_page .LiveAgent_content .buttons_area.patient-tab {
    display: inline-block;
    width: 100%;
    clear: both;

    .btn:nth-child(2) {
      width: calc(50% - 10px);
      float: left;
      margin-top: 15px;
    }

    .btn:nth-child(3) {
      width: calc(50% - 10px);
      float: right;
      margin-top: 15px;
    }
  }

  .LiveAgent_page .LiveAgent_content .buttons_area.booking-tab {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}

@media only screen and (min-width:400px) and (max-width: 768px) {
  .LiveAgent_page .LiveAgent_content .buttons_area.patient-tab {
    .btn {
      width: 30%;
      max-width: 250px;
      margin: 0;
    }
  }

  .LiveAgent_page .LiveAgent_content .buttons_area.booking-tab {
    .btn {
      width: 45%;
      display: flex;
      line-height: 18px;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 768px) {
  .LiveAgent_page .LiveAgent_content .buttons_area {
    .btn {
      width: 30%;
      max-width: 400px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .LiveAgent_page {
    .btn-orange {
      background-color: $vivid-orange;
    }

    .btn-gray {
      background-color: $grey;
    }
  }
}