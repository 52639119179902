@import 'src/resources/scss/utilities/variables';

.invoice_Success_Component{
  text-align: center;

  .success_icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;

    path {
      fill: $green-main;
    }
  }

  .invoice_title {
    margin-top: 20px;
    font-weight: bold;
  }

  .invoice_subtitle {
    font-weight: normal;
    font-size: 30px;
  }

  .invoice_steps {
    padding: 0px 62px;
    .invoice_step {
      display: flex;
      align-items: flex-start;
      padding-top: 38px;
      text-align: left;

      .step {
        margin-right: 30px;
        text-align: center;
        font-size: 32px;
        background-color: $lightest-grey;
        border-radius: 50%;
        flex: 0 0 52px;
        // width: 52px;
        // height: 52px;
      }
    }
      .invoice_step ~ .invoice_step{
        padding-top: 42px;
      }
  }

  .invoice_note {
    margin-top: 36px;
    background-color: $filter-reset;
    line-height: 1.5;
    font-size: 16px;
    padding: 17px 60px 19px;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .invoice_Success_Component {
    .success_icon {
      path {
        fill: $sundhedplus-status-accepted;
      }
    }

    .invoice_steps {
      .invoice_step {
        .step {
          background-color: $sundhedplus-lightest-grey;
        }
      }
    }
  }  
}