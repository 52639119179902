@import 'src/resources/scss/utilities/variables';

.grant_access_fail_Component{
  width:100%;
  text-align: center;
  
  .grant_access_fail_Component-header{
    margin-bottom: 40px;

    i {
      font-size: 60px;
      margin-bottom: 25px;
      color:$logo-red;
    }

    .title {
      font-size: 36px;
      font-weight: bold;
      color: $darkest-grey;
    }
  }

  .grant_access_fail_Component-content{
    font-size: 18px;
    width: 100%;
    padding-bottom: 26px;

    .firstText{
      margin: 0 auto;
      max-width: 450px;
      padding-bottom: 26px;
    }
    .secondText {
      margin: 0 auto;
      max-width: 450px;
    }
  }
  
  &.reasign {
    .grant_access_fail_Component-header{
      margin-bottom: 0;
      .title {
        font-size: 32px;
        padding: 20px;
      }
    }
    .grant_access_fail_Component-content{
      .firstText{
        padding-bottom: 0;
      }
    }
  }
}
