@import 'src/resources/scss/utilities/variables';

.loadingMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loadingMessage:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #000;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

.LoadingSpinner {
  text-align: center;
  margin-bottom: 40px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 220px;
  height: 220px;
}

.lds-ring div {
  display: block;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 5px solid #fff;
  border-radius: 50%;
  border-color: $lightest-grey;
}

.lds-ring div div {
  display: block;
  position: absolute;
  z-index: 101;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  top: -5px;
  left: -5px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $sundhedplus-red-main transparent transparent transparent;
}

.lds-ring div div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}