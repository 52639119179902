@import 'src/resources/scss/utilities/variables';

.RadioButton_component {
  display: flex;
  flex-direction: column;

  .radio-button-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;

    &:hover div {
      background-color: $light-grey;
    }

    .radio-button {
      appearance: none;
      width: 22px;
      height: 22px;
      background-color: $lightest-grey;
      margin-right: 10px;
      border-radius: 50%;
      outline: none;
      transition: background-color 0.3s ease;

      &.checked {
        border: solid 8px $sundhedplus-selected-radio-button;
        border-color: transparent;
      }
    }

    .label {
      font-size: 14px;
      color: $darkest-grey;
      margin-left: 10px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .RadioButton_component {
    .radio-button-container {
      .radio-button {
        background-color: $sundhedplus-lightest-grey;

        &.checked {
          border: solid 8px $sundhedplus-selected-radio-button;
        }
      }

      .label {
        font-size: 14px;
        color: $sundhedplus-darkest-grey;
      }
    }
  }
}
