@import 'src/resources/scss/utilities/variables';

#CreditInfoComponent{
  border-bottom: 5px solid #f2f2f2;
  height: 110px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .icon_area {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 54px;
      &.green{
      color: $green-main;
      }
    }
  }
  .content_area {
    .top_area {
      font-size: 24px;
      font-family: "Maven Pro Medium";
      line-height: 24px;
    }
    .bottom_area {
      color: $grey;
      font-size: 14px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  #CreditInfoComponent{
    .icon_area {
      i {
        &.green{
        color: $sundhedplus-brawn;
        }
      }
    }
  }  
}