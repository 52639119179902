@import 'src/resources/scss/utilities/variables';

#ClinicIndexSection,
#documentsTypeList {
  .tab-nav {
    max-width: 200px;

    .nav-item {
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        a {
          background-color: #ececec;
        }
      }

      a {
        cursor: pointer;
        width: 100%;
        height: 100%;
        color: #222;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f2f2f2;
        border: none;

        i {
          color: $green-main;
          font-size: 22px;
        }

        &.active {
          border-bottom: 4px solid #e5e5e5;
          background-color: $white;
          color: #222;
        }
      }
    }
  }

  .nav-tabs {
    border-bottom: none;
  }

  .tab-container {
    width: calc(100% - 200px);
    margin-left: -1px;
  }
}

.booking-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 45px;

  .booking-option {
    padding: 25px 30px 16px 25px;
    width: 100%;
    min-height: 156px;
    background: #f8f8f8;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    color: #272525;

    &.selected {
      border: 1px solid $green-main;
    }
    &.disabled {
      /* Styles for disabled radio button */
      label {
        input[type='radio'] + *::before {
          background-color: #ccc;
        }
      }
    }
    .error {
      color: $text-status-red;
      padding: 4px;
      font-size: 12px;
    }

    .option-description {
      padding-left: 50px;
      font-size: 14px;
      line-height: 24px;

      input {
        height: 35px;
        background: $white;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        width: 100%;
        padding: 7px 90px 7px 15px;
        margin-top: 15px;

        &:focus-visible,
        &:active {
          border: 1px solid #d9d9d9;
          outline: none;
        }
      }
    }

    label {
      font-size: 18px;
      line-height: 24px;
    }

    label > input[type='radio'] {
      display: none;
    }

    label > input[type='radio'] + *::before {
      content: '';
      display: inline-block;
      vertical-align: bottom;
      width: 24px;
      height: 24px;
      margin-right: 25px;
      border-radius: 50%;
      border-style: solid;
      border-width: 2px;
      border-color: #d3d3d3;
      background-color: #fff;
    }

    label > input[type='radio']:checked + *::before {
      background: radial-gradient(
        $green-main 0%,
        $green-main 50%,
        transparent 60%,
        transparent
      );
      border-color: #d3d3d3;
    }

    label > input[type='radio'] + * {
      display: inline-block;
      cursor: pointer;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  #ClinicIndexSection,
  #documentsTypeList {
    .tab-nav {
      .nav-item {
        &:hover {
          a {
            background-color: #ececec;
          }
        }
        a {
          background-color: #f2f2f2;
          i {
            color: $sundhedplus-brawn;
          }
          &.active {
            background-color: $white;
          }
        }
      }
    }
  }

  .booking-options {
    .booking-option {
      &.selected {
        border: 1px solid $sundhedplus-logo-green;
      }

      label > input[type='radio']:checked + *::before {
        background: radial-gradient(
          $sundhedplus-logo-green 0%,
          $sundhedplus-logo-green 50%,
          transparent 60%,
          transparent
        );
      }
    }
  }
}
