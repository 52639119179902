.has-tooltip {
  position: relative;
  font-weight: 400;
  .link-tooltip {
    text-decoration: none;
    color: $darker-grey;

    @include hover-focus-active {
      color: $darker-grey;
      text-decoration: none;
    }
  }
  @include media-breakpoint-up(sm) {
    @include hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
        transform: translateX(0px) translateY(0px) translateZ(0);
      }
    }
  }

  &.top {
    .tooltip {
      bottom: 100%;
      top: auto;
      left: 50%;
      transform: translate3d(-50%, 10px, 0);
      margin-bottom: $tooltip-margin + $tooltip-arrow-height;

      &:before {
        @include tooltip-arrow($tooltip-bg, 'up');
        bottom: -$tooltip-arrow-height + 1;
        left: calc(50% - 7px);
        width: 12px;
      }
    }
  }

  &.bottom {
    .tooltip {
      top: 100%;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate3d(-50%, -10px, 0);
      margin-top: $tooltip-margin + $tooltip-arrow-height;

      &:before {
        @include tooltip-arrow($tooltip-bg, 'down');
        top: -$tooltip-arrow-height;
        left: calc(50% - 7px);
        width: 12px;
      }
    }
  }

  &.right {
    .tooltip {
      left: 100%;
      right: auto;
      bottom: auto;
      top: 50%;
      transform: translate3d(-10px, -50%, 0);
      margin-left: $tooltip-margin + $tooltip-arrow-height;

      &:before {
        @include tooltip-arrow($tooltip-bg, 'right');
        left: -$tooltip-arrow-height;
        top: 50%;
        width: 12px;
      }
    }
  }

  &.rightArrowCenterLeft {
    .tooltip {
      left: 100%;
      right: auto;
      bottom: auto;
      top: 50%;
      transform: translate3d(-10px, -50%, 0);
      margin-left: $tooltip-margin + $tooltip-arrow-height;

      &:before {
        @include tooltip-arrow($tooltip-bg, 'right');
        left: -$tooltip-arrow-height;
        width: 8px;
      }
    }
  }


  &.left {
    .tooltip {
      right: 100%;
      left: auto;
      bottom: auto;
      top: 50%;
      transform: translate3d(10px, -50%, 0);
      margin-right: $tooltip-margin + $tooltip-arrow-height;

      &:before {
        @include tooltip-arrow($tooltip-bg, 'left');
        right: -$tooltip-arrow-height;
        top: 50%;
        width: 12px;
      }
    }
  }
}

.tooltip {
  background-color: $tooltip-bg;
  color: $white;
  display: block;
  border-radius: 4px;
  opacity: 0;
  padding: 5px 10px;
  width: 120px;
  font-size: 12px;
  white-space: normal;
  text-align: center;
  position: absolute;
  visibility: hidden;
  transition: all .2s ease-out .2s;

  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  &:before {
    content: '';
    display: block;
    height: 1rem;
    position: absolute;
    width: 100%;
  }

  &.small {
    width: 100px;
  }

  &.medium {
    width: 120px;
  }

  &.large {
    width: 160px;
  }

}