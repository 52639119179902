@import 'src/resources/scss/utilities/variables';

.Step2 {
  .error{
    color: $red-main;
    text-align: center;
  }
  .hide{
    display: none;
  }
}
