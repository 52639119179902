@import 'src/resources/scss/utilities/variables';

#addCompanyLocation {
  overflow: visible;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 760px;
  height: 100%;
  flex-direction: column;

  .content {
    text-align: center;
    padding: 25px 10px 0;
    background-color: $white;
  }

  .controls {
    margin-top: 30px;
    text-align: center;
  }
  .actions-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 15px auto;
    button {
      width: 170px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
    }
    button:last-child {
      margin-left: 17px;
    }
  }

  /* STEP */
  .step {
    .icon {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 60px;
      }
    }
    .title {
      color: $darkest-grey;
      font-size: 36px;
      font-family: $font-family-bold;
      font-weight: bold;
      line-height: 0.83;
      margin: 25px auto 30px auto;
    }
    .description {
      margin-bottom: 30px;
      text-align: center;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  /* STEP ROW */
  .step_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &.centered {
      margin-top: 50px;
      margin-bottom: 40px;
      justify-content: center;
      align-items: center;
    }
    .zip_area {
      width: 30%;
    }
    .city_area {
      width: 65%;
    }
    &.last_row {
      .inputComponent {
        margin-bottom: 5px;
      }
    }
  }

  .half_area {
    width: 48%;
  }

  /* STEPS CUSTOM STYLES */
  .step3 {
    .inputComponent {
      input {
        height: 50px;
      }
    }
  }
  .step4 {
    .description {
      font-size: 18px;
    }
    .icon {
      color: $green-main;
      line-height: 0.4;
      align-items: center;
      margin-top: 0;
      i {
        color: $green-main;
      }
    }
  }

  /* STEP 3 STYLES */
  .selector {
    display: flex;
    padding: 15px 25px;
    border: 1px solid $light-grey;
    border-radius: 30px;
    margin-bottom: 22px;
    &.invalid {
      border: 1px solid $text-status-red;
    }
    .roundOption {
      width: 31px;
      height: 31px;
      border-radius: 50px;
      border: 1px solid $light-grey;
      background-color: $lightest-grey;
      margin-right: 20px;
      cursor: pointer;
    }
    &.selected {
      background-color: $lightest-grey;
      .roundOption {
        border-width: 10px;
        border-color: $green-main;
      }
    }
    .icon {
      width: 216px;
      img {
        width: 100%;
        height: fit-content;
      }
    }
  }
  .selector.second {
    .icon {
      width: auto;
      i {
        font-size: 30px;
      }
    }
  }

  .textzone {
    display: flex;
    align-items: center;
    color: $very-dark-orange;
    margin-left: 20px;
  }

  .selectionContainer {
    min-height: 200px;
    position: relative;
  }

  .warning_zone {
    background-color: $filter-reset;
    padding: 15px 22px;
    text-align: left;
    font-size: 14px;
    border-radius: 4px;
    &.first {
      position: absolute;
      bottom: 20px;
    }
    &.second {
      margin-bottom: 65px;
    }
    .warning_row {
      display: flex;
      justify-content: space-between;
      .regno_area {
        width: 33%;
      }
      .acountNo_area {
        width: 64%;
      }
    }
  }

  .selector.first .warning_zone {
    margin-top: 120px;
  }

  /* STEP 4 STYLES */
  .locationData {
    width: 100%;
    background-color: $lightest-grey;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 25px;
    .name {
      width: 100%;
      text-align: left;
      font-weight: bold;
      font-size: 18px;
      color: $darkest-grey;
      margin-bottom: 25px;
    }
    .icon {
      width: auto;
      padding-top: 0;
      margin-top: -5px;
      i {
        font-size: 16px;
        font-weight: bold;
        color: $green-main;
      }
    }
    .line {
      display: flex;
      align-items: flex-start;
      min-height: 60px;
    }
    .column {
      flex: 50%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 25px;
    }
    .input_details {
      margin-left: 15px;
      .label {
        font-size: 12px;
        font-family: $font-family-medium;
        line-height: 1.83;
        text-transform: uppercase;
        text-align: left;
        color: $grey;
      }
      .value {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        &.empty {
          color: $grey;
        }
      }
    }
  }

  .more_info {
    margin: 28px 0;
    display: flex;
    justify-content: center;
    .lnir-alarm-clock {
      margin-right: 17px;
      font-size: 24px;
      color: $orange-main;
    }
  }
  .link_zone {
    margin-bottom: 70px;
  }
  .step_link {
    text-decoration: underline;
    margin-left: 10px;
    color: $green-main;
    cursor: pointer;
  }
}

/* STEP ERROR */
.step_error {
  color: $text-status-red;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.71;
  text-align: center;
}
.not_showing {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}
.showing {
  visibility: visible;
  transition: visibility 1s, opacity 0.5s linear;
  opacity: 1;
}

@media (max-width: 768px) {
  #addCompanyLocation {
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    .content {
      width: 100%;
    }
    .textzone {
      font-size: 12px;
      margin-left: 10px;
    }
    .selector .icon {
      width: 100px;
    }
  }
}
@media (max-width: 480px) {
  #addCompanyLocation {
    .content {
      padding: 0;
    }
    .textzone {
      font-size: 10px;
      margin-left: 10px;
    }
    .selector .icon {
      width: 100px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  #addCompanyLocation {
    .step4 {
      .icon {
        color: $sundhedplus-red-main;
        i {
          color: $sundhedplus-red-main;
        }
      }
    }

    /* STEP 3 STYLES */
    .selector {
      border: 1px solid $sundhedplus-light-grey;
      .roundOption {
        border: 1px solid $sundhedplus-light-grey;
        background-color: $sundhedplus-lightest-grey;
      }
      &.selected {
        background-color: $sundhedplus-lightest-grey;
        .roundOption {
          border-width: 10px;
          border-color: $sundhedplus-red-main;
        }
      }
    }
    .textzone {
      color: $sundhedplus-very-dark-orange;
    }

    /* STEP 4 STYLES */
    .locationData {
      width: 100%;
      background-color: $sundhedplus-lightest-grey;
      .name {
        color: $sundhedplus-darkest-grey;
      }
      .icon {
        i {
          color: $sundhedplus-red-main;
        }
      }
    }

    .more_info {
      .lnir-alarm-clock {
        color: $sundhedplus-orange-main;
      }
    }
    .step_link {
      color: $sundhedplus-red-main;
    }
  }
}
