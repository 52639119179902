@import 'src/resources/scss/utilities/variables';

#searchCompanyChain {
  width: 100%;

  .filter-loader {
    position: absolute;
    right: 35px;
    top: 35%;
    font-size: 30px;
    .ButtonLoader_component {
      color: $grey;
    }
  }
  .suggestions-loader {
    height: 63px;
    padding: 20px !important;
    .ButtonLoader_component {
      color: $grey;
    }
  }
  .filter-content {
    .filter-loader {
      right: 8%;
      .ButtonLoader_component {
        margin: 3px auto;
        width: 3px;
        height: 3px;
      }
    }
  }

  .lnir-search-alt {
    position: absolute;
    right: 5%;
    top: 35px;
    font-size: 20px;
    color: $grey;
  }
  .searchCompanyChain__input,
  .no-suggestions {
    border: 1px solid $light-grey;

    border-radius: 4px;
    width: 100%;
    font-size: 14px;
    color: $darkest-grey;
    &:focus {
      border: 1px solid $green-main;
      box-shadow: none;
    }
  }
  .searchCompanyChain__list {
    max-width: -webkit-fill-available;

    &.list-group {
      position: absolute;
      width: 60%;
      z-index: 200;
      padding: 0;
      margin: 0;
      max-height: 260px;
      overflow-y: auto;
    }

    .searchCompanyChain__list-item {
      margin: 0;
      font-size: 16px;
      color: $black;
      cursor: pointer;
    }
    li {
      padding: 10px;
      margin: 0;
      &:hover {
        background-color: $lightest-grey;
      }
    }
  }
}
