@import 'src/resources/scss/utilities/variables';

#CalendarCreditInfoComponent {
  border-bottom: 5px solid #f2f2f2;
  height: 110px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;

  .icon_area {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 54px;
      &.green{
        color: $green-main;
      }
      &.red{
        color: $status-debtCollection;
      }
      &.gray{
        color: $grey;
      }
      &.yelow {
        color: $status-yellow;
      }
    }
  }
  .content_area {
    .top_area{
      font-size: 24px;
      line-height: 24px;
      font-family: $font-family-medium;
      &.green{
        color: $green-main;
      }
      &.yelow{
        color: $status-yellow;
      }
      &.red{
        color: $status-debtCollection;
      }
    }
    .bottom_area {
      color: $grey;
      font-size: 14px;
      line-height: 24px;
      @media only screen and (max-width: 600px) {
        line-height: 18px;
      }
    }
  }

  .editCreditFacilityButton {
    position: absolute;
    right: 7px;
    top: 7px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $lightest-grey;
    border: solid 1px $lightest-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      transition: all .2s ease-in-out;
      background-color: $lighter-grey;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  #CalendarCreditInfoComponent  {
    .icon_area {
      i {
        &.green {
          color: $sundhedplus-card-green;
        }
      }
    }

    .content_area {
      .top_area {
        line-height: 24px;
        &.green {
          color: $sundhedplus-card-green;
        }
      }
      .bottom_area {
        color: $grey;
      }
    }

    .editCreditFacilityButton {
      background-color: $sundhedplus-lightest-grey;
      border: solid 1px $sundhedplus-lightest-grey;
      &:hover {
        background-color: $sundhedplus-lighter-grey;
      }
    }
  }
}