@import 'src/resources/scss/utilities/variables';

.credit-facility-modal {
  min-width: 620px;
  color: $very-dark-black !important;

  .modal-body .card {
    border: 0;
    position: initial;
  }

  .title-text {
    font-size: 32px;
    line-height: 1;
    font-family: $font-family-bold;
  }

  .nav-tabs {
    border: none;
    cursor: pointer;

    .nav-item {
      min-width: 120px;
      margin-bottom: -2px;
      .nav-link {
        color: $darker-grey;
        text-align: center;
        background-color: $lightest-grey;
        font-size: 14px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        &.active,
        &.show .nav-link {
          color: $green-main;
          border: none;
          border-bottom: 2px solid $green-main;
        }
      }
    }
  }
  .tab-content {
    background-color: $lightest-grey;
    border-radius: 8px;
    border: 0px;
    color: $darker-grey;
    min-height: 200px;
  }

  .credit-details {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 14px;
    .row {
      .col {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &:first-child {
          border-right: 1px solid $logo-grey;
        }
      }
    }
  }
}
.hide-textarea {
  display: none;
}

.fadeOutAndHide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.edit-credit-form, .restructuring-form {
  padding: 30px 23px;
  font-size: 14px;
  .form-control {
    border: 1px solid $light-grey;
    font-size: 14px;
    &:focus, &:active, &:visited {
      border: 1px solid $light-grey;
      box-shadow: none;
      transition: none;
    }
  }
  .row {
    margin-bottom: 20px;
    label {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }
  }
  textarea {
    min-height: 142px;
  }

  .inlineInputError {
    padding: 0 0 0 2px;
  }
  .calculation-error {
    color: $text-status-red;
    padding: 4px;
    font-size: 12px;
  }

  .interest-wrapper,
  .amount-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    input {
      text-align: left;
      padding-right: 30px;
    }
    span {
      position: absolute;
      top: 11px;
      right: 10px;
      line-height: 100%;
      vertical-align: middle;
      font-size: 16px;
      color: $darker-grey;
    }
    &.last {
      input {
        width: 100%;
      }
    }
    &.min-duration {
      .form-control {
        padding: 0.375rem 0.75rem 0.375rem 0.75rem;

      }
      &.error {
        .form-control {
          border: 1px solid $status-red !important;
          color: $text-status-red;
        }
      }
    }
  }
  .interest-wrapper input {
    padding-right: 25px;
  }
  .credit-date-picker {
    position: relative;
    i {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
      font-size: 18px;
      pointer-events: none;
      color: $grey;
    }
    .popover {
      min-width: auto;
      border: 1px solid rgba(0, 0, 0, 0.2);
      .bg-primary {
        background-color: $green-main !important;
        color: $white;
      }
    }
  }

  .custom_select__control {
    border: 1px solid $light-grey;
    transition: none;
    box-shadow: none;
  }
  .custom_select__indicator-separator {
    display: none;
  }
  .custom_select__indicators .custom_select__dropdown-indicator {
    padding: 5px;
    color: hsl(0, 0%, 80%);
    svg {
      width: 30px !important;
      height: 30px !important;
      path {
        stroke: #fff;
        stroke-width: 1px;
      }
    }
  }
  .custom_select__option--is-focused,
  .custom_select__option--is-selected,
  .custom_select__option:hover {
    background-color: $grey-hover;
    color: initial;
    border: none;
    cursor: pointer;
    transition: none;
  }
  &.invalidInput .custom_select__control {
    border: 1px solid $status-red;
    border-radius: 8px;
    box-shadow: none;
  }

  .btn {
    width: 175px;
    height: 40px;
    font-size: 14px;
  }
  .btn-red {
    background: $logo-red;
    color: $white;
  }
}
.edit-credit-form {
  .form-group {
    margin-bottom: 0;
  }
  .form-control:not(textarea) {
    width: 235px;
    height: 40px;
  }
  .form-control.estimated {
    width: 100%;
  }
  textarea {
    min-height: 80px;
  }
    .display-amounts {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  .calculated-results {
    display: flex;
    justify-content: space-between;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .credit-facility-modal {
    min-width: 620px;
    color: $sundhedplus-very-dark-black !important;
    .nav-tabs {
      .nav-item {
        .nav-link {
          background-color: $sundhedplus-lightest-grey;
          &.active,
          &.show .nav-link {
            color: $sundhedplus-red-main;
            border: none;
            border-bottom: 2px solid $sundhedplus-red-main;
          }
        }
      }
    }
    .tab-content {
      background-color: $sundhedplus-lightest-grey;
    }
  
  }
  .edit-credit-form, .restructuring-form {
    padding: 30px 23px;
    font-size: 14px;
    .form-control {
      border: 1px solid $sundhedplus-light-grey;
      font-size: 14px;
      &:focus, &:active, &:visited {
        border: 1px solid $sundhedplus-light-grey;
        box-shadow: none;
        transition: none;
      }
    }
    .credit-date-picker {
      position: relative;
      i {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        font-size: 18px;
        pointer-events: none;
        color: $grey;
      }
      .popover {
        min-width: auto;
        border: 1px solid rgba(0, 0, 0, 0.2);
        .bg-primary {
          background-color: $sundhedplus-red-main !important;
          color: $white;
        }
      }
    }

    .custom_select__control {
      border: 1px solid $sundhedplus-light-grey;
      transition: none;
      box-shadow: none;
    }
  }
  
}