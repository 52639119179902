.navbar-wrapper {
  display: block;
  width: 100%;
  position: relative;

  background-color: $dark;

  // Define common button styles
  .common-btn-styles {
    background-color: $sundhedplus-lighter-grey;
    color: #444444;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    path {
      fill: currentColor;
    }

    &:hover {
      background-color: #aa2d36;
      color: white;
      border: 1px solid #444;

      path {
        fill: white;
      }
    }
  }

  .navbar-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 56.63px;
    background-color: $dark;
    z-index: 9999;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .menu {
      display: flex;
      align-items: center;

      .userDashboard {
        font-size: 22px;
        font-family: 'Maven Pro', sans-serif;
        font-weight: 500;
        line-height: 28px;
        color: $white;
      }

      .menu-item {
        margin-left: 20px;
        min-height: 40px;

        button {
          @extend .common-btn-styles;
          max-height: 40px;
        }

        .actions-btn {
          width: 100%;
          margin-right: 10px;
          border: 1px solid #444444;
          height: 40px;

          .actions-btn-label {
            justify-content: center;
            display: flex;
            align-items: center;
            max-width: 180px;
          }
        }

        .add-patient-btn {
          width: 100%;
          margin-right: 10px;
          border: 1px solid #444444;

          .add-patient-label {
            justify-content: center;
            display: flex;
            align-items: center;
            max-width: 180px;

            i {
              justify-content: center;
              display: flex;
              margin: auto;
              align-items: center;
              width: 18px;
              height: 18px;
            }

            &:hover {
              svg {
                fill: $white;
              }
            }
          }
        }

        .circle-btn {
          @extend .common-btn-styles;
          border-radius: 50%;
          width: 38px;
          height: 38px;
          align-items: center;
          display: flex;
          justify-content: center;

          padding: 5px;
          box-sizing: border-box;
        }

        .circle-btn .icon {
          width: 18px;
          height: 18px;
          margin: auto;
          fill: currentColor;
        }

        .circle-btn:hover .icon {
          fill: white;
          /* Hover color without !important */
        }
      }
    }

    .menu-item .circle-btn.active,
    .menu-item .add-patient-btn.active {
      background-color: #aa2d36;
      color: white;
      border: 1px solid #444444;
    }

    .menu-item .circle-btn.active path,
    .menu-item .add-patient-btn.active path {
      fill: white;
    }
  }

  .logo {
    max-width: fit-content;

    img {
      height: 30px;
    }

    a {
      max-width: 180px;

      @include plain-all {
        text-decoration: none;
        color: $white;
      }
    }

    .di-denti {
      display: block;
      position: absolute;
      top: 2.5%;
    }

    .brandLogo {
      display: block;
      opacity: 0;
    }

    @include media-breakpoint-up(lg) {
      .di-denti {
        animation: easeOut 0.4s linear normal 1;
      }

      .brandLogo {
        animation: easeOut 0.4s linear normal 1, fadeOut ease 0.5s;
      }
    }

    // Repeated styles are removed, and styles are combined based on media queries
    @include media-breakpoint-up(xwd) {
      margin-left: 0;

      .di-denti {
        display: block;
        position: absolute;
        top: 2.5%;
      }

      .brandLogo {
        display: block;
        opacity: 0;
      }

      @include media-breakpoint-up(lg) {
        .di-denti {
          animation: easeOut 0.4s linear normal 1;
        }

        .brandLogo {
          animation: easeOut 0.4s linear normal 1, fadeOut ease 0.5s;
        }
      }

      // Repeated styles are removed, and styles are combined based on media queries
      @include media-breakpoint-up(xwd) {
        margin-left: 0;

        .di-denti {
          display: none;
        }

        .brandLogo {
          display: block;
          opacity: 1;
          margin: 0 auto;
          animation: none;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 0 auto;

        .di-denti {
          display: none;
        }

        .brandLogo {
          opacity: 1;
        }
      }
    }
  }
}