@import "src/resources/scss/utilities/variables";

.list_of_associated_users {
  .header_wrapper {
    width: 100%;
    padding: 0 30px;
    color: $white;
    background-color: $darkest-grey;
    border-color: $black;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-size: 14px;

    .lead_wrapper {
      display: flex;
      height: 42px;

      div {
        display: flex;
        align-items: center;
      }
    }
  }
  &.card {
    margin-top: 0;
  }
  .contentWrapper {
    width: 100%;
    padding: 0 30px; // 4%
    .login_field {
      .pending {
        color: $status-yellow;
      }
    }
  }

  .table_separator {
    border-bottom: 5px solid #339ca6;
    margin: 0;
  }

  .lead_row {
    display: flex;
    border-bottom: 1px solid $lighter-grey;
    padding: 16px 0;
    align-items: center;
    font-size: 14px;
  }
  .name_field,
  .email_field,
  .role_field,
  .login_field {
    min-width: calc(25% - 10px);
    max-width: calc(25% - 10px);
  }
  .role_field {
    a {
      color: $black;
      text-decoration: underline;
    }
    .tooltip {
      bottom: 100%;
      top: auto;
      left: 50%;
      transform: translate3d(-50%, 10px, 0);
      margin-bottom: 24px;
      &::before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #222222;
        width: 0;
        height: 0;
        bottom: -7px;
        left: calc(50% - 7px);
        width: 12px;
      }
    }
  }
  .seeDetailsButton {
    min-width: 40px;
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 136px;
      .lnir {
        margin-right: 10px;
        color: $green-main;
        font-size: 16px;
      }
    }
  }

  .button_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 105px;
    .btn {
      .lnir {
        margin-right: 0.5rem;
      }
    }
  }
  .NoCompanis {
    color: $grey;
    width: 100%;
    height: 250px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .NoCompanis_container {
      width: 370px;
      text-align: center;
    }
  }
  .confirmfirstline-message {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 680px) {
    .table-size {
      width: 680px;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .list_of_associated_users {
    .header_wrapper {
      background-color: $sundhedplus-darkest-grey;
    }

    .table_separator {
      border-bottom: 5px solid $sundhedplus-brawn;
      margin: 0;
    }

    .lead_row {
      border-bottom: 1px solid $sundhedplus-lighter-grey;
    }

    .seeDetailsButton {
      .btn {
        .lnir {
          color: $sundhedplus-red-main;
        }
      }
    }
  }
}
