@import "src/resources/scss/utilities/variables";

.list-of-company-chains {
  .header_wrapper {
    width: 100%;
    padding: 0 30px;
    color: $white;
    background-color: $darkest-grey;
    border-color: $black;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-size: 14px;

    .lead_wrapper {
      display: flex;
      height: 42px;

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0 30px; // 4%
    position: relative;
  }

  .table-separator {
    border-bottom: 5px solid #339ca6;
    margin: 0;
  }

  .lead_row {
    display: flex;
    border-bottom: 1px solid $lighter-grey;
    padding: 16px 0;
    align-items: center;
    font-size: 14px;
  }
  .name-field {
    min-width: 30%;
    max-width: 30%;
    padding-right: 10px;
  }
  .reference-field,
  .status-field,
  .create-date-field,
  .revenue-field {
    min-width: calc(17.5% - 33px);
    max-width: calc(17.5% - 33px);
  }
  .create-date-field.lowercase {
    text-transform: lowercase;
  }
  .seeDetailsButton {
    width: 136px;
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 136px;
      .lnir {
        margin-right: 10px;
        color: $green-main;
        font-size: 16px;
      }
    }
  }

  .button-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 105px;
    .btn {
      .lnir {
        margin-right: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .table-size {
      width: 680px;
    }
  }
}
.new-location-modal {
  min-width: 620px;
  @media (max-width: 768px) {
    min-width: auto;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .list-of-company-chains {
    .header_wrapper {
      background-color: $sundhedplus-darkest-grey;
    }

    .table-separator {
      border-bottom: 5px solid $sundhedplus-brawn;
    }

    .lead_row {
      display: flex;
      border-bottom: 1px solid $sundhedplus-lighter-grey;
    }

    .seeDetailsButton {
      .btn {
        .lnir {
          color: $sundhedplus-brawn;
        }
      }
    }
  }
}
