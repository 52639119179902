.sidebar-wrapper {
  color: $white;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 100%;
  position: fixed;
  z-index: 101;
  transform: translate3d(100%, 0, 0);
  visibility: hidden;
  padding: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  overflow: auto;

  @include media-breakpoint-up(lg) {
    position: relative;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    width: 70px;
    height: 100vh;
    background-color: #f6f0ee;
  }

  @include media-breakpoint-up(xwd) {
    width: 250px;
  }

  @media (min-width: 1390px) {
    &.dashboard-sidebar {
      width: 250px !important;
      position: relative !important;
      visibility: visible !important;
      transform: translate3d(0, 0, 0) !important;
      overflow: hidden !important;
    }
  }

  &.open {
    transform: translate3d(0, 0, 0);
    /* Initial position */
    visibility: visible;
    height: calc(var(--vh, 1vh) * 100);

    @include media-breakpoint-down(md) {
      transform: translate3d(calc(100% - 250px),
          0,
          0);
      /* Adjusted position for medium screens */
    }
  }

  .sidebar-section {
    background-color: $sundhedplus-red-secudary;
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: space-between;
    max-width: 250px;
    z-index: 10;
    position: relative;
    box-shadow: 10px 0 30px 0 rgba($black, 0.3);

    @include media-breakpoint-up(lg) {
      box-shadow: none;
      height: 100%;
      position: relative;
      left: 0;
      max-width: 100%;
      min-width: 250px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 50;
      min-height: 50px;
      background-color: $darkest-grey;

      img {
        display: flex;
        align-items: center;

        padding: 0 10px;
        margin: auto 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      margin-top: 80px;

      @include media-breakpoint-down(lg) {
        overflow-y: auto;
        height: 1px;
        min-height: 1px;
      }

      @include media-breakpoint-down(md) {
        padding: 0 15px 0 15px;
        justify-content: space-between;
        margin-top: 28px;
      }
    }

    .menu {
      padding: {
        bottom: 0.5rem;
      }

      .top-menu-item {
        margin-top: 0;

        .menu-item {
          padding: 0;
          margin: 0;
        }

        hr {
          border-bottom: 0 solid #d1d1d1;
          width: 100%;
        }
      }

      .list-group {
        padding-bottom: 15px;
        // background-color: $form-black;
      }

      .list-group-item {
        // background-color: $form-black;
        // color: $dark-grey;

        border: 0;
        display: flex;
        flex-direction: row;
        padding: 4px 25px 4px 50px;
        cursor: pointer;

        position: relative;
        align-items: center;
        @include font-style(12px, 400, 12px);

        &.active {
          color: $white;

          @include font-style(12px, 500, 12px);

          &:before {
            position: absolute;
            top: 8px;
            margin: 1px 0 0 -21px;
            border-radius: 4px;
            vertical-align: middle;
            display: inline-block;
            width: 7px;
            height: 7px;
            // background: $green-main;
            content: '';
          }
        }
      }

      .menu-item {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding-bottom: env(safe-area-inset-bottom) !important;
        margin-bottom: env(safe-area-inset-bottom) !important;

        button {
          text-decoration: none;
          color: $dark-grey;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        button,
        a {
          padding: 0px 0px 16px 8px;
          margin-left: 6px;
          cursor: pointer;
          @include font-style(14px, 500, 16px);
          display: block;

          @media screen and (min-width: 768px) and (max-width: 980px) {
            font-size: 16px;
          }

          @include plain-all() {
            color: rgb(68, 68, 68, 1);
            text-decoration: none;
            outline: 0;
          }

          .di,
          .lnir,
          .lnil {
            font-size: 24px;
            width: 48px;
            height: 48px;
            padding: 12px;
            margin-right: 10px;
          }
        }

        &:hover {

          button,
          a {
            color: rgb(68, 68, 68, 1);
            font-weight: bold;

            font-size: 16px;
          }

          button .di:hover,
          .lnil:hover,
          .lnir:hover,
          &:hover .di,
          &:hover .lnil,
          &:hover .lnir {
            background-color: $sundhedplus-light-grey;
            border-radius: 50%;
            width: 48px;
            height: 48px;
          }

          .svg-icon {
            background-color: $sundhedplus-light-grey;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            padding: 12px;
          }
        }

        &.active {

          button,
          a {
            color: rgb(68, 68, 68, 1);
            font-weight: bold !important;
            width: 100%;
            font-size: 16px;
          }

          .di,
          .lnil,
          .svg-icon,
          .lnir {
            color: $white;
            background-color: rgb(68, 68, 68, 1);
            border-radius: 50%;
            width: 48px;
            height: 48px;
          }

          .di:hover,
          .lnil:hover,
          .lnir:hover,
          .svg-icon:hover,
          &:hover .di,
          &:hover .lnil,
          &:hover .lnir {
            color: $white;
            background-color: rgb(68, 68, 68, 0.7);
            border-radius: 50%;
            width: 48px;
            height: 48px;
          }

          path {
            fill: white;
          }

          &:hover {

            button,
            a {
              color: rgb(68, 68, 68, 0.7);
            }
          }
        }

        &.user-info {
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
          }

          .name_icon {
            max-width: 38px;
            min-width: 38px;
            height: 38px;
            border-radius: 45px;
            background-color: #faf8f6;
            border: 1px solid #d1d1d1;
            color: #444444;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
          }

          .clinicInfo {
            .companyname {
              font-size: 1rem;
            }

            .username {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              color: #8e8e8e;
            }
          }
        }

      }
    }

    .footer {
      margin-top: auto;

      hr {
        border-bottom: 0 solid #d1d1d1;
        width: 100%;
      }
    }

    // menu item with active or hover change the padding: 16px 0 16px 8px;
    .menu-item.active,
    .menu-item:hover {
      padding: 0;
      margin: 0;
    }

    .icon-wrapper {
      position: relative;
      display: inline;

      width: fit-content;

      .notification-count {
        width: 20px;
        height: 20px;
        background-color: #d63031;
        border-radius: 20px;
        color: white;
        text-align: center;
        position: absolute;
        top: -18px;
        right: 10px;
        padding: 3px;
        border-style: solid $form-black;
        border-width: 2px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &:hover {
    @include media-breakpoint-up(lg) {
      width: 250px;

      .header {
        .title {
          margin-left: 0;

          .di-denti {
            opacity: 0;
            animation: easeIn linear 0.3s; // out
          }

          .brandLogo {
            opacity: 1;
            // transition: opacity 1s ease-in;
            animation: fadeIn ease 2s;
            margin: 0 auto;
          }
        }
      }
    }
  }

  @keyframes easeIn {
    0% {
      transform: translate(23px, 0);
      opacity: 1;
    }

    50% {
      transform: translate(30px, 0);
      opacity: 0.8;
    }

    80% {
      transform: translate(40px, 0);
      opacity: 0.3;
    }

    100% {
      transform: translate(50px, 0);
      opacity: 0;
    }
  }

  @keyframes easeOut {
    0% {
      transform: translate(30px, 0);
      opacity: 0.2;
    }

    50% {
      transform: translate(20px, 0);
      opacity: 0.5;
    }

    80% {
      transform: translate(10px, 0);
      opacity: 0.8;
    }

    100% {
      transform: translate(0px, 0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
}