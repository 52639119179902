@import 'src/resources/scss/utilities/variables';

.Step5 {

  .title{
    margin-bottom: 20px;
  }
  .content{
      text-align: left;
      padding-bottom: 5px;
  }
}
