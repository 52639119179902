/* CUSTOM TABLES */
.custom-table {

    .table-header,
    .table-body {
        width: 100%;

        .table-row-div {
            display: grid;
            grid-template-columns: 20% 20% 15% 20% 15% 10%;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
    }

    .table-header,
    .table-separator,
    .table-body {
        min-width: 600px;
    }

    &.manager {

        .table-header,
        .table-body {
            .table-row-div {
                grid-template-columns: 15% 20% 20% 25% 15% 5%;
            }
        }
    }

    &.dentist {

        .table-header,
        .table-body {
            .table-row-div {
                grid-template-columns: 25% 25% 25% 20% 5%;
            }
        }
    }

    &.agreement {
        &.empty {
            min-height: 100px;
        }

        .table-header,
        .table-body {
            .table-row-div {
                grid-template-columns: 15% 10% 20% 15% 20% 15% 5%;

                @include media-breakpoint-down(md) {
                    grid-template-columns: 18% 8% 17% 12% 16% 20% 5%;
                }

                .table-cell-div {
                    &.loan-type {
                        font-size: 25px;
                    }

                    // &:last-child{
                    //     justify-content: flex-end;
                    //     text-align: right;
                    // }
                }
            }
        }
    }


    .table-header {
        padding: 0 30px;
        color: $white;
        background-color: $darkest-grey;
        border-color: $black;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        font-size: 14px;

        .table-cell-div {
            height: 42px;
            line-height: 42px;
        }
    }

    .table-body {
        width: 100%;
        display: block;

        .scrollbar-wrapper {
            width: 100%;
        }

        .table-row-div {
            border-bottom: 1px solid #f6f0ee;
            font-size: 14px;
            padding: 0px 30px;

            @include media-breakpoint-down(md) {
                padding: 0px 15px;
            }

            // &:last-child {
            //     border-bottom: none;
            // }
        }

        .table-cell-div {
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &.actions-menu {
                justify-content: flex-end;
            }
        }
    }

    .table-separator {
        border-bottom: 5px solid #339ca6;
        margin: 0;
    }

}

$start: 0;
$end: 5;

@for $i from $start through $end {
    .invoices-list-#{$i} {
        height: #{$i*62+3}px;
    }
}

.invoices-list-scroll {
    height: 345px;

    .track-horizontal+div {
        right: 0 !important;
    }
}

.card-table-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 25px;

    &.has-scroll {
        box-shadow: 0px -7px 6px -6px rgba(0, 0, 0, 0.1);
    }

    &.agreement {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .btn {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.invoice-list-item,
.loan-list-item {
    .KebabMenu_Component {
        margin-left: 5px;

        // background: none;
        // border: none;
        .menu-container {
            .menu-items {
                padding: 10px 5px;
                width: 180px;
            }

            .KebabMenu-item {
                padding: 10px 15px;

                &.last {
                    border-top: 1px solid #f6f0ee;
                }

                &:hover {
                    color: $green-main;

                    svg {
                        fill: $green-main;
                    }
                }
            }
        }
    }
}

.loan-list-item .KebabMenu_Component .menu-container .KebabMenu-item:last-child:not(:first-child) {
    border-top: none;
}

.loan-list-item {
    cursor: pointer;

    .type {
        font-family: $font-family-regular;

        i {
            font-size: 14px;
        }
    }

    &.status-active {
        .type {
            background-color: $green-main;
            color: $white;
        }

        .status,
        .loan-type {
            color: $green-main;

            .percentage-icon {
                fill: $green-main;
            }
        }
    }

    &.status-inactive {
        color: $grey;

        .type {
            background-color: $lighter-grey;
            border-color: $light-grey;
            color: $darker-grey;
        }

        .loan-type {
            color: $grey;

            .percentage-icon {
                fill: $grey;
            }
        }
    }

    &.status-red {
        .type {
            background-color: #dd8e8a;
            color: $white;
        }

        .status,
        .loan-type {
            color: $sundhedplus-red-main;

            .percentage-icon {
                fill: $sundhedplus-red-main;
            }
        }
    }

    &.status-disabled {
        border-bottom: 1px solid $light-grey;
        background-color: #fcfcfc;
        color: $grey;

        .table-cell-div:last-of-type {
            color: #444444;
        }

        .type {
            background-color: $white;
            color: $darker-grey;
            border: 1px solid #f6f6f6;
        }

        // .table-cell-div:first-child {
        //     padding-left: 15px;
        // }
        &.first {
            box-shadow: inset 0px 11px 8px -12px rgba(204, 204, 204, 0.3);
        }

        &.last {
            box-shadow: inset 0px -11px 8px -10px rgba(204, 204, 204, 0.3);
        }

        &.first.last {
            box-shadow: inset 0px 11px 8px -12px rgba(204, 204, 204, 0.3), inset 0px -11px 8px -10px rgba(204, 204, 204, 0.3);
        }
    }

}

.consolidated-group {

    //box-shadow: 0px 11px 8px -10px #CCC, 0px -11px 8px -10px #CCC
    .status-disabled:not(.last) {
        border: none !important;
    }

    // for the last row of consolidated, single or multiple group
    &.last {

        .last.single,
        &.first.last {
            box-shadow: inset 0px 11px 8px -12px rgba(204, 204, 204, 0.3) !important;
        }

        .last.multiple {
            box-shadow: none;
        }
    }
}


.table-cell-div:last-of-type {
    position: absolute;
    right: 40px;

    @include media-breakpoint-down(md) {
        right: 10px;
    }
}


// ####### SUNDHEDPLUS #########
#Sundhedplus {
    .custom-table {
        .table-separator {
            border-bottom: 5px solid $sundhedplus-brawn;
        }
    }

    .KebabMenu-item:hover {
        color: $sundhedplus-brawn;

        svg {
            fill: $sundhedplus-brawn;
        }
    }

    .loan-list-item {

        &.status-active {
            .type {
                background-color: $sundhedplus-status-agreement;
                color: $white;
            }

            .status,
            .loan-type {
                color: $sundhedplus-status-agreement;

                .percentage-icon {
                    fill: $sundhedplus-status-agreement;
                }
            }
        }

        &.status-inactive {
            .type {
                background-color: $lighter-grey;
                border-color: $light-grey;
                color: $darker-grey;
            }
        }

        &.status-red {
            .type {
                background-color: #dd8e8a;
                color: $white;
                border-color: transparent;
            }

            .status,
            .loan-type {
                color: $sundhedplus-red-main;

                .percentage-icon {
                    fill: $sundhedplus-red-main;
                }
            }
        }

        &.status-disabled {
            background-color: $light;
            color: $grey;

            //border-bottom: none;
            .type {
                background-color: $white;
                color: $grey;
                border: 1px solid #f6f6f6;
            }
        }

    }
}