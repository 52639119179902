/* MAIN LOGIN */
#main-login {
  font-family: $font-family-regular;
  width: 100%;
  min-height: 100vh;
  background-position: top right;
  display: flex;
  background-size: cover;

  // Default fallback to jpg
  background-image: url('#{$images-path}/login-background-optimized.jpg');

  // Check for avif support
  @supports (background-image: url('#{$images-path}/login-background-optimized.avif')) {
    background-image: url('#{$images-path}/login-background-optimized.avif');
  }

  // Check for webp support
  @supports (background-image: url('#{$images-path}/login-background-optimized.webp')) {
    @supports not (background-image: url('#{$images-path}/login-background-optimized.avif')) {
      background-image: url('#{$images-path}/login-background-optimized.webp');
    }
  }

  aside {
    width: 40%;
    min-width: 600px;
    min-height: 100vh;
    background-color: #f6f0ee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 80px;

    .sundhed-plus-logo {
      padding-bottom: 60px;

      @media screen and (min-width: 768px) and (max-width: 980px) {
        padding-bottom: 30px;
      }
    }

    .need-help {
      font-size: 16px;
      line-height: 24px;
      color: #444;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h6 {
        font-family: $font-family-medium;
        font-size: 18px;
        line-height: 27px;
        display: block;
      }

      a {
        color: #444;
      }
    }
  }

  .image-container {
    width: 60%;
    text-align: center;
    padding-top: 40px;
    height: 100%;
  }

  .login-box {
    padding: 20px 40px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 40px;
    max-width: 490px;

    h2 {
      font-family: $font-family-bold;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      color: #444;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }

    .button-wrapper {
      display: flex;
      justify-content: center;

      button {
        font-family: $font-family-medium;
        width: 260px;
        height: 54px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
          font-size: 20px;

          path {
            fill: white;
          }
        }

        span {
          padding-left: 10px;
          font-size: 16px;

          text-transform: uppercase !important;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    #main-login {
      min-height: 100vh;
    }

    .login-box {
      padding: 30px;
    }

    aside {
      display: flex;
      min-height: 100vh;
      width: 100%;
      padding: 0 20px;
      min-width: fit-content;
    }

    .image-container {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  #main-login {
    min-height: 100vh;
    overflow: hidden;

    aside {
      min-height: 100vh;

      .sundhed-plus-logo {
        text-align: center;
        padding-top: 20px;
      }
    }

    // .image-container svg {
    //   position: absolute;
    //   top: 40px;
    //   right: 30%;
    // }
  }
}

.login-modal {
  .modal-content {
    background-clip: unset;
    border: none;
  }

  .modal-content .modal-body {
    padding: 0;
  }
}

/* LOGIN */
.user-login {
  position: relative;
  width: 100%;

  .form-wrapper {
    z-index: 100;
    width: 100%;

    #loginForm,
    #forgotPasswordForm {
      min-height: 367px;

      border-radius: 3px;
      position: relative;
      z-index: 1;
      background: #faf8f6;
      width: 100%;
      padding: 30px 40px;
      text-align: center;

      h2 {
        font-family: $font-family-bold;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #444;
        margin-bottom: 20px;
      }

      button {
        margin-top: 0 !important;
      }

      input:disabled {
        background-color: $light-grey;
      }
    }

    #forgotPasswordForm {
      .text-error {
        margin: 10px 0 !important;
        position: relative !important;
        bottom: 0 !important;
      }
    }

    #loginForm {
      height: 374px !important;
      padding: 0;
      margin: 0;
    }

    #loginForm,
    #forgotPasswordForm,
    #resetPassword {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100% !important;
      border-radius: 3px;
      padding: 40px;
      position: relative;
      z-index: 1;
      width: 100%;
      text-align: center;

      .resetPasswordValidation {
        max-width: 420px;
        width: 100%;
        padding: 0;
        margin: auto;
        color: $white;
      }

      .col-sm-6 {
        padding: 0;
        text-align: left;
        font-size: 15px;
      }

      .valid {
        color: $sundhedplus-status-green;
      }

      .invalid {
        color: $white;

        &.valid {
          color: $sundhedplus-status-green;
        }
      }

      .row {
        margin-bottom: 10px;

        // @media only screen and (max-width: 500px) {
        //   margin-bottom: 0px;

        //   .col-sm-6 {
        //     padding-left: 7px;
        //   }
        // }

        i {
          margin: 0 8px;
        }
      }

      div.form-group {
        position: relative;
        margin-bottom: 0;

        svg {
          position: absolute;
          top: 10px;
          left: 15px;
        }

        &:first-of-type {
          margin: 10px 0;
        }

        input.form-group.active {
          border: 2px solid $green-main;
          color: $green-main;
        }
      }

      input {
        border-radius: 20px;
        outline: 0;
        border: 1px solid #e2dbd9;
        width: 100%;
        margin: 0 0 10px;
        padding: 10px 15px;
        box-sizing: border-box;
        min-height: 44px;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.83;
        letter-spacing: 0.4px;
        color: #444;
        background-color: $white;
        padding-left: 50px;

        &::placeholder {
          color: #444;
        }
      }

      label {
        display: none;
      }

      button {
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        outline: none;
        border: 0;
        border-radius: 40px 40px 40px 0px;
        text-transform: uppercase;
        cursor: pointer;
        color: $white;
        background-color: $green-main;
        border-color: $green-main;
        height: 54px;
        @include font-style(16px, 500, 24px);
        font-stretch: normal;
        letter-spacing: 0.4px;
        width: 100%;
      }

      label {
        text-align: left;
        width: 100%;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .text-error {
        color: #be323c;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        position: absolute;
        bottom: -10px;
      }

      .login-button {
        text-decoration: none;
        padding: 12px 10px;
        cursor: pointer;
        outline: none;
        background-color: $green-main;
        border-color: $green-main;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 1px;
        display: inline-block;
        min-width: 200px;
        font-family: 'Maven Pro Bold';
      }

      .login-error-message {
        color: #be323c;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        width: 80%;
        margin: 10px auto;
      }

      .login-warning-message {
        color: $vivid-orange;
        font-size: 16px;
        line-height: 20px;
      }

      .login-newlink-message {
        color: #72ae8c;
        font-size: 14px;
        line-height: 21px;
        padding-top: 10px;
      }
    }

    .forgot-password {
      font-family: $font-family-regular;
      width: 100%;
      cursor: pointer;
      color: #969696;
      font-size: 12px;
      display: block;
      text-align: right;
      margin-bottom: 40px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;

      &.green {
        color: $sundhedplus-status-green;
        text-align: center;
        padding: 20px;
      }

      &.red {
        color: $error-color;
        text-align: center;
        padding: 20px 10px 0 10px;
        margin-bottom: 12px;
      }

      &.headline {
        text-align: center;
        padding: 20px 10px 0 10px;
        margin-bottom: 12px;
      }
    }

    .login-bottom {
      text-align: center;
      min-height: 84px;

      .help {
        color: #be323c;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        width: 80%;
        margin: 10px auto;

        strong {
          margin-top: 20px;
          display: block;
        }

        p {
          margin: 0;
        }

        .login-help-text {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
        }

        .login-help-number {
          font-size: 24px;
          font-family: 'Maven Pro Bold';
          line-height: 1;
        }
      }
    }
  }
}

.forgot-password.user-login {
  .form-wrapper {
    .login-bottom {
      min-height: 50px;
    }

    #forgotPasswordForm {
      margin-bottom: 0;

      button {
        outline: none;
        margin-bottom: 0;
        margin-top: 0 !important;
      }
    }
  }
}

.user-login.reset-password {
  .form-wrapper {
    .reset-headline {
      color: #fff;
      text-align: center;
      margin-bottom: 40px;
    }

    .logo {
      margin-bottom: 5%;
    }

    .login-bottom {
      min-height: 55px;
    }

    form#resetPassword {

      &.success,
      &.error {
        background: none;
      }

      .text-error {
        position: relative;
        bottom: 0;
        margin-top: 5px;
      }

      div.form-group.has-error {
        input {
          margin: 0;
        }
      }
    }
  }
}

/* TO BE REMOVED AFTER REDESIGN OF RESET PASS */
#old-login {

  /* LOGIN */
  &.user-login {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: none;
    background-color: $sundhedplus-login-bg-color;
    overflow: auto;

    @include media-breakpoint-only(sm) {
      padding: 20px 0;
    }

    .form-wrapper {
      z-index: 100;
      background-color: $dark;
      max-width: 480px;
      width: 100%;
      padding: 30px;
      box-shadow: 0 0 20px 0 rgba($black, 0.2), 0 5px 5px 0 rgba($black, 0.24);
      margin: auto;

      @include media-breakpoint-up(sm) {
        max-width: 500px;
      }

      .logo {
        color: $white;
        text-align: center;
        font-size: 45px;
        margin-bottom: 10%;
      }

      #loginForm {
        border-radius: 3px;
        position: relative;
        z-index: 1;
        background: $form-black;
        width: 100%;
        padding: 30px 40px;
        margin: 0 auto 20px;

        text-align: center;

        button {
          margin-top: 0 !important;
        }

        input:disabled {
          background-color: $light-grey;
        }
      }

      #loginForm,
      #forgotPasswordForm,
      #resetPassword {
        border-radius: 3px;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 auto 20px;
        text-align: center;

        .resetPasswordValidation {
          max-width: 420px;
          width: 100%;
          padding: 0;
          margin: auto;
          color: $white;
        }

        .col-sm-6 {
          padding: 0;
          text-align: left;
          font-size: 15px;
        }

        .valid {
          color: $sundhedplus-status-green;
        }

        .invalid {
          color: $white;

          &.valid {
            color: $sundhedplus-status-green;
          }
        }

        .row {
          margin-bottom: 10px;

          @media only screen and (max-width: 500px) {
            margin-bottom: 0px;

            .col-sm-6 {
              padding-left: 7px;
            }
          }

          i {
            margin: 0 8px;
          }
        }

        div.form-group {
          position: relative;

          &:first-of-type {
            margin: 10px 0;
          }

          input.form-group.active {
            border: 2px solid $green-main;
            color: $green-main;
          }
        }

        input {
          border-radius: 20px;
          outline: 0;
          border: 2px solid $logo-color;
          width: 100%;
          margin: 0 0 20px;
          padding: 10px 20px;
          box-sizing: border-box;
          min-height: 44px;
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.83;
          letter-spacing: 0.4px;
          color: $dark-grey;
          background-color: $white;
          padding-left: 50px;

          &::placeholder {
            color: $dark-grey;
          }
        }

        label {
          display: none;
        }

        span.di {
          position: absolute;
          top: 11px;
          left: 20px;
          z-index: 1;
          color: $dark-grey;
          cursor: pointer;
          font-size: 21px;
          font-weight: 400;
          line-height: 1;
        }

        button {
          margin-top: 40px;
          margin-bottom: 5px;
          padding: 12px 10px;
          outline: none;
          border: 0;
          border-radius: 20px;
          text-transform: capitalize;
          cursor: pointer;
          color: $white;
          background-color: $green-main;
          border-color: $green-main;
          min-height: 44px;

          @include font-style(14px, 700, 0.71);
          font-stretch: normal;
          letter-spacing: 0.4px;
          width: 100%;

          @include media-breakpoint-up(md) {
            min-width: 200px;
            max-width: 200px;
          }
        }

        img {
          max-height: 70px;
          margin-bottom: 20px;
        }

        label {
          text-align: left;
          width: 100%;
          color: $white;
          text-transform: uppercase;
          margin-bottom: 10px;
          font-size: 14px;
        }

        .text-error {
          color: $error-color;
          text-align: left;
          font-size: 16px;
          line-height: 20px;
          position: absolute;
          bottom: -4px;
        }

        .login-button {
          text-decoration: none;
          padding: 12px 10px;
          cursor: pointer;
          outline: none;
          background-color: $green-main;
          border-color: $green-main;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 1px;
          display: inline-block;
          min-width: 200px;
          font-family: 'Maven Pro Bold';
        }

        .login-error-message {
          color: $error-color;
          font-size: 16px;
          line-height: 20px;
        }

        .login-warning-message {
          color: $vivid-orange;
          font-size: 16px;
          line-height: 20px;
        }

        .login-newlink-message {
          color: $sundhedplus-logo-green;
          font-size: 16px;
          line-height: 20px;
        }
      }

      .forgot-password {
        font-family: 'Maven Pro Bold' !important;
        width: 100%;
        color: $white;
        font-size: 14px;
        float: left;
        text-align: center;
        margin-bottom: 10px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;

        &.green {
          color: $sundhedplus-status-green;
          text-align: center;
          padding: 20px;
        }

        &.red {
          color: $error-color;
          text-align: center;
          padding: 20px 10px 0 10px;
          margin-bottom: 12px;
        }

        &.headline {
          text-align: center;
          padding: 20px 10px 0 10px;
          margin-bottom: 12px;
        }
      }

      .login-bottom {
        text-align: center;
        min-height: 84px;

        .help {
          color: $white;

          p {
            margin: 0;
          }

          .login-help-text {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
          }

          .login-help-number {
            font-size: 24px;
            font-family: 'Maven Pro Bold';
            line-height: 1;
          }
        }
      }
    }
  }

  &.user-login.reset-password {
    .form-wrapper {
      .reset-headline {
        color: #fff;
        text-align: center;
        margin-bottom: 40px;
      }

      .logo {
        margin-bottom: 5%;
      }

      .login-bottom {
        min-height: 55px;
      }

      form#resetPassword {

        &.success,
        &.error {
          background: none;
        }

        .text-error {
          position: relative;
          bottom: 0;
          margin-top: 5px;
        }

        div.form-group.has-error {
          input {
            margin: 0;
          }
        }
      }
    }
  }

  .form-wrapper {
    #resetPassword {
      input {
        border-radius: 4px;
        padding: 10px 20px 10px 50px;
      }

      div.form-group {
        input.form-group.active {
          border: 0;
          color: $sundhedplus-very-dark-black;
        }
      }

      button {
        border-radius: 20px 20px 20px 0px;
        background-color: $sundhedplus-red-main;
        border-color: $sundhedplus-red-main;
      }

      .login-button {
        background-color: $sundhedplus-red-main;
        border-color: $sundhedplus-red-main;
      }
    }
  }
}

/* TO BE REMOVED AFTER REDESIGN OF RESET PASS */

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .user-login {
    .form-wrapper {

      #loginForm,
      #forgotPasswordForm,
      #resetPassword {
        input {
          border-radius: 4px;
          padding: 10px 20px 10px 50px;
        }

        div.form-group {
          input.form-group.active {
            border: 0;
            color: $sundhedplus-very-dark-black;
          }
        }

        button {
          max-width: 123px;
          height: 54px;

          background-color: $sundhedplus-red-main;
          border-color: $sundhedplus-red-main;
        }

        .login-button {
          background-color: $sundhedplus-red-main;
          border-color: $sundhedplus-red-main;
        }
      }
    }
  }
}