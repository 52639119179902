.agreement-page-wrapper {
  background-color: $lightest-grey;
  position: relative;

  .menu-toggler {
    z-index: 1;
    position: absolute;
    top: 1rem;
    left: 1rem;

    @include media-breakpoint-only(md) {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
  }

  .create-agreement {
    .topSection {
      padding-top: 60px;

      @include media-breakpoint-up(md) {
        padding-top: 75px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 40px;
      }
    }
  }

  .full-page-agreement {
    display: flex;
    flex-direction: column;
    padding: {
      left: 15px;
      right: 15px;
    }

    .text-row, .tab-row {
      justify-content: center;
    }

    .text-row {
      flex: 0 0 auto;
    }

    .tab-row {
      min-height: 1px;
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;

      @include media-breakpoint-only(sm) {
        margin-bottom: 30px;
      }
    }
  }
}

.create-agreement {
  // width: 33.3%;
  margin: 0 auto;
  box-sizing: border-box;
  width: 90%;

  @include media-breakpoint-up(md) {
    width: 100%;
  }

  .topSection {
    padding-top: 40px;
    text-align: left;
    color: $dark;

    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }

    h2, p {
      margin-bottom: 16px;

      @include media-breakpoint-up(md)  {
        margin-bottom: 24px;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: 32px;
      }

      @include media-breakpoint-up(wd) {
        margin-bottom: 37px;
      }
    }

    h2 {
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.3px;
      @include font-style(24px, 700, 1);

      @include media-breakpoint-up(md) {
        @include font-style(30px, 700, 1);
      }
    }

    p {
      @include font-style(16px, 400, 1.38);
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.3px;
      text-align: left;
    }
  }

  &.modal-display {
    .topSection {
      padding-top: 30px;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
      }
    }
  }


  .nav-tabs {
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;

    .nav-item {
      width: 50%;

      .nav-link {
        color: $darker-grey;
        border: none;
        border-bottom: 2px solid rgba($black, 0.125);;
        text-align: center;
        background-color: $lightest-grey;

        &.active, &.show .nav-link {
          color: $green-main;
          border: none;
          border-bottom: 2px solid $green-main;
        }
      }
    }
    
  }

  .nav-tabs-section {

    .nav-link {
      .di {
        margin-right: 5px;
        font-size: 13px;
        vertical-align: -2px;
      }
    }
  }

  .tab-content {
    padding-top: 30px;


    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 50px;
    }

    .sent-text-wrapper {
      line-height: 1.33;
      letter-spacing: 0.4px;
      font-size: 12px;
      color: $darker-grey;

      .card-body {
        div.link-wrapper {
          span {
            font-weight: 500;
          }

          a {
            text-decoration: underline;
            color: $darker-grey;
          }
        }

        p.signature {
          margin: 0;
        }

        p.signature-name {
          font-family: "Maven Pro Bold";
          font-size: 12px;
        }
      }
    }

    form {
      .form-group {
        width: 80%;
        margin: 0 auto;
        // display: flex;

        .send-label {
          text-transform: uppercase;
          @include font-style(14px, 500, 14px);
          display: block;
          padding-left: 2%;
        }

        input {
          background-color: $white;
          border: 1px solid rgba($black, 0.125);
          margin-right: 2%;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: 0.4px;
          color: $dark-grey;
          min-height: 44px;

          @include hover-focus-active {
            box-shadow: none;
          }

          @include media-breakpoint-down(xs) {
            display: block;
            width: 100%;
          }
        }

        button {
          width: 40%;
          text-transform: none;
          font-style: normal;
          font-stretch: normal;
          @include font-style(14px, 700, 0.71);
          letter-spacing: 0.4px;
          min-height: 44px;
          padding: {
            left: 10px;
            right: 10px;
          }
          text-align: center;
          border: none;

          @include media-breakpoint-down(sm) {
            display: block;
            width: 100%;
            margin-top: 1rem;
          }
        }

        @include media-breakpoint-down(md) {
          width: 90%;
        }
      }

      &.invalid {
        .invalid-feedback {
          display: block;
        }
      }
    }

    .email-container, .sms-container {
      .form-group {
        position: relative;

        &:before {
          font-family: 'denti-icon';
          position: absolute;
          top: 10px;
          left: 15px;
          color: $grey;
        }

        .form-control {
          padding-left: 40px;
        }

        .form-elements {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }
        }
      }
    }

    .email-container {
      .form-group {
        &:before {
          content: unicode($di-email-closed);
        }
      }
    }

    .sms-container {
      .form-group {
        &:before {
          content: unicode($di-phone);
        }
      }
    }
  }

  .email-template-preview {
    width: 80%;
    margin: 25px auto;
    display: none;
    background-color: $white;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .top-header {
      width: 100%;
      text-align: center;
      background-color: $green-main;
      padding: 1rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      img {
        width: 148px;
        height: auto;
      }
    }

    .banner-image {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }
    }

    .body {
      padding: 1rem;

      .top-description {
        color: $darkest-grey;
        text-align: center;
        padding: 2rem 1rem;

        .title {
          color: $email-title;
          @include font-style(32px, 700, 42px);
        }

        .subtitle {
          color: $email-subtitle;
          margin-bottom: 2rem;
          @include font-style(20px, 400, 30px);
        }

        .action-button {
          @include font-style(17px, 700, 20px);
          padding: 10px 22px;
          border-radius: 0;
          @include plain-all {
            background-color: $email-action-button;
            border: 0;
          }
        }
      }

      .bottom-description {
        color: $email-description;
        text-align: center;
        padding: 1rem 1rem 0 1rem;
        margin-bottom: 2rem;

        p {
          @include font-style(16px, 400, 23px);
          margin-bottom: 1rem;
        }
      }

      .trust-pilot {
        margin-top: 2rem;
        margin-bottom: 1rem;

        a {
          display: inline-block;
          padding: 0 0.5rem;
          @include plain-all {
            text-decoration: none;
          }

          img {
            max-height: 20px;
          }
        }
      }
    }

    .footer {
      padding: 1rem;
      border-top: 1px solid $email-footer-border;
      color: $email-subtitle;

      div {
        padding-left: 1rem;

        &:first-child {
          padding-top: 1rem;
        }
      }
    }
  }

  .sms-template-preview {
    width: 80%;
    margin: 25px auto;
    padding: 1.5rem;
    border-radius: 20px;
    @include font-style(16px, 400, 1.25);
    color: $darkest-grey;
    background-color: $sms-preview-background;
    display: none;
    position: relative;

    @include media-breakpoint-up(md) {
      display: block;
    }

    a {
      @include plain-all {
        color: $darkest-grey;
        text-decoration: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: -10px;
      left: -30px;
      height: 25px;
      border-right: 50px solid $sms-preview-background;
      background: $sms-preview-background;
      border-bottom-right-radius: 80px 50px;
      transform: translate(0, -2px);
    }

    /* creates part of the curved pointy bit */
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: -10px;
      left: -20px;
      width: 50px;
      height: 25px;
      background: $lightest-grey;
      border-bottom-right-radius: 40px 50px;
      transform: translate(-30px, -2px);
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .agreement-page-wrapper {
    background-color: $sundhedplus-lightest-grey;
    position: relative;
  }
  .create-agreement {
    .nav-tabs {
      .nav-item {
        .nav-link {
          background-color: $sundhedplus-lightest-grey;
          &.active, &.show .nav-link {
            color: $sundhedplus-red-main;
            border-bottom: 2px solid $sundhedplus-red-main;
          }
        }
      }
    }

    .email-template-preview {
      .top-header {
        background-color: $sundhedplus-red-secudary;
      }
      .body {
        .top-description {
          color: $sundhedplus-darkest-grey;
        }
      }
    }
  
    .sms-template-preview {
      color: $sundhedplus-darkest-grey;
      a {
        @include plain-all {
          color: $sundhedplus-darkest-grey;
        }
      }
      &:after {
        background: $sundhedplus-lightest-grey;
      }
    }
  }
}