@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/breakpoints';

.createNewCompanyChain {
 .createNewCompanyChain-header {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   i{
     font-size: 60px;
     margin: 10px 0;
   }
   .title{
     font-size: 36px;
     font-family: $font-family-bold;
   }
 }
 .content {
   margin-top: 50px;
   padding: 0px 24px;
   .form_control_group {
     margin-bottom: 20px;
     width: 100%;
   }

   .form_text {
     display: flex;
     justify-content: space-between;

     div:last-of-type {
       font-size: 16px;
       line-height: 26px;
     }
   }

   .form_input {
     width: 100%;
     height: 62px;
     margin-top: 5px;
     padding: 0 15px;
     border-radius: 4px;
     border: solid 1px $light-grey;
     background-color: $white;
     font-size: 36px;
     text-align: left;
     color: $darkest-grey;

     &:invalid {
       border: solid 1px $error-color;
     }

     &:invalid:focus {
       border-color: $error-color;
     }

     &:focus {
       border-color: $light-grey;
     }
   }
   .input_danger{
     border-color: $error-color!important;
     &:focus {
       border-color: $error-color;
     }
   }

    input {
      &:focus {
        outline: none;
      }
      @include media-breakpoint-only(sm) {
        font-size: 36px;
      }

      @include media-breakpoint-only(md) {
        font-size: 36px;
      }
    }

    .radio_container {
      display: flex;
      width: 45%;
      justify-content: space-between;
      margin-top: 13px;
      @media screen and (max-width: 768px) {
        gap: 50px;

      }
    }

    .actions_container {
      text-align: center;
      position: relative;

      .create-button {
        margin: 50px 0 10px 0;
        font-size: 14px;
        border-color: transparent;
        padding-left: 50px;
        padding-right: 50px;

        .lnir {
          margin-right: 15px;
        }
      }
      .error {
        color: $error-color;
      }
    }
 }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .createNewCompanyChain {
    .content {
      .form_input {
        border: solid 1px $sundhedplus-light-grey;
        background-color: $white;
        color: $sundhedplus-darkest-grey;

        &:focus {
          border-color: $sundhedplus-light-grey;
        }
      }
    }
  }
}