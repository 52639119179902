@import "src/resources/scss/utilities/variables";

.SimpleCheckbox_component {
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;

  &-icon {
    width: 20px;
    height: 20px;
    border: 1px solid $light-grey;
    background-color: white;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    font-size: 14px;
    i {
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }

  &.checked {
    .SimpleCheckbox_component-icon {
      color: $white;
      background-color: $green-main;
      border: 1px solid $green-main;
      border-radius: 5px;
    }
  }

  &-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    color: $black;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .SimpleCheckbox_component {
    &-icon {
      border: 1px solid $sundhedplus-light-grey;
    }

    &.checked {
      .SimpleCheckbox_component-icon {
        color: $white;
        background-color: $sundhedplus-red-main;
        border: 1px solid $sundhedplus-red-main;
      }
    }
  }
}