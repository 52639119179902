@import 'src/resources/scss/utilities/variables';

.profile-component {
  .wrapper {
    padding: 16px 27px;

    .descriptionWraper {
      display: flex;
      margin-bottom: 25px;
      margin-top: 15px;
      align-items: center;

      .iconArea {
        margin-left: 2px;
        font-size: 22px;
        color: $green-main;
      }

      .titleArea {
        font-size: 22px;
      }
    }

    .usage-description {
      font-size: 14px;
      color: $darker-grey;

      a {
        color: $green-main;
        text-decoration: underline;
      }
    }
  }

  &.master-data {
    label {
      color: $green-main;
    }

    input,
    .quill {
      width: 100%;
      color: $darkest-grey;
      padding: 10px 15px;
      border: 1px solid $light-grey;
      border-radius: 5px;
      font-size: 14px;
      outline: none;
      &:disabled {
        background-color: #f8f8f8;
      }
    }

    .wrapper {
      .descriptionWraper {
        .titleArea {
          margin-left: 17px;
        }
      }
    }
    .error {
      color: #e26b6b;
      font-size: 12px;
      margin-left: 20px;
    }
  }

  .quill {
    .ql-container.ql-snow {
      border: none;
    }
    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid #f1e8e2;
    }
    .ql-snow.ql-toolbar {
      button.ql-active, button:hover {
        stroke: $green-main;
        .ql-stroke {
          stroke: $green-main;
        }
      }
    } 
  }
}

.brand-background-color {
  background-color: $lightest-grey;
  border: none;
}

.helpbox-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 5px auto 15px auto;
  text-align: center;
}

#CompanyProfileComponent {
  .label-wrapper label {
    color: $grey;
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }

  input,
  .quill {
    width: 100%;
    color: $darkest-grey;
    padding: 10px 15px;
    border: 1px solid $light-grey;
    border-radius: 5px;
    font-size: 14px;

    &:focus,
    &:focus,
    &:focus-visible {
      border: 1px solid $light-grey;
      outline: 0;
    }

    &:disabled {
      //background-color: rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3);
      background-color: $lightest-grey;
    }
  }

  .quill {
    padding: 0;
  }

  .textarea-wrapper {
    .ql-editor {
      min-height: 160px;
    }

    .hint {
      background-color: #f9f9f9;
      border: 1px solid #ececec;
      color: #747474;
      font-size: 12px;
      padding: 5px;
      margin-top: -8px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  a.profile-link {
    color: $green-main;
    font-size: 14px;
    line-height: 24px;

    i {
      font-size: 12px;
      padding-left: 5px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    label {
      max-width: 60%;
    }

    .error {
      color: $error-color;
      text-transform: none;
      font-size: 12px;
      margin-left: 20px;
    }
  }


  .CustomSelectComponent .custom_select .custom_select__placeholder {
    font-size: 16px;
  }

  .CustomSelectComponent .custom_select {
    .custom_select__multi-value .custom_select__multi-value__label {
      font-size: 14px;
    }

    .custom_select__option {
      font-size: 16px;
    }

    .custom_select__indicators .custom_select__dropdown-indicator {
      padding: 0;
    }

    &.custom_select--is-disabled {
      .custom_select__indicators {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .profile-component .wrapper {
    .descriptionWraper {
      flex-direction: column;
    }

    .action-buttons {
      width: 100%;
      margin-top: 5px;
      gap: 10px;
      display: grid;
    }

    .form-wrapper {
      .col {
        margin-bottom: 15px;
      }

      .mb-15 {
        margin-bottom: 0;
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .profile-component {
    .wrapper {
      .usage-description {
        a {
          color: $red-main;
        }
      }

      .descriptionWraper {
        .iconArea {
          color: $red-main;
        }
      }
    }

    .CustomSelectComponent {
      .custom_select {
        .custom_select__multi-value {
          background-color: $sundhedplus-brawn;
          color: $white;
        }
      }
    }

    &.master-data {
      label {
        color: $sundhedplus-brawn;
      }

      input,
      .quill {
        color: $very-dark-black;
        border: 1px solid $sundhedplus-light-grey;
      }

      .wrapper {
        .descriptionWraper {
          .iconArea {
            color: $sundhedplus-brawn;
          }
        }
      }
    }

    .quill {
      .ql-snow.ql-toolbar {
        button.ql-active, button:hover {
          stroke: $sundhedplus-link;
          .ql-stroke {
            stroke: $sundhedplus-link;
          }
        }
      } 
    }
  }

  .brand-background-color {
    background-color: $sundhedplus-lightest-grey;
  }

  #CompanyProfileComponent {
    a.profile-link {
      color: $sundhedplus-brawn;
    }

    input,
    .quill {
      color: $sundhedplus-darkest-grey;
      border: 1px solid $sundhedplus-light-grey;

      &:focus,
      &:focus,
      &:focus-visible {
        border: 1px solid $sundhedplus-light-grey;
        outline: 0;
      }
    }

    .textarea-wrapper {
      .hint {
        background-color: $sundhedplus-lighter-grey;
        border: 1px solid $sundhedplus-light-grey;
        color: $grey;
      }
    }
  }
}