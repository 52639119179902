// ## DOCUMENTATION PAGE
@import 'src/resources/scss/utilities/variables';

.DocumetationItemContent {
  .wrapper {
    display: block;
  }

  .custom-header {
    background-color: transparent;
    border: none;
    margin-left: 20px;
  }
  .Btn-approveDocuments {
    text-transform: uppercase;
  }
  .btn-cancel {
    color: $sundhedplus-red-main !important;
    margin-right: 20px;
    background-color: #fff !important;
    margin-right: 20px;
    border: 2px solid $sundhedplus-red-main !important;

    &:hover {
      background-color: #f8f8f8 !important;
    }

    & i {
      font-size: 14px;
    }
  }
}

#documentsTypeList {
  height: 100%;

  .doc-preview {
    position: relative;
    height: 100%;

    .document-area {
      border: 1px solid #d9d9d9;
    }
  }
  .img-viewer {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
  .pdf-viewer {
    max-height: 80px;
    height: 100%;

    width: 100%;
    overflow: hidden !important;
  }

  .small-card {
    width: 140px;
    height: 180px;
    padding: 10px 20px;
    margin: 10px;
    gap: 10px;
    text-align: center;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    position: relative;

    transition: background-color 0.1s ease, border 0.1s ease,
      transform 0.1s ease, box-shadow 0.1s ease;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: #fff;
      padding: 5px 10px;
      border-radius: 4px;
      transition: opacity 0.1s ease, transform 0.1s ease;
    }

    &:hover::after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .cardDescription {
    position: absolute;
    width: 100%;
    bottom: 5px;
    font-size: 12px;
    color: #444;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    cursor: pointer;

    .tooltip {
      z-index: 99999;
    }
  }

  .client-note {
    display: flex;
    color: #444;
    font-size: 12px;
    margin-top: 20px;

    align-items: center;
    justify-content: center;
  }

  .skeleton-row {
    height: 3px;
    background-color: #e0e0e0;
    margin-bottom: 5px;
    border-radius: 4px;
  }

  .support-note-display {
    margin-top: 40px;

    .support-note-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .note-card {
      display: flex;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      width: 100% !important;

      .support-note-content {
        flex: 1;
        padding: 10px;
        border: 1px solid #f6f0ee;
        border-radius: 4px;
        min-height: 50px;
      }

      .support-info {
        font-size: 12px;
        color: #8e8e8e;
      }
    }
  }
}
.client-note-wrapper {
  border: 0.5px solid #d9d9d9;
  padding: 13px 10px;
}
.Button-modal-wrapper {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#budget-section {
  height: 100%;

  .budgetAmountDisplay {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #f6f0ee;
    border-radius: 4px;

    &.budget-hover {
      &:hover {
        background-color: $sundhedplus-lightest-grey;
        transition: background-color 0.2s ease-in-out,
          transform 0.2s ease-in-out;
      }
    }

    .input-with-icon {
      border-radius: 4px;

      .budgetInput {
        border: none;
        height: 100%;
        font-size: 22px;
        max-width: 160px;
        width: 100%;
        background-color: transparent;
        text-align: center;

        &:focus {
          outline: none;
        }
      }

      .icon {
        cursor: pointer;
        color: $sundhedplus-brawn;
      }
    }

    .updatingLoading {
      min-width: 130px;
      max-width: 150px;
      align-items: center;
      justify-content: flex-start;

      .updateTitle {
        font-size: 22px;
        font-weight: bold;
        width: 100%;
        gap: -10px;

        p {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    .spinnerUpdade {
      min-width: 60px;
      max-width: 60px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .budget-information {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 25px;

    & > .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 1.7;
    }

    & > .referenceBudget {
      color: #8e8e8e;
    }
  }
    @media only screen and (max-width: 600px) {
      height: 100%;
      overflow: scroll;
  
      .table-wrapper {
        min-width: 700px;
      }
  
      .conclusions-section {
        flex-flow: column;
        padding: 10px;
  
        .right-side {
          padding: 10px;
        }
      }
    }
    }

.document-note-section {
  .detailsArea {
    margin-bottom: 20px;
    i.grey {
      color: grey;
    }
    i.quotes:before,
    i.quotes:after {
      content: '"';
    }

    .ql-editor {
      min-height: 50px;
    }

    .quill-editor {
      margin: 20px 0;

      .ql-editor {
        min-height: 64px;
        overflow-y: auto;
      }
    }

    .support-note-editor {
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      .support-note-input {
        width: 100%;
        border: 1px solid $sundhedplus-lightest-grey;

        outline: none;

        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        resize: none;
        min-height: 50px;

        &:focus {
          border-bottom: 2px solid $sundhedplus-status-green;
        }
      }
    }
  }
}

.documentation-svg-icon {
  width: 18px;
  height: 18px;
  fill: white;
}
