@import 'src/resources/scss/utilities/variables';

.userInfo-wrapper {
  position: fixed;
  z-index: 1000;
  max-width: 285px;
  // max-height: 250px;
  background-color: #fefefe;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 1;
  top: 50px;
  right: 10px;
  padding: 0 !important;
  margin: 0 !important;
  &.isDashboard {
    max-width: 142px;
    max-height: 68px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 5px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fefefe;
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding: 15px;

    width: 100%;
    height: 100%;

    align-items: center;

    &.menuDashboard {
      padding: 0;
    }

    .list-group {
      display: flex;
      width: 100%;
      padding: 0 16px;
      &.list-group-dashboard {
        padding: 0;
      }

      .user-info {
        display: flex;
        padding-top: 16px;
        align-items: center;

        .name_icon {
          max-width: 38px;
          min-width: 38px;
          height: 38px;
          border-radius: 45px;
          background-color: #faf8f6;
          border: 1px solid #d1d1d1;
          color: #444444;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
        }

        .clinicInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .companyName {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #444;
          }

          .username {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #8e8e8e;
          }
        }
      }
      hr {
        border-bottom: 0 solid #d1d1d1;
        width: 100%;
      }

      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none !important;
        padding: 0 !important;

        .btn {
          padding: 0;
        }
      }

      .common-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: transparent;
        border: none !important;
        color: #444444;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        margin: 5px 0;
        margin-left: 8px;

        i {
          font-size: 20px;
          margin-right: 20px !important;
        }

        &:hover {
          color: #444;
          font-weight: bold;
          font-size: 16px;

          .di,
          .lnil,
          .lnir {
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 10px;
              margin-left: 8px;
              right: 0;
              transform: translate(-50%, -50%);
              width: 35px;
              height: 35px;
              background-color: #d1d1d1;
              border-radius: 50%;
              opacity: 0.7;
              transition: all 0.3s ease-in-out;
              z-index: -1;
            }
          }
        }
      }

      .lnil {
        border-radius: 50%;
        font-size: 20px;
        background: transparent;
        color: #444;
        z-index: 1;
      }
    }
  }
}
