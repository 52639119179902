.error-page {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 20px;
  line-height: 1.4;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #d7e5e9;
  background-size: cover;
  overflow-x: hidden;
  // h1 {
  //   overflow-wrap: normal;
  //   font-size: 30px;
  //   line-height: 36px;
  //   margin: 0 0 30px 0;
  // }

  p {
    font-size: 16px;
    margin: 1.5em 0;
  }

  // a {
  //   color: #339CA6;
  // }

  .error-canvas {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    background: #d7e5e9;

    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      background: #d7e5e9 url('#{$images-path}/error-back.png') center no-repeat;
    }
    @include media-breakpoint-down(sm) {
      background-color: #ffffff !important;
    }

    .error-container {
      position: relative;

      width: 90%;
      max-width: 550px;
      margin: auto;
      text-align: center;
      .error-content {
        max-width: 100%;
        padding: 40px;
        margin: 80px auto 0 auto;
        box-shadow: 0 1px 5px #a7a7a7, inset 0 1px 0 $white;
        background: $white;
        position: relative;
        line-height: 1.5;
        text-align: left;
        // mobile version of error page

        @include media-breakpoint-down(sm) {
          box-shadow: none;
          padding: 0 10px 20px 10px;
        }

        // @include media-breakpoint-down(xs) {
        //   h1 {
        //     word-break: break-all;
        //   }
        // }

        a {
          color: $green-main;
          text-decoration: underline;
          // non break space
        }
        .btn {
          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
      footer {
        margin-top: 30px;
        color: #444444 !important;
        h4 {
          font-family: 'Maven Pro Medium', sans-serif;
          font-size: 18px;
        }
        a {
          color: #444444 !important;
          text-decoration: none !important;
        }
      }
    }

    h1,
    .headline {
      font-size: 32px;
      font-family: $font-family-bold;
      margin: 0 0 20px 0;
    }
    p,
    p.small-margin-bottom {
      margin-bottom: 5px;
    }
    ul {
      font-size: 16px;
    }
    .button_area {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  a:not(.btn) {
    color: $green-main;
    text-decoration: underline;
  }
  .btn > a {
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
  .public-page-logo {
    display: flex;
    justify-content: center;
  }
  img {
    width: 80%;
    max-width: 200px;
    height: auto;
    margin-top: 30px;
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  &.error-page {
    .error-canvas {
      background-color: $sundhedplus-lightest-grey;
      @include media-breakpoint-up(sm) {
        background: $sundhedplus-lightest-grey;
        height: 100vh;
      }
      h1,
      .headline {
        font-family: 'PlayfairDisplay', serif;
        font-size: 32px;
        font-weight: 600;
      }
      .error-content {
        a {
          color: $sundhedplus-red-main;
          text-decoration: underline;
        }
        .btn {
          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
    a:not(.btn) {
      color: $sundhedplus-red-main;
      text-decoration: underline;
    }
    .btn > a {
      color: $white;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
