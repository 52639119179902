@import 'src/resources/scss/utilities/variables';

.DropDown_Component {
  position: relative;
  background-color: $white;
  //font-family: Maven Pro, Open Sans, sans-serif;
  .Top_area {
    border: 1px solid $light-grey;
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    padding-left: 21px;
    font-size: 24px;
    color: $darkest-grey;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    &.hasError {
      border-color: $text-status-red;
    }
    .placeholder {
      color: hsl(0, 0%, 50%);
      font-size: 24px;
    }
    .triangle {
      // width: 0;
      // height: 0;
      // border-left: 11px solid transparent;
      // border-right: 11px solid transparent;
      // border-top: 18px solid $light-grey;
      position: absolute;
      top: 12px;
      right: 5px;
      width: 40px;
      svg {
        fill: currentColor;
        line-height: 1;
        color: hsl(0, 0%, 80%);
        display: flex;
        path {
          stroke: #fff;
          stroke-width: 1px;
        }
      }
    }
  }
  .drop_area {
    width: 100%;
    border: 1px solid $light-grey;
    position: absolute;
    background-color: $white;
    z-index: 1;
    max-height: 195px;
    overflow: auto;
    .row {
      height: 64px;
      display: flex;
      width: 100%;
      margin: 0px;
      padding: 10px;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: $lightest-grey;
      }
    }
    .first_column, .third_column {
      width: 100px;
      font-size: 16px;
      height: 100%;
    }
    .first_column {
      justify-content: flex-start;
      text-align: center;
    }
    .second_column {
      width: calc(100% - 200px);
      justify-content: flex-start;
      align-items: center;
      &.hasmargin {
        padding-left: 21px;
      }
      &.fullWidth {
        width: 100%;
      }
      .first {
        color: $darkest-grey;
        font-size: 16px;
        &.boldText {
          font-size: 24px;
        }
      }
      .second {
        font-size: 12px;
        color: $grey;
      }
    }
    .third_column {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .badge {
      color: $white;
      padding: 10px;
      width: 80px;
      height: 30px;
      font-weight: normal;
      &.status-ACTIVE {
        background-color: $dunning-green;
      }
      &.status-PASSIVE {
        background-color: $card-yellow;
      }
    }

    .displayNone {
      display: none;
    }
  }
}

#filter-dropdown {
  .DropDown_Component {
    .Top_area {
      height: 40px;
      padding-left: 10px;
      font-size: 14px;

      .placeholder {
        font-size: 14px;
      }

      .triangle {
        position: absolute;
        top: -2px;
        right: 0;
        width: 35px;
      }
    }

    .drop_area {
      .row {
        height: auto;
      }
      .first_column {
        width: 70px;
        font-size: 12px;
      }

      .second_column {
        width: calc(100% - 70px);

        .first {
          font-size: 12px;
        }
      }
    }
  }
}
// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .DropDown_Component {
    .Top_area {
      border: 1px solid $sundhedplus-light-grey;
    }

    .drop_area {
      border: 1px solid $sundhedplus-light-grey;

      .row {
        &:hover {
          background-color: $sundhedplus-lightest-grey;
        }
      }

      .badge {
        &.status-ACTIVE {
          background-color: $sundhedplus-dunning-green;
        }

        &.status-PASSIVE {
          background-color: $card-yellow;
        }
      }
    }
  }
}