@import "src/resources/scss/utilities/variables";

.grant_access_Component {
  min-height: 400px;
  padding: 0 24px;
  .grant_access_Component-header {
    text-align: center;
  }
  &-header {
    margin-bottom: 50px;

    i {
      font-size: 60px;
      margin-bottom: 25px;
      color: $black;
    }

    .title {
      font-size: 36px;
      font-weight: bold;
      color: $darkest-grey;
    }
  }

  &-section {
    margin-bottom: 25px;

    .select-patient-note {
      text-align: left;
      font-size: 18px;
      margin-bottom: 25px;
      padding-left: 5px;
      text-align: center;
    }

    .clinic-search {
      position: relative;
      margin-bottom: 30px;

      .list-group-item {
        display: flex;
        padding: 10px !important;
        border-top-color: $lightest-grey;
        border-bottom-color: $lightest-grey;

        .sugestion_refrenceid {
          width: 100px;
          text-align: center;
          margin-right: 10px;
          justify-content: flex-start;
        }
        .sugestion_data {
          width: calc(100% - 200px);
          justify-content: flex-start;
          align-items: center;
        }
        .sugestion_status {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .badge {
          color: $white;
          width: 80px;
          height: 30px;
          padding: 0;
          line-height: 30px;
          font-weight: normal;
          &.status-ACTIVE {
            background-color: $dunning-green;
          }
          &.status-PASSIVE {
            background-color: $card-yellow;
          }
          &.status-DEACTIVE {
            background-color: $grey;
          }
        }
        .displayNone {
          display: none;
        }
      }
      .clinic_search_address {
        color: $dark-grey;
        font-size: 12px;
      }
      & .clinic-search-input {
        border-radius: 4px;
        border-color: $light-grey;
        font-size: 24px;
        color: $darkest-grey;
        height: 62px;

        &:focus {
          //border-color: $green-main;
          box-shadow: none;
        }

        &::placeholder {
          color: $grey;
        }
      }

      .lnir-search-alt {
        position: absolute;
        right: 3%;
        top: 25%;
        font-size: 30px;
        color: $grey;
      }

      .no-suggestions,
      .suggestions {
        &.list-group {
          position: absolute;
          width: 100%;
          z-index: 200;
          max-height: 250px;
          overflow-y: scroll;
        }

        .list-group-item {
          padding: 20px;
          font-size: 16px;
          color: $black;
          cursor: pointer;

          &:hover {
            background-color: $lightest-grey;
          }
        }
      }
    }
  }

  &-footer {
    font-size: 14px;
    margin-bottom: 45px;
    text-align: center;
  }

  &-button_area {
    text-align: center;
    i {
      padding-right: 10px;
    }

    button {
      padding-left: 55px;
      padding-right: 55px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .disabled {
      cursor: not-allowed;
    }
  }
}
