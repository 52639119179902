@import 'src/resources/scss/utilities/variables';

.invoice_Error_Component{
  text-align: center;
  margin: 24px;

  .error_icon{
    width: 322px;
    height: 298px;
    margin-bottom: 44px;
  }

  .invoice_title {
    margin-bottom: 36px;
    font-weight: bold;
  }

  & div {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 1.5;

    .contact_email {
      color:$green-main;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .invoice_Error_Component{
    & div {
      .contact_email {
        color: $sundhedplus-red-main;
      }
    }
  }
}