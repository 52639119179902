@import 'src/resources/scss/utilities/variables';

#SettingsComponent {
    .animation-wrapper {
        position: relative;
        min-height: 100%;
        overflow: hidden;
        .no-leads-details {
            height: 100vh;
        }
    }

    .manager-companies-list {
        position: relative;
        background-color: #f8f8f8;
        z-index: 19;
        padding-top: 30px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .manager-companies-list-item {
        position: absolute;
        top: 0;
        right: -100%;
        /* right: 0; */
        bottom: 0;
        overflow: auto;
        background-color: #f8f8f8;
        transition: right .2s ease-in;
        height: 100%;
        width: 100%;
        padding: 0;
        min-height: 0;
        z-index: 19;
        &.active {
            right: 0;
        }
        .main-content {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .main-content {
        position: relative;
        padding-bottom: 20px;
    }
    .centered {
        margin: 0 auto;
    }
    .go-back-to-list {
        display: block;
        margin-left: 25px;
    }

    .main-content>.row:first-of-type {
        margin-top: 3rem;
    }

    .master-data-info {
        justify-content: center;
        align-items: center;

        .iconArea {
            color: $green-main;
            height: 30px;
            width: 70px;
            background-color: $light-grey;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            color: $green-main;
            font-size: 14px;
            margin-left: 10px;
        }
    }
    .company-extra-data {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        color: $grey;
        > div {
            span {
                margin-right: 5px;
            }
        }
    }
}

#ManagersListComponent {
    &.card {
        height: auto;
    }

    margin-bottom: 50px;
}

.companies-table {
    font-size: 14px;

    .header-wrapper {
        border-bottom: 1px solid $sundhedplus-lighter-grey;
        padding: 0 20px;

        .col,
        .name-field,
        .create-date-field,
        .revenue-field,
        .status-field  {
            line-height: 55px;
            color: $green-main;
        }
    }

    .content-wrapper {
        padding: 0 15px;

        .row {
            line-height: 55px;
            border-bottom: 1px solid $sundhedplus-lighter-grey;
            color: $black;
            cursor: pointer;
            &:hover {
                background-color: $grey-hover;
                border-bottom-color: #ededed;
            }
        }

        .name-field {
            line-height: 55px;
            font-family: $font-family-medium;
            font-size: 16px;
        }

        .status-field {
            .dot {
                height: 12px;
                width: 12px;
                background-color: $dunning-grey;
                border-radius: 50%;
                display: inline-block;
                margin-right: 15px;

                &.status-ACTIVE {
                    background-color: $dunning-green;
                }

                &.status-PASSIVE {
                    background-color: $card-yellow;
                }

                &.status-DEACTIVE {
                    background-color: $grey;
                }
            }
        }

        .seeDetailsButton {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;

            i {
                color: $grey;
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 768px) {
    #SettingsComponent {
        .manager-companies-list {
            padding-left: 20px;
        }
        .manager-companies-list-item {
            left: -100%;
            &.active {
                left: 0;
            }
            .main-content {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .main-content {
            padding-top: 0;
            > .row:first-of-type {
                margin-top: 1rem;
            }
        }
        .go-back-to-list {
            margin-left: 0;
            position: relative;
        }
        .company-extra-data {
            margin-bottom: 10px;
            justify-content: flex-start;
        }
    }
}


// ####### SUNDHEDPLUS #########
#Sundhedplus {
    .master-data-info {
        .iconArea {
            color: $sundhedplus-brawn;
            background-color: $sundhedplus-lightest-grey;
        }

        span {
            color: $sundhedplus-brawn;
        }
    }

    .companies-table {
        .header-wrapper {
            .row {
                margin: auto;
            }
            .col,
            .name-field,
            .create-date-field,
            .revenue-field,
            .status-field {
                color: $sundhedplus-brawn;
            }
        }
        .content-wrapper {
            .row {
               
                cursor: pointer;
                &:hover {
                    background-color: $sundhedplus-lightest-grey;
                    border-bottom-color: $sundhedplus-lighter-grayish-orange;
                }
            }
        }

        .status-field {
            .dot {
                &.status-ACTIVE {
                    background-color: $sundhedplus-dunning-green;
                }

                &.status-DEACTIVE {
                    background-color: $sundhedplus-light-grayish-red;
                }
            }
        }
    }
}