@import 'src/resources/scss/utilities/variables';

.page-content {
  height: 100vh;
  color: $darker-grey;
  overflow: auto;
  margin: 0;

  .top-header {
    text-align: center;
    margin: auto;
    justify-content: center;
    background-color: $green-main;
    padding: 25px 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    img {
      height: 40px;
    }
  }
  .bookingRequestSolvedBar {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: $sundhedplus-card-green;

    p {
      color: $white;
      font-size: 16px;
      margin: auto;
      padding: auto;
    }
  }

  .container-booking {
    width: 100%;
    min-height: 35.125rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 27.5px 4.5px rgba(102, 102, 102, 0.17);
    background-color: $white;

    .container-content {
      min-height: 32.125rem;
      max-width: 500px;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 0 1.6rem;

      .header-name {
        padding-top: 2.75rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $darker-grey;
      }

      .referralTrue {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-bottom: 1.688rem;
        padding: 0;
        height: 30px;
        border-radius: 13px;
        width: 200px;
        height: 30px;
        border: 1.5px solid $sundhedplus-light-red-main;

        .referral-position {
          display: flex;
          margin: auto;
          padding: 0;
          align-items: center;

          i {
            color: $sundhedplus-card-green;
            height: 17px;
            font-weight: bold;
          }
          .referral-text {
            font-family: 'Overpass-Light', sans-serif;
            font-size: 14px;
            font-weight: 300;

            text-align: left;
            margin-left: 12px;
          }
        }
      }
      .referralFalse {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-bottom: 1.688rem;
        padding: 0;
        height: 30px;
        border-radius: 13px;
        width: 222px;
        height: 30px;
        border: 1.5px solid $sundhedplus-light-red-main;

        .referral-position {
          display: flex;
          margin: auto;
          padding: 0;
          align-items: center;

          i {
            margin: auto;
            color: $sundhedplus-light-grey;
            height: 17px;
            font-weight: bold;
          }
          .referral-text {
            font-family: 'Overpass-Light', sans-serif;
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            margin-left: 12px;
          }
        }
      }
      .text-hr {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 15px auto;
        color: $sundhedplus-light-red-main;
        font-size: 14px;
        font-weight: 300;

        &::before,
        &::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid $sundhedplus-light-red-main;
        }
      }
      .contact {
        display: inline-block;
        margin-bottom: 0.8rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: $darker-grey;

        .emailAndTlf {
          text-transform: uppercase;
          width: 5.7rem;
        }
        .contact-info {
          height: 1.344rem;
          position: relative;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    .info-header {
      height: 0.563rem;
      margin-bottom: 1.469rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: $darker-grey;
    }
    .upper-case {
      text-transform: uppercase;
      margin-top: 1.75rem;
    }
    .booking-comment {
      margin-bottom: 2rem;
      font-size: 16px;
      font-weight: 300;
      color: $darker-grey;
    }
    .timeRange-title {
      display: inline-block;
    }

    .timeRange-list {
      margin-top: 0.563rem;

      border-radius: 0.313rem;
      border: solid 1px $light-grey;
      background-color: #faf8f6;

      .time-list {
        padding: 1rem;
        font-size: 1.063rem;
        font-weight: 300;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: $darkest-grey;
      }
      .capitalize-me::first-letter {
        text-transform: capitalize;
      }
    }
    // time-stamp footer from patient booking information
    .footer-info {
      width: fit-content;
      margin: 0 auto;
      padding: 21px;
      font-size: 0.75rem;
      font-weight: 300;
      color: $darker-grey;
    }
  }
  // bookingRquestBody

  .content-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    grid-template-areas:
      'first-section'
      'second-section'
      'third-section'
      'footer';

    .first-section {
      grid-area: first-section;
      height: max-content;

      .therapist-container {
        margin-right: 20px;
      }
    }

    .second-section {
      grid-area: second-section;
      max-width: 500px;
      justify-content: center;
      align-items: center;
      height: max-content;
    }
    .third-section {
      grid-area: third-section;
      font-size: 1rem;
      font-weight: 300;
      color: $black;
      height: max-content;

      .btnBox {
        margin-top: 30px;
        align-items: center;
        text-align: center;
        .btn-BookingDisabled {
          background: $neutral-main;
          border: none;
        }
        .btnSolved {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          max-width: 261px;
          width: 100%;
          height: 40px;
          text-transform: uppercase;
          font-size: 13.5px;
          font-weight: 600;
          i {
            font-size: 20px;
          }
        }
        .btnDeleted {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          max-width: 153px;
          width: 100%;
          height: 40px;
          margin-left: 1rem;
          text-transform: uppercase;
          font-size: 13.5px;
          font-weight: 600;
          i {
            font-size: 20px;
          }
        }
        @media only screen and (max-width: 480px) {
          display: inline-block;

          width: 100%;
          .btnDeleted {
            margin-top: 15px;
          }
        }
      }
    }

    .header-title {
      font-size: 2rem;
      margin-bottom: 1em;
      font-weight: 600;
      color: $darker-grey;
    }

    .contact-patient-title {
      margin-top: 1rem;
      font-weight: bold;
      font-size: 1rem;
      color: $black;
    }
    h3 {
      margin-bottom: 2px;
      line-height: 1.63 !important;
      color: $darker-grey;
    }

    .count-down-timer {
      width: 100%;
      margin-top: 42px;

      .count-down-text {
        font-size: 0.875rem;
        font-weight: 300;
        margin: 0 auto 1.25rem auto;
        text-align: center;
        color: $black;
        max-width: 367px;
        display: block;
      }
      .count-down-timer-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 0;
        grid-gap: 10px;
        margin: 0 auto;
        justify-content: center;

        .count-box {
          width: 4rem;
          height: 4.437rem;
          text-align: center;
          border-radius: 5px;
          background-color: #f8f4ec;
          font-size: 30px;
          font-weight: 300;
          color: $black;
          padding: 0.5rem;
        }

        .box-text {
          font-size: 0.75rem;
          display: flex;
          justify-content: center;
          bottom: 6px;
          position: relative;
          font-weight: 300;
          color: $black;
        }
        .red-color {
          background-color: lightcoral !important;
        }
      }
    }

    .color {
      color: $green-main;
    }
    .footer {
      grid-area: footer;
      width: fit-content;
    }
  }
  .question {
    font-weight: bold;
  }

  @media only screen and (min-width: 769px) {
    .content-wrapper {
      max-width: 1200px;
      display: grid;
      height: 60%;
      margin: 6.125rem auto 4.563rem auto;
      justify-items: center;
      grid-template-columns: 0.8fr 0.8fr;
      grid-template-rows: 0.1fr 1fr;

      grid-template-areas:
        'first-section second-section'
        'third-section second-section'
        'footer footer';
    }
    .first-section {
      max-width: 500px;
      margin: 24px 1rem 0 1.2rem;
      height: 100vh;
    }
    .third-section {
      max-width: 500px;
      margin: 0 1rem 0 1.2rem;
      height: 100vh;

      p {
        margin-right: 30px;
      }
      .btnBox {
        display: flex;
        max-width: 100%;
        justify-content: center;
        .btnSolved {
          max-width: 261px;
        }
        .btnDeleted {
          max-width: 153px;
        }
      }
    }
    .second-section {
      width: 100%;
      margin-right: 1.2rem;
      height: 100vh;
      justify-self: center;
    }

    .footer {
      width: 100%;
      margin: 0 1rem;
      // make sure that the footer show on Firefox the same as Chrome
      @-moz-document url-prefix() {
        margin-top: 100px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content-wrapper {
      margin: 44px auto 0 auto;
    }

    .therapist-container {
      margin: 55px 1rem 45px 1.2rem;
    }

    .question {
      display: block;
    }
    .second-section {
      min-width: 100%;
      display: flex;
      flex-direction: column;
    }
    .third-section {
      margin: 55px 1rem 0 1.2rem;
      .btnBox {
        display: flex;
        max-width: 100%;
        justify-content: center;
        .btnSolved {
          max-width: 261px;
        }
        .btnDeleted {
          max-width: 153px;
        }
      }
    }

    .container-booking {
      padding: 0 1.15rem;
      border-radius: 0.25rem;
      box-shadow: 0 0 27.5px 4.5px rgba(102, 102, 102, 0.17);
      background-color: $white;
      max-width: 480px;

      .container-content {
        padding: 0;
      }
    }
    .count-down-timer {
      padding-bottom: 0;
    }
    .footer {
      margin: 4rem 1rem 0 1.2rem;
    }
    .timeRange-title {
      font-size: 0.8rem;
    }
  }
}
.modal-content-bookingRequest {
  max-width: 460px !important;
  max-height: 259px !important;
  width: 100%;
  height: 100%;
  .modal-body {
    width: 100%;
    height: 100%;
    .status-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .modalOptionsContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .opions-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          margin-bottom: 20px;
          width: 100%;
          max-width: 388px;
          height: 40px;
          text-transform: uppercase;
        }
      }
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 362px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 600;
        color: $black;
        font-size: 18px;
        text-align: center;
        width: 100%;
      }
    }
  }
}
// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .page-content {
    font-family: 'Overpass-Light', sans-serif;

    .btnDeleted {
      background: $sundhedplus-brawn;
    }

    .top-header {
      color: $sundhedplus-brawn;
      background-color: $sundhedplus-red-secudary;
    }

    a {
      color: $sundhedplus-red-main;
    }
  }
}

.error-page .error-canvas {
  min-height: 100vh;
}
