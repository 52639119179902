.welcome-page-modal {
  overflow: hidden !important;
  max-width: 600px;
  max-height: 800px;
  padding: 20px;
  &.modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
      max-height: 759px;
    }
  }
  .modal-content,
  .modal-body {
    position: initial;
  }

  .logo {
    display: flex;
    background-color: $white;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
  }
}

.welcome-page-wrapper {
  height: 100%;
  display: none;
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  text-align: center;
  color: $darker-grey;

  &.modal-send-application {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100% !important;

    overflow: hidden !important;

    .slide-group {
      // make transition smooth
      transition: transform 0.5s ease;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .loading-spinner {
      .card-body {
        justify-content: center;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .welcome-page-header {
    margin-bottom: 20px;
    h1 {
      font-size: 18px;
      font-family: 'Maven Pro Medium', sans-serif;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 27px;
      color: #444444;
    }
    h2 {
      font-size: 16px;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 24px;
      color: #444444;
    }
  }

  .card-wrapper {
    height: 253px;
    width: 300px;
    display: flex;

    background-color: #ffffff;
    box-shadow: 0px 4px 0px 0px rgba(242, 242, 242, 1);
    border: none;
    .content {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      height: 100%;
      padding: 20px 25px 30px 25px;

      h3 {
        font-size: 18px;
        font-family: 'Maven Pro Medium', sans-serif;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 27px;
        color: #444444;
      }

      p {
        font-size: 14px;

        font-weight: 400;
        margin-bottom: 10px;
        line-height: 21px;
        color: #444444;
      }
      .actions-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .btn {
          min-width: 142px;
          max-height: 41px;
          font-size: 14px;
          line-height: 21px;

          font-weight: 500;

        
          box-shadow: none !important;

          @include media-breakpoint-down(sm) {
            max-width: 50%;
            min-width: 130px;

            &.full-width {
              max-width: 100%;
            }
          }
          .button-label {
            justify-content: center;
            display: flex;

            text-align: center;
            width: 100%;

            i {
              font-size: 18px;
              text-align: right;
              font-weight: 500;
            }
          }
        }

        .full-width {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }
  .info-container {
    margin-top: 40px;
    font-size: 14px;

    .link-color {
      //remove color from link
      color: inherit;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .welcome-page-modal {
    .step-content {
      color: $select-title-color;

      .step-body {
        .select-options {
          .select-item {
            &:hover {
              background-color: $sundhedplus-lightest-grey;
            }

            .di {
              color: $sundhedplus-red-main;
            }
          }
        }

        .input-container {
          background-color: $sundhedplus-lightest-grey;

          .clinic-search {
            position: relative;
            width: 100%;

            i {
              position: absolute;
              color: $grey;
            }

            .no-suggestions,
            .suggestions {
              .list-group-item {
                &:hover {
                  background-color: $sundhedplus-lightest-grey;
                }
              }
            }
          }

          #clinicSearch {
            &.form-control {
              border-color: $sundhedplus-light-grey;
              color: $sundhedplus-darkest-grey;
            }
          }

          .form-group {
            .form-control {
              border-color: $sundhedplus-light-grey;
            }
          }
        }
      }

      .text-information {
        .btn-app-link {
          display: flex;
          margin: auto;
          color: $sundhedplus-red-main;
        }
      }

      .clinic-search {
        .list-group-item {
          border-top-color: $sundhedplus-lightest-grey;
          border-bottom-color: $sundhedplus-lightest-grey;
        }

        & .clinic-search-input {
          border-color: $sundhedplus-light-grey;

          &:focus {
            border-color: $grey;
          }
        }

        .no-suggestions,
        .suggestions {
          .list-group-item {
            &:hover {
              background-color: $sundhedplus-lightest-grey;
            }
          }
        }
      }

      &.step-sendapp-link {
        .DropDown_Component .Top_area .placeholder {
          color: $sundhedplus-light-grey;
        }

        .input-send-container {
          .form-group {
            .form-control {
              border-color: $sundhedplus-light-grey;
              color: $darkest-grey;

              &::placeholder {
                color: $sundhedplus-light-grey;
              }

              &:focus {
                box-shadow: none;
                border-color: $sundhedplus-light-grey;
              }
            }
          }
        }

        .button-actions {
          .btn {
            border-radius: 20px 20px 20px 20px;
          }
        }
      }
    }
  }
}
