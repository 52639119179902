@import 'src/resources/scss/utilities/variables';

.CustomSelectComponent {
  width: 100%;
  label {
    text-align: left;
    line-height: 2.5;
    font-weight: normal;
    font-size: 16px;
    font-family: 'Maven Pro Medium';
    color: $darker-grey;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
  }
  .custom_select {
    width: 100%;
    .custom_select__control {
      font-size: 24px;
      border-color: $light-grey;
      box-shadow: none;
      .custom_select__value-container {
        padding-left: 14px;
        font-size: 14px;
      }
    }
    .custom_select__indicator-separator {
      display: none;
    }
    .custom_select__indicators .custom_select__dropdown-indicator {
      padding: 10px;
      svg {
        width: 40px !important;
        height: 40px !important;
        path {
          stroke: #fff;
          stroke-width: 1px;
        }
      }
    }
    .custom_select__menu {
      text-align: left;
    }
    .custom_select__placeholder {
      font-size: 24px;
    }
    .custom_select__clear-indicator {
      display: none;
    }
    .custom_select__multi-value {
      background-color: $green-main;
      color: $white;
      border-radius: 4px;
      margin-right: 5px;
      .custom_select__multi-value__remove {
        width: 30px;
        display: flex;
        justify-content: center;

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
      .custom_select__multi-value__label {
        font-size: 16px;
        color: $white;
        padding-left: 10px;
      }
    }

    .custom_select__option {
      font-size: 24px;
    }
    .custom_select__option--is-focused,
    .custom_select__option:hover {
      background-color: $grey-hover;
      border: none;
      cursor: pointer;
    }
    &.invalidInput .custom_select__control {
      border: 1px solid $status-red;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 18px solid $light-grey;
    position: absolute;
    top: 20px;
    right: 16px;
  }

  &.filter {
    .custom_select {
      .custom_select__placeholder {
        font-size: 14px;
      }
      .custom_select__indicators .custom_select__dropdown-indicator {
        padding: 0px;
      }
      .custom_select__option {
        font-size: 14px;
        &.custom_select__option--is-selected {
          background-color: $light-grey !important;
        }
        &:active,
        &:hover,
        &:focus {
          background-color: $light-grey;
        }
      }
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .CustomSelectComponent {
    .custom_select {
      .custom_select__control {
        border-color: $sundhedplus-light-grey;
      }

      .custom_select__multi-value {
        background-color: $sundhedplus-red-main;
        color: $white;
      }
    }

    .triangle {
      border-top: 18px solid $sundhedplus-light-grey;
    }
  }
}
