@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/breakpoints';

.new_Invoice_Component {
  min-height: 400px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form_icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;

    .form_icon_ico {
      width: 60px;
      height: 60px;
    }
  }

  .form_title {
    color: $darkest-grey;
    margin-top: 25px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.83;
  }

  .form_control {
    margin-top: 50px;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }

    .form_control_group {
      margin-bottom: 20px;
    }

    .form_text {
      display: flex;
      justify-content: space-between;

      div:last-of-type:not(:first-of-type) {
        font-size: 12px;
        line-height: 24px;
      }

      &.toggle {
        align-items: center;
        margin-bottom: 18px;

        .react-switch {
          margin-bottom: 0;
          width: 206px;
          .react-switch-button {
            width: 103px;
          }
        }
        
        .react-switch-checkbox:active + .react-switch-button {
          width: 103px;
        }
      }
    }


    .form_input {
      width: 100%;
      height: 62px;
      margin-top: 5px;
      padding: 0 15px;
      border-radius: 4px;
      border: solid 1px $light-grey;
      background-color: $white;
      font-size: 30px;
      text-align: left;
      color: $darkest-grey;

      &:invalid {
        border: solid 1px $error-color;
      }

      &:invalid:focus {
        border-color: $error-color;
      }

      &:focus {
        border-color: $light-grey;
      }
    }

    textarea.form_input {
      height: 83px;
      font-size: 14px;
      line-height: 24px;
      padding: 13px 27px 14px 16px;
      &::placeholder {
        color: #afafaf;
      }
    }

    .input_danger {
      border-color: $error-color !important;

      &:focus {
        border-color: $error-color;
      }
    }

    input,
    textarea {
      &:focus {
        outline: none;
      }

      @include media-breakpoint-only(sm) {
        font-size: 36px;
      }

      @include media-breakpoint-only(md) {
        font-size: 36px;
      }
    }
  }

  label:not(.react-switch) {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: $darkest-grey;
  }

  .clinic-search {
    position: relative;
    margin-bottom: 30px;

    .list-group-item {
      display: flex;
      padding: 10px !important;
      border-top-color: $lightest-grey;
      border-bottom-color: $lightest-grey;

      .sugestion_refrenceid {
        width: 100px;
        text-align: center;
        margin-right: 10px;
        justify-content: flex-start;
      }

      .sugestion_data {
        width: calc(100% - 200px);
        justify-content: flex-start;
        align-items: center;

      }

      .sugestion_status {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .badge {
        color: $white;
        width: 80px;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-weight: normal;

        &.status-ACTIVE {
          background-color: $dunning-green;
        }

        &.status-PASSIVE {
          background-color: $card-yellow;
        }

        &.status-DEACTIVE {
          background-color: $grey;
        }
      }

      .displayNone {
        display: none;
      }
    }

    .clinic_search_address {
      color: $dark-grey;
      font-size: 12px;
    }

    & .clinic-search-input {
      border-radius: 4px;
      border-color: $light-grey;
      font-size: 24px;
      color: $darkest-grey;
      padding-top: 15px;
      padding-bottom: 15px;

      &:focus {
        border-color: $green-main;
      }
    }

    .lnir-search-alt {
      position: absolute;
      right: 3%;
      top: 25%;
      font-size: 30px;
      color: $grey;
    }

    .no-suggestions,
    .suggestions {
      &.list-group {
        position: absolute;
        width: 100%;
        z-index: 200;
        max-height: 250px;
        overflow-y: scroll;
      }

      .list-group-item {
        padding: 20px;
        font-size: 16px;
        color: $black;
        cursor: pointer;

        &:hover {
          background-color: $lightest-grey;
        }
      }
    }
  }

  .radio {
    padding-bottom: 12px;

    label {
      width: auto;
      margin-bottom: 0;
      font-size: 16px;
      color: $darkest-grey;

      input {
        appearance: none;
        background-color: $lightest-grey;
        border: 1px solid $light-grey;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        width: 25px;
        height: 25px;
        margin-right: 13px;
        margin-top: -5px;
        vertical-align: middle;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $light-grey;
        }

        &:checked {
          border: 8px solid $green-main;
        }
      }

      .radio_danger {
        border-color: $error-color;

        &:hover {
          background-color: $light-grey;
        }

        &:checked {
          border: 8px solid $green-main;
        }
      }
    }
  }

  .clinic-search-input {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    padding-right: 60px !important;
    padding-left: 15px !important;

  }

  .buttonArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 38px 0 10px 0;

    .di-circle-plus {
      margin-right: 14px;
      font-size: 17px;
    }

    button {
      padding-right: 40px;
      padding-left: 40px;
      min-width: 170PX;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .eror_notification {
    text-align: center;
    padding: 0 24px;
    font-size: 14px;
  }
}

.center_modal {
  display: flex !important;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1280px) and (orientation: landscape) {
    align-items: flex-start;
  }
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms; // max-height 0.15s ease-out;
}

.fade-exit {
  opacity: 1;
  display: none;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 100ms;
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .new_Invoice_Component {
    .form_title {
      color: $sundhedplus-darkest-grey;
    }

    .form_control {
      .form_input {
        border: solid 1px $sundhedplus-light-grey;
        color: $sundhedplus-darkest-grey;

        &:focus {
          border-color: $sundhedplus-light-grey;
        }
      }
    }

    label {
      color: $sundhedplus-darkest-grey;
    }

    .clinic-search {
      .list-group-item {
        border-top-color: $sundhedplus-lightest-grey;
        border-bottom-color: $sundhedplus-lightest-grey;

        .badge {
          &.status-ACTIVE {
            background-color: $sundhedplus-dunning-green;
          }
        }
      }

      & .clinic-search-input {
        border-radius: 4px;
        border-color: $sundhedplus-light-grey;
        color: $sundhedplus-darkest-grey;

        &:focus {
          border-color: $sundhedplus-logo-green;
          box-shadow: none;
        }
      }

      .no-suggestions,
      .suggestions {
        .list-group-item {
          &:hover {
            background-color: $sundhedplus-lightest-grey;
          }
        }
      }
    }

    .radio {
      label {
        color: $sundhedplus-darkest-grey;

        input {
          background-color: $sundhedplus-lightest-grey;
          border: 1px solid $sundhedplus-light-grey;

          &:hover {
            background-color: $sundhedplus-light-grey;
          }

          &:checked {
            border: 8px solid $sundhedplus-selected-radio-button;
          }
        }

        .radio_danger {
          border-color: $error-color;
          &:hover {
            background-color: $sundhedplus-light-grey;
          }

          &:checked {
            border: 8px solid $sundhedplus-red-main;
          }
        }
      }
    }

  }
}