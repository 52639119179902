@import 'src/resources/scss/utilities/variables';

//upload documentation
.learn-more {
  color: $sundhedplus-red-main;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;

  span {
    padding: 0 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.upload-section,
.note-section {
  padding: 30px 20px;
  border-radius: 4px;
  border: 0.5px solid #44444433;
  margin-bottom: 20px;
  background-color: #fafafa;
  font-size: 14px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  .files-container {
    padding-bottom: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        width: 100%;
        padding: 10px 10px;
        list-style: none;
        border: 1px solid #444;
        background-color: #fff;
        border: 0.5px solid #44444433;
        margin-bottom: 20px;
        .grouped {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }

        .filename {
          margin-left: 5px;
          cursor: pointer;
          text-decoration: underline;
          max-width: 100%;
          overflow: hidden;
        }

        .close-icon {
          margin-left: auto;
          color: #be323c;
          font-size: 12px;
          font-weight: 900;
          cursor: pointer;
        }
      }
    }
  }

  .bottom-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    gap: 10px;
  }

  .addItemBtn {
    cursor: pointer;
    min-width: 100px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #be323c;
    line-height: 18px;
    border-radius: 30px;
    border: 1.5px solid #be323c;
    background: #fff;
    padding: 10px 30px;
    min-width: 175px;
    &.section {
      min-width: 224px;

      &:hover {
        animation-timing-function: ease-in-out;
        animation-duration: 200ms;
        background-color: #f5f0ee;
      }
      &:active {
        animation-timing-function: ease-out;
        animation-duration: 300ms;
      }
    }

    &.note {
      min-width: auto;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &.enabled {
        cursor: pointer;
        opacity: 1;
        background-color: #be323c;
        color: #ffffff;

        .file-note {
          fill: $white;
        }
      }
    }

    .file-note {
      fill: $sundhedplus-red-main;
      width: 18px;
      height: 18px;
    }

    i {
      font-size: 18px;
    }

    span {
      padding-left: 10px;
      font-weight: 600;
    }
  }

  .files-uploaded {
    margin-bottom: 20px;
  }

  .file-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 3px;

    .filename {
      padding: 5px 10px;
      background-color: #fff;
      font-size: 14px;
      width: 100%;

      span {
        padding-left: 10px;
      }
    }

    .icon {
      i {
        font-size: 30px;
      }

      &.APPROVED {
        color: #63b587;
      }

      &.REJECTED {
        color: #e88888;
      }

      &.PENDING {
        color: #afafaf;
      }
    }
  }
  // section rejected
  &.rejected {
    border: 0.5px solid #be323c;
  }
}

.delete-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #be323c;
  margin-top: 20px;
  .info-circle {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

.upload-tip {
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}
.upload-doc-modal .upload-section {
  margin-bottom: 10px;
  transition: height 0.3s ease-in-out;
  .panel-editor {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.invisible {
      opacity: 0;
      height: 0;
      padding: 0;
    }
  }
}

.rejected-note {
  border-width: 0.5px;
  border-style: solid;
  border-color: #e88888;
  .head {
    background: linear-gradient(0deg, #fedede, #fedede);
    padding: 3px 10px 0 10px;
    line-height: 25px;
    text-transform: uppercase;
  }
  .body {
    padding: 10px;
    background-color: white;
  }
}


.note-wrapper {
  margin: 20px auto;
  background-color: #fff;
  border: 0.5px solid rgba(68, 68, 68, 0.2);
  padding: 10px;
  transition: height 0.5s ease-in-out;

  .lnir-pencil-alt {
    font-size: 18px;
    cursor: pointer;
  }

  .file-note {
    fill: #444444;
    width: 18px;
    height: 18px;
  }
  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    span {
      margin-left: 10px;
      margin-right: auto;
    }

    .arrow {
      margin-left: auto;
      order: 2;
      cursor: pointer;

      &::before {
        display: inline-block;
        font: normal normal normal 1em/1 'LineIconsPro Regular';
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #000;
        content: '\eb27';
        transition: 0.5s ease-in-out;
      }
      &.active::before {
        transform: rotateX(180deg);
      }
    }
  }

  .client-note {
    border: 0.5px solid #0000001a;
    width: 100%;
    min-height: 64px;
    padding: 10px;
    margin-top: 10px;

    &:focus-visible,
    &:focus,
    &:active {
      border: 1px solid #ccc;
      outline: none;
    }
  }

  .panel-editor {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    overflow: hidden;
    //transition: height 0.3s ease-in-out;
    //transition: max-height 0.2s ease-out;

    &.open {
      height: auto;
    }
    &.close {
      height: 0;
    }

    .addItemBtn {
      background-color: #be323c;
      color: #ffffff;
      margin: 15px 0 10px 0;

      .file-note {
        fill: $white;
        width: 18px;
        height: 18px;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.documentation-section {
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.upload-results {
  .filename {
    margin-right: auto;
    order: 2;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    .dot-status {
      width: 15px;
      height: 15px;
      border-radius: 20px;
      display: inline-block;
      margin-right: 10px;
    }
    &.PENDING {
      .dot-status {
        background-color: #ffd570;
      }
    }
    &.APPROVED {
      .dot-status {
        background-color: #00b20d;
      }
    }
    &.REJECTED {
      .dot-status {
        background-color: #be323c;
      }
    }
  }
}

.toggle-documentation {
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    padding-top: 20px;
  }
  i {
    font-size: 24px;
    color: #444;
  }
  cursor: pointer;
}

.onboarding .quill {
  background-color: $white;

  .ql-editor {
    min-height: 64px;
  }
}

.preview {
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
}

.preview-image {
  max-width: 100%;
  object {
    min-height: 500px;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.upload-status-modal {
  &.loading,
  &.success,
  &.error {
    height: 700px;
  }
  &.finished {
    .subheadline {
      padding: 10px 50px 40px;
    }
    .body-text {
      margin: 40px 0 20px 0;
      text-align: left;
      font-size: 18px;
      line-height: 30px;
    }
    .modal-content {
      height: auto;
      .modal-body {
        padding: 40px;
      }
    }
  }
  .btn {
    text-transform: uppercase;
  }

  .modal-content {
    height: 100%;
    .modal-body {
      padding: 70px 40px;
    }
  }

  .content-wrapper {
    height: 100%;
    flex-direction: column;
    //min-height: 630px;
    text-align: center;
  }

  .subheadline {
    padding: 10px 50px 60px;
    font-family: 'PlayfairDisplay', serif;
  }

  // loading status
  // .loading-spinner {
  //     margin-top: 50px;
  // }

  .lds-ring {
    width: 115px;
    height: 115px;

    div {
      border: 3px solid #fff;
      border-color: #f8f8f8;

      div {
        width: calc(100% + 7px);
        height: calc(100% + 7px);
        top: -3px;
        left: -3px;
        border: 3px solid #fff;
        border-color: #be323c transparent transparent transparent;
      }
    }
  }

  // success status
  .status-icon {
    margin-top: 30px;
    margin-bottom: 50px;

    i {
      color: $sundhedplus-red-main;
      font-size: 120px;
    }
  }

  .success {
    .subtitle {
      margin-bottom: 30px;
    }
  }

  .error {
    .subtitle {
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.delete-file-modal,
.cancel-upload-modal,
.validate-file-modal {
  flex-direction: column;
  min-height: 510px;
  text-align: center;

  .file-item {
    background-color: #fff;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0.5px solid #444;
    padding: 13px 10px;
    margin: 50px 0;

    i {
      margin-right: 5px;
      font-size: 15px;
    }
  }
}

.cancel-upload-modal {
  justify-content: space-evenly;
  padding: 0 20px;
  .subtitle {
    font-size: 18px;
    line-height: 30px;
  }
}

.upload-status-modal,
.delete-file-modal,
.cancel-upload-modal,
.restart-application-modal,
.validate-file-modal {
  .headline {
    font-size: 32px;
    line-height: 40px;
  }
}
.validate-file-modal {
  .error-text {
    font-size: 18px;
    line-height: 30px;
  }
  .lds-ring {
    width: 116px;
    height: 115px;
    > div {
      border: 2px solid #fff;
      border-radius: 50%;
      border-color: #f8f8f8;
      div {
        width: calc(100% + 3px);
        height: calc(100% + 3px);
        top: -3px;
        left: -3px;
        border: 2px solid #fff;
        border-color: #be323c transparent transparent transparent;
      }
    }
  }
}

.onboarding-flow {
  .notification-success {
    border: 2px solid #71ae8c !important;
    background-color: #fff !important;
    .notification-dismiss {
      background-color: #71ae8c !important;
    }
  }
  .notification-error {
    border: 2px solid #e88888 !important;
    background-color: #fff !important;
    .notification-dismiss {
      background-color: #e88888 !important;
    }
  }
}
