@import "src/resources/scss/utilities/variables";

.UserRegistrationFlow {
  overflow: auto !important;
  height: 100vh;
  color: $darker-grey;
  margin: 0;

  @media only screen and (max-width: 700px) {
    justify-content: flex-start;
    flex-direction: column;
  }

  .btn {
    min-width: 142px;
    font-size: 14px;

    &.btn-light {
      border: 2px solid $green-main;
      color: $green-main;
      font-weight: 500;
    }
  }

  .top-header {
    display: flex;
    position: relative;
    justify-content: center;
    bottom: -30px;

    .d-logo {
      display: flex;
      justify-content: center;

      align-items: center;

      border-radius: 50px;
      width: 70px;
      height: 70px;
      font-size: 32px;

      background-color: $logo-green;
      color: $white;

      z-index: 2;

      @media only screen and (max-width: 700px) {
        width: 56px;
        height: 56px;
        font-size: 26px;
      }

      // @media only screen and (max-width: 500px) {
      //   margin: 10px auto;
      //   // display: inline-block;
      // }
      .di-denti {
        position: relative;
        bottom: 3px;
      }
    }

    @media only screen and (max-width: 700px) {
      padding-top: 64px;
      transform: translate(-50%, 0px);
      margin-left: 50%;

      .d-logo {
        position: relative;
        transform: none;
        margin: 0 auto;
        left: 0;
      }
    }

    img {
      height: 40px;
    }
  }

  .wrapper {
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    text-align: center;
    padding: 0;
    background-color: $white;

    a {
      color: $green-main;
    }

    @media only screen and (max-width: 700px) {
      padding-top: 64px;
      transform: translate(-50%, 0px);
      margin-left: 50%;

      .d-logo {
        position: relative;
        transform: none;
        margin: 0 auto;
        left: 0;
      }
    }

    .imgTitle {
      width: 180px;
      margin: 0 auto;
      margin-bottom: 20px;
      display: inline-block;

      .headerImg {
        width: 100%;
      }

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    .registration-steps {
      position: relative;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 0 28px 5px rgba(102, 102, 102, 0.17);
      padding: 30px 30px 13px 30px;
      min-height: 760px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &_content {
        padding-top: 40px;

        @media only screen and (max-width: 700px) {
          padding-top: 20px;
        }

        .section {
          .title {
            color: $darker-grey;
            font-size: 32px;
            font-weight: bold;
          }

          .note {
            margin-top: 30px;
            margin-bottom: 45px;
            font-size: 18px;
            font-weight: 300;
          }

          .content {
            .inputContainer {
              text-align: left;
              margin-bottom: 40px;

              label {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 300;
                color: $darker-grey;
                display: flex;
                justify-content: space-between;

                .error {
                  text-transform: initial;
                }
              }

              input {
                border-radius: 4px;
                background-color: $lightest-grey;
                height: 60px;
                padding: 20px;
                border: none;
                font-size: 24px;
                font-weight: 300;
                width: 100%;

                &:read-only {
                  color: $grey;
                }

                &:focus-visible {
                  outline: none;
                }
              }
            }
          }
        }
      }

      .actions-section {
        padding-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        button:first-child {
          margin-right: 25px;
        }
      }

      &_step-bullets {
        margin: 0 auto;

        .step-bullet {
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: $lightest-grey;
          margin: 0 8px 0 0;

          &.checked {
            background-color: $light-grey;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      .controls {
        margin-top: auto;
      }
    }
  }

  .finalState {
    margin: auto;

    .iconZone {
      padding-top: 30px;

      .lnil {
        font-size: 170px;

        &.red {
          color: $status-debtCollection;
        }

        &.green {
          color: $green-main;
        }

        @media only screen and (max-width: 700px) {
          font-size: 130px;
        }
      }
    }
  }

  .longButtonzone {
    width: 100%;
    margin: 10px 0px;

    .btn {
      width: 70%;
    }
  }

  @media only screen and (max-width: 500px) {
    display: block;
  }

  @media only screen and (max-width: 700px) {
    display: block;

    .wrapper {
      padding-top: 0px;

      .imgTitle {
        margin-bottom: 0px;
        margin-top: 10px;
        width: 50%;
      }

      width: 100%;

      .registration-steps {
        box-shadow: none;
        padding: 20px;
        min-height: calc(100vh - 60px);

        .actions-section {
          padding: 10px 0;

          .btn {
            min-width: 40%;
            font-size: 14px !important;
          }

          &.single {
            .btn {
              min-width: 80%;
            }
          }
        }
      }

      .registration-steps_content {
        .section {
          .note {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 321px) {
    .wrapper {
      .registration-steps_content {
        .section {

          .note {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .content {
            .inputContainer {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .single {
    .btn {
      margin-right: 0px !important;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .UserRegistrationFlow {
    background-color: $sundhedplus-red-secudary;

    .btn {
      &.btn-light {
        border: 2px solid $sundhedplus-red-main;
        color: $sundhedplus-red-main;
        background-color: $white;
      }
    }

    a {
      color: $sundhedplus-red-main;
    }

    .top-header {
      color: $sundhedplus-brawn;
      background-color: $sundhedplus-red-secudary;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;

      justify-content: center;

      padding: 25px 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .wrapper {
      a {
        color: $sundhedplus-red-main;
      }

      .registration-steps {
        min-height: 700px;

        &_content {
          padding-top: 0px;

          .section {
            .content {
              .inputContainer {
                input {
                  background-color: $sundhedplus-lightest-grey;
                }
              }
            }
          }
        }

        &_step-bullets {
          .step-bullet {
            background-color: $sundhedplus-lightest-grey;

            &.checked {
              background-color: $sundhedplus-light-grey;
            }
          }
        }
      }
    }

    .finalState {
      margin: auto;

      .iconZone {
        padding-top: 30px;

        .lnil {
          font-size: 170px;

          &.red {
            color: $status-debtCollection;
          }

          &.green {
            color: $sundhedplus-red-main;
          }
        }
      }
    }
  }
}