@import 'src/resources/scss/utilities/variables';

.StepBullets_Component{
  .steps {
    position: relative;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 28px 5px rgba(102, 102, 102, 0.17);
    padding: 30px 30px 13px 30px;
    min-height: 760px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &_content {
      padding-top: 40px;
      @media only screen and (max-width: 700px) {
        padding-top: 20px;
      }
      .section {
        .title {
          color: $darker-grey;
          font-size: 32px;
          font-weight: bold;
        }

        .note {
          margin-top: 30px;
          margin-bottom: 45px;
          font-size: 18px;
          font-weight: 300;
        }

        .content {
          .inputContainer {
            text-align: left;
            margin-bottom: 40px;

            label {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 300;
              color: $darker-grey;
              display: block;
            }

            input {
              border-radius: 4px;
              background-color: $lightest-grey;
              height: 60px;
              padding: 20px;
              border: none;
              font-size: 24px;
              font-weight: 300;
              width: 100%;

              &:read-only {
                color: $grey;
              }

              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }
    }

    .actions-section {
      padding-bottom: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      button:first-child {
        margin-right: 25px;
      }
    }

    &_step-bullets {
      margin: 0 auto;
      .step-bullet {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $lightest-grey;
        margin: 0 8px 0 0;

        &.checked {
           background-color: $light-grey;
        }
        &:last-child{
          margin: 0;
        }
      }
    }
    .controls{
      margin-top: auto;
    }
  }
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .StepBullets_Component {
    .steps {
      &_content {
        .section {
          .content {
            .inputContainer {
              input {
                background-color: $sundhedplus-lightest-grey;
              }
            }
          }
        }
      }

      &_step-bullets {
        .step-bullet {
          background-color: $sundhedplus-lightest-grey;

          &.checked {
            background-color: $sundhedplus-light-grey;
          }
        }
      }
    }
  }
}