@import 'src/resources/scss/utilities/variables';

@keyframes expandbackround {
  0%{
    left: 75%;
    top: 33px;
    width: 0;
    height: 0;
    border-radius: 50%;
  }
  100%{
    left: 0;
    top: -300px;
    width: 600px;
    height: 600px;
    border-radius: 0%;

  }
}

.Checkbox_component {
  background-color: $lightest-grey;
  display: flex;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  height: 80px;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  cursor: pointer;
  transition: all 200ms ease-in;

  &.checked {
    // background-color: $soft-green;
    color: $black;
    transition: all 200ms ease-in;
    .colored-backround {
      position:absolute;
      width: 600px;
      height: 600px;
      left: 0;
      top: -300px;
      background-color: $softer-green;
      border-radius: 0%;
      animation-name: expandbackround;
      animation-duration: .7s;
    }
    .Checkbox_component-icon {
      border-color: $status-agreement;
      background-color: $status-agreement;
      z-index: 1;
    }
  }

  &-icon {
    width: 40px;
    height: 40px;
    border: 2px solid #eaeaea;
    background-color: #fff;
    border-radius: 50%;
    transition: all 200ms ease-in;
    position: relative;
    .lnir {
      position: absolute;
      top: 8px;
      left: 9px;
      font-size: 20px;
      color: $white;
      font-weight: bolder;
    }
  }

  &-content {
    flex-grow: 3;
    z-index: 1;
    width: calc(100% - 40px);
    padding-left: 20px;
  }
  @media screen and (max-width: 478px) {
    height: auto;
    padding: 10px;
    width: 100%;
    .Checkbox_component-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      .lnir {
        // top: 28px !important;
        // left: 20px !important;
        font-size: 20px;
      }
    }
    .Checkbox_component-content {
      flex-grow: 1;
      width: calc(100% - 40px);
    }
    
  }
    
  
}

// ####### SUNDHEDPLUS #########
#Sundhedplus {
  .Checkbox_component {
    background-color: $sundhedplus-lightest-grey;

    &.checked {
      .Checkbox_component-icon {
        border-color: $sundhedplus-status-agreement;
        background-color: $sundhedplus-status-agreement;
        z-index: 1;
      }
    }
  }
}