@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/mixins';

.application-data-item {
  position: relative;

  &.monthio {
    margin-bottom: 50px;

    @media screen and (max-width: 600px) {
      margin-bottom: 25px;
    }
  }

  .custom-card .card-body {
    padding: 16px 27px;
  }

  .budget-card {
    margin-bottom: 50px;

    @media screen and (max-width: 600px) {
      margin-bottom: 25px;
    }

    .table-wrapper {
      overflow-x: auto;

      .table-header,
      .table-content {
        min-width: 850px;
      }
    }
  }

  .section-item {
    width: 100%;

    .counter {
      border-radius: 40px;
      padding: 0 15px;
      background-color: #fff;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    .budget-entry {
      font-size: 14px;
      line-height: 16px;

      span {
        max-width: 60%;
      }
    }
  }

  .section-description {
    width: 100%;
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    transition: 0.5s ease-in-out;

    .collapsible-content {
      width: 100%;

      .budget-entry {
        margin-top: 10px;
      }
    }
  }

  .arrow {
    margin-left: auto;
    order: 2;
    cursor: pointer;

    &::before {
      display: inline-block;
      font: normal normal normal 1em/1 'LineIconsPro Regular';
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #000;
      content: '\eb27';
      transition: 0.5s ease-in-out;
      font-size: 12px;
    }

    &.active::before {
      transform: rotateX(180deg);
    }
  }
}

#application-master-data {
  .menu-items {
    min-width: 182px !important;
  }

  .header-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .application-created {
    padding: 0 70px;
    color: #8e8e8e;
  }

  .application-status {
    padding: 3px 30px;
    border-radius: 20px;
    color: #fff;
  }

  .actions-wrapper {
    margin-left: auto;
  }

  @media only screen and (max-width: 600px) {
    .header-text {
      align-items: flex-start;
      justify-content: start;
      flex-direction: column;
    }

    .application-status {
      margin-top: 10px;
    }

    .application-created {
      padding: 0;
    }

    .actions-wrapper {
      position: absolute;
      right: 5px;
      margin-left: inherit;
    }
  }

  .application-last-menu-items {
    border-top: 1px solid #dbdbdb;
    margin-top: 10px;
    padding-top: 5px;
  }
}

.application-timeline {
  width: 100%;

  .frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 25px;
    width: 100%;

    .frame-name {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        span {
          margin-bottom: 5px;
        }
      }

      svg {
        width: 100%;
      }
    }
  }

  .frame .frame-name:first-child .group {
    padding-left: 0;
  }

  .frame .frame-name:last-child .group {
    padding-right: 0;
  }

  .timeline-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    span {
      display: inline-block;
      width: 100%;
      min-width: 30px;
      height: 1px;
      background-color: #dbdbdb;
      margin-top: 0px;
      margin-right: -4px;
    }

    .arrow-right {
      border: solid #dbdbdb;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 2px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  @media only screen and (max-width: 750px) {
    .frame {
      display: block;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 25px;

      .frame-name {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        margin-bottom: 10px;

        .group {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
        }

        svg {
          width: 45px;
        }
      }
    }
  }
}

.application-reason-wrapper {
  display: flex;
  margin-top: 30px;

  .application-status-reason {
    background-color: #fff3cd;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    .icon.done {
      color: #71ae8c;
      margin-right: 10px;
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }

  .metadata {
    font-size: 12px;
    color: #8e8e8e;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
}

#warnings-section {
  .warnings-counter {
    color: #be323c;
    margin-left: 10px;
  }

  .warning-grid {
    display: block;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .warning-item {
    margin-bottom: 10px;
    break-inside: avoid;

    .info-circle {
      width: 18px;
      height: 18px;
      fill: #996b68;
    }
  }

  .warning-text {
    margin-left: 10px;
  }

  .warning-description {
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: max-height 0.2s ease-out;
    background-color: rgb(250, 248, 246);
    font-size: 14px;
    line-height: 24px;

    p {
      padding: 10px 43px;
    }
  }

  .warning-headline {
    background-color: #fff3cd;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.headline-section {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;

  &.warnings {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Maven Pro Medium';
  }
}

.budget-entry {
  background-color: #faf8f6;
  border-radius: 4px;
  padding: 8px 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  @media screen and (min-width: 768px) and (max-width: 980px) {
    font-size: 16px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  label {
    margin-bottom: 0;
    color: #969696;
  }

  &>.salary-group {
    display: flex;
    align-items: center;
  }
}

.media.application {
  .media-body-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  .logo {
    padding-top: 0;

    .d-logo {
      line-height: 27px;

      svg path {
        fill: $sundhedplus-brawn;
      }
    }
  }

  .heading {
    font-size: 16px;
    line-height: 17px;
    color: #444444;
    text-transform: none;
    font-family: 'Maven Pro Medium';
  }
}

.payslips-modal {
  border-radius: 0.3rem;

  @media screen and (min-width: 768px) and (max-width: 980px) {
    .modal-dialog {
      max-width: 75%;
    }
  }


  .modal-content {
    .modal-body {
      padding: 40px 60px;
    }
  }

  .average {
    display: flex;
    justify-content: flex-end;
    color: #8e8e8e;
    padding: 0 20px;

    .line {
      .label {
        font-size: 14px;
      }

      .value {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .value {
      margin-left: 15px;
    }
  }
}

.payslips-entries {
  .payslips-group {
    margin-bottom: 30px;

    .month-date {
      text-transform: capitalize;
      font-family: 'Maven Pro Medium';
      font-size: 14px;
      line-height: 16px;
      color: #444444;
      padding: 0 10px;
    }

    .month-group {
      .payslip {
        margin-bottom: 10px;
      }
    }

    .multiplePaySlip {
      margin: 0;
    }
  }

  .payslip {
    margin-bottom: 30px;

    .month-content {
      background-color: #fbf9f8;
      padding: 10px 20px;
      border-radius: 4px;
      margin: 10px 0;
      font-size: 14px;
      line-height: 20px;
    }

    .line {
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;

      .label {
        color: #8e8e8e;
        width: 100px;
        display: inline-block;
      }

      .value {
        display: inline-block;
        width: calc(100% - 100px);

        &.net {
          font-family: 'Maven Pro Medium';
        }

        &.gross {
          color: #8e8e8e;
        }
      }
    }
  }
}

#budget-section {
  .budget-item>div {
    display: inline-block;
  }

  .item {
    width: calc(25% - 40px);
    display: inline-block;
  }

  .reference,
  .bank-data,
  .client {
    width: 10%;
    text-align: right;
  }

  .status {
    width: 10%;
    text-align: center;
  }

  .deviation,
  .split-expenses {
    width: 5%;
    text-align: right;
  }

  @media screen and (max-width: 1200px) {
    .deviation {
      width: 6%;
    }

    .split-expenses {
      width: 4%;
    }
  }

  .deviation {
    .deviation-red {
      color: #e88888;
    }
  }

  .client,
  .final {
    width: 12.5%;
    text-align: right;
  }

  .final {
    input {
      width: 100%;
      text-align: right;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1900px) {
    .deviation {
      width: 8%;
    }

    .split-expenses {
      width: 4%;
    }

    .final {
      width: 11.5%;
    }
  }

  .status {
    span {
      height: 18px;
      padding: 3px 10px;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;

      &.required {
        background-color: #d1d1d1;
      }

      &.pending {
        background-color: #ffd570;
      }

      &.approved {
        background-color: #72ae8c;
      }

      &.rejected {
        background-color: #a2dceb;
      }
    }
  }

  .table-header {
    background-color: #f6f0ee;
    padding: 10px 10px 10px 45px;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    font-family: 'Maven Pro Medium';

    span {
      display: inline-block;
    }
  }

  .budget-category-headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #f6f0ee;
    padding: 0 10px;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    .arrow {
      margin-left: 0;
      margin-right: 10px;
      order: 0;

      &::before {
        font-size: 10px;
      }
    }

    .total {
      margin-left: auto;
    }
  }

  .budget-category-list {
    overflow: hidden;
    transition: 0.5s ease-in-out;
    padding-left: 40px;
  }

  .budget-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 5px;
    min-height: 46px;
    border-bottom: 1px solid #f6f0ee;

    div {
      display: inline-block;
    }

    .item {
      text-align: left;

      a {
        color: #444;
        text-decoration: underline;
        font-family: 'Maven Pro Medium';
      }
    }
  }

  .final-input {
    width: 100%;
    height: 26px;
    padding: 5px 10px;
    text-align: right;
    border-radius: 4px;
    border: 1px solid #afafaf;
    color: #444;
    font-size: 14px;

    &:focus,
    &:active {
      outline: none;
      background-color: rgba(114, 174, 140, 0.08);
      border: 2px solid #72ae8c;
    }
  }

  .budget-save-btn {
    padding: 30px 0;
  }

  .conclusions-section {
    background-color: #faf8f6cc;
    width: 80%;
    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: space-around;
    padding: 20px 50px;
    margin: 0 auto;

    .left-side {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        font-family: 'Maven Pro Medium';
        margin-left: 10px;
      }
    }

    .right-side {
      font-size: 14px;

      .calculated-amount {
        font-size: 24px;
        line-height: 28px;
        margin-left: 10px;
      }

      .small {
        font-size: 12px;
        color: #8e8e8e;
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-flow: column;
      padding: 15px;
    }
  }

  .no-data {
    font-size: 16px;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    margin-top: 10px;
    border-top: 1px solid #f6f0ee;
    border-bottom: 1px solid #f6f0ee;
    font-family: 'Maven Pro Medium';
  }
}

.update-budget-modal {
  height: 450px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  font-family: 'PlayfairDisplay', serif;

  .modal-content {
    height: 100%;

    .modal-body {
      padding: 70px 40px;
    }
  }

  .modal-content {
    height: 100%;

    .modal-body {
      padding: 70px 40px;
    }
  }

  .content-wrapper {
    height: 100%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }

  .headline {
    font-size: 32px;
    line-height: 40px;
  }

  .subheadline {
    padding: 10px 50px 60px;
    font-family: 'PlayfairDisplay', serif;
  }

  .lds-ring {
    width: 115px;
    height: 115px;

    div {
      border: 3px solid #fff;
      border-color: #f8f8f8;

      div {
        width: calc(100% + 7px);
        height: calc(100% + 7px);
        top: -3px;
        left: -3px;
        border: 3px solid #fff;
        border-color: #be323c transparent transparent transparent;
      }
    }
  }
}

.confirm-modal {
  .reason-wrapper {
    width: 400px;

    label {
      width: 100%;
      text-align: left;
      color: #969696;
      font-size: 14px;
    }

    textarea {
      min-height: 66px;
      background-color: #faf8f6;
      border: 0.5px solid rgba(68, 68, 68, 0.2);
      font-size: 14px;
      line-height: 22px;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    ::-webkit-input-placeholder {
      font-style: italic;
      color: #969696;
    }

    :-moz-placeholder {
      font-style: italic;
      color: #969696;
    }

    ::-moz-placeholder {
      font-style: italic;
      color: #969696;
    }

    :-ms-input-placeholder {
      font-style: italic;
      color: #969696;
    }
  }
}

.save-note-btn {
  cursor: pointer;
  min-width: 100px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 30px;
  border: 1.5px solid #be323c;
  padding: 10px 30px;
  background-color: #be323c;
  color: #ffffff;
  margin: 25px 0 10px 0;

  .file-note {
    fill: #ffffff;
    width: 18px;
    height: 18px;
  }

  span {
    padding-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}