.percentage-icon {
    width: 25px;
    height: 25px;
}

.settle-icon {
    width: 18px;
    height: 18px;
    fill: #222120;
    margin-right: 16px;
    margin-left: -3px;
}

.information-alt {
    width: 18px;
    height: 20px;
    fill: #be323c;
}

.info-circle {
    width: 130px;
    height: 130px;
    fill: $sundhedplus-red-main;
    // transform: rotate(180deg);
}

.celebrate-image {
    width: 100%;
    height: 270px;
}

.coin-icon {
    width: 16px;
    height: 16px;
    fill: $sundhedplus-brawn;
}

.circle-plus {
    width: 18px;
    height: 18px;
    fill: $sundhedplus-brawn;
}